<template>
  <div class="activity-item">
    <app-avatar class="activity-item__avatar" :src="avatar">
      <div class="activity-item__icon-nest">
        <font-awesome-icon :icon="['fas', 'message']" class="activity-item__icon" />
      </div>
    </app-avatar>

  <div class="activity-item__content" @click="goToLesson">
    <p class="activity-item__description">
      <strong>
        {{ activity.data.comment.user.first_name }} {{ activity.data.comment.user.last_name }}
        {{ $t('notifications.repliedToYourComment') }}</strong> <span class="activity-item__description--content">{{ activity.data.course.title }} {{ $t('notifications.video') }} "{{ activity.data.lesson.title }}"</span>
    </p>
  </div>

    <div class="activity-item__indicator" @click="markAsRead" v-if="!activity.is_read"></div>
  </div>
</template>

<script>

export default {
  name: 'ActivityCommentReply',
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  computed: {
    avatar () {
      return this.activity.data.comment.user.avatar || this.vaporAsset('images/avatar-placeholder.png')
    }
  },
  methods: {
    goToLesson () {
      this.$router.push({
        name: 'lesson',
        params: {
          id: this.activity.data.lesson.id
        },
        query: {
          comment_id: this.activity.data.comment.id
        }
      })
      this.$emit('close-toast')
      this.trackEvent('button_clicked', { button: 'activity_view_reply_clicked' })
    },
    markAsRead () {
      this.$store.dispatch('profile/readActivity', {
        id: this.activity.id,
        read: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .activity-item {
    display: flex;
    position: relative;
    padding: 12px 34px 12px 10px;
    margin-bottom: 6px;
    border-radius: 6px;
    align-items: center;

    @include tablet-portrait {
      padding: 12px 34px 12px 15px;
    }
    @include desktop-xs {
      &:hover {
        background-color: var(--gray-8);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    &:active {
      background-color: var(--gray-8);
    }

    &__content {
      flex: 2;
      cursor: pointer;
    }

    &__avatar {
      height: 42px;
      width: 42px;
      margin: 0 15px 0 0;
      background-color: var(--gray-6);

      @include phone-portrait {
        height: 52px;
        min-width: 52px;
        margin: 0 20px 0 0;
      }
    }

    &__icon-nest {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -6px;
      right: -1px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: var(--gray-5);

      @include phone-portrait {
        height: 24px;
        width: 24px;
      }
    }
    &__icon {
      font-size: 10px;
      margin-bottom: -1px;

      @include phone-portrait {
        font-size: 12px;
      }
    }

    &__description {
      font-size: $text-sm;
      margin: 0 0 2px 0;

      @include phone-portrait {
        font-size: $text-md;
      }

      &--content {
        color: var(--gray-3);
      }
    }

    &__time {
      font-size: 12px;
      color: var(--gray-3);
      margin: 0;

      @include tablet-portrait {
        font-size: $text-sm;
      }
    }

    &__indicator {
      position: absolute;
      top: 50%;
      right: 10px;
      height: 9px;
      min-width: 9px;
      margin: -4.5px 0 0 15px;
      border-radius: 50%;
      background: var(--red);
      cursor: pointer;

      @include tablet-portrait {
        right: 15px;
      }
    }
  }
</style>
