<template>
  <div class="share-button" :class="`share-button--${currentLanguage}`">
    {{ $t('share.share') }}<span v-if="!isPhone">{{ $t('share.lesson') }}</span>
    <font-awesome-icon :icon="['fas', 'share-nodes']" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('course', {
      course: state => state.course
    })
  }
}
</script>

<style lang="scss" scoped>
.share-button {
  display: flex;
  flex-flow: column-reverse;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 55px;
  width: 55px;
  font-size: 12px;
  line-height: 24px;
  font-weight: $bold;
  text-align: center;
  white-space: nowrap;
  padding: 8px 0 0 0;
  background: var(--gray-8);
  transition: background-color .1s ease-in-out, opacity .4s ease-in-out;
  z-index: 105;
  cursor: pointer;

  @include tablet-portrait(portrait) {
    display: block;
    height: auto;
    width: auto;
    min-width: 140px;
    line-height: 32px;
    padding: 0 20px;
    margin: 0 0 0 16px;
    border: 1px solid var(--gray-1);
    border-radius: 6px;
    background: none;
  }
  @include tablet-landscape {
    display: block;
    height: auto;
    width: auto;
    min-width: 140px;
    line-height: 32px;
    padding: 0 20px;
    margin: 0 0 0 16px;
    border: 1px solid var(--gray-1);
    border-radius: 6px;
    background: none;
  }

  @include desktop-xs {
    &:hover {
      background: rgba((var(--white-rgb)), 0.1);
    }
  }

  &:active {
    background: rgba((var(--white-rgb)), 0.1);
  }

  &--active {
    @include tablet-portrait {
      background: rgba((var(--white-rgb)), 0.3);

      &:hover {
        background: rgba((var(--white-rgb)), 0.3);
      }
    }
  }

  &--disabled {
    opacity: .2;
    pointer-events: none;
  }

  &--es {
    width: 70px;
  }
  &--pt {
    width: 80px;
  }
  &--es,
  &--pt {
    @include tablet-portrait(portrait) {
      width: auto;
    }
    @include tablet-landscape {
      width: auto;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    width: 16px;
    background: linear-gradient(270deg, #121214 0%, rgba(23, 23, 25, 0) 100%);
    pointer-events: none;

    @include tablet-portrait(portrait) {
      display: none;
    }
    @include tablet-landscape {
      display: none;
    }
  }

  svg {
    display: block;

    @include phone-landscape {
      display: inline;
      margin: 0 0 0 6px;
    }
  }
}
</style>