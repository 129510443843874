import appClient from '@shared/api/appClient'
import i18n from '@shared/i18n'

export default {
  namespaced: true,
  namespace: 'quickTips',
  state: {
    comments: [],
    commentsTotal: null,
    commentsNextPage: null,
    commentsPrevPage: null,
    commentsLastPage: null,
    commentsLoading: true,
    commentsOpened: false,
    commentFocus: false,
    replyTo: null,
    shareOpened: false,
    popupOpened: false,
    postComment: '',
    categories: [],
    activeCategory: 'all',
    categoriesLoading: false,
    autoPromotionShown: false,
    initialAutoPromotionShown: false,
    autoPromotionShownVideos: [],
    autoLoginPopupShown: false,
    videos: [],
    video: null,
    videoProgress: 0,
    allQuickTips: [],
    quickTipsLoading: false,
    quickTipsAllLoading: false,
    noMoreQuickTips: false,
    lockScroll: false,
    showSwipeIndicator: null,
    swipeIndicatorSeen: false,
    videoAutoplay: true,
    muted: false
  },
  getters: {
    videos: (state, getters, rootState) => {
      return state.videos
    },
    allQuickTips(state, getters, rootState) {
      return state.allQuickTips
    },
    activeCategory: (state, getters, rootState) => {
      return state.activeCategory
    }
  },
  actions: {
    toggleComments ({ commit }, value) {
      commit('TOGGLE_COMMENTS', value)
    },
    toggleShare ({ commit }, value) {
      commit('TOGGLE_SHARE', value)
    },
    togglePopup ({ commit }, value) {
      commit('TOGGLE_POPUP', value)
    },
    toggleLockScroll ({ commit }, value) {
      commit('TOGGLE_LOCK_SCROLL', value)
    },
    toggleVideoAutoplay ({ commit }, value) {
      commit('TOGGLE_VIDEO_AUTOPLAY', value)
    },
    toggleShowSwipeIndicator({ commit, state }, value) {
      if (!state.showSwipeIndicator && state.showSwipeIndicator === false) return
      commit('TOGGLE_SHOW_SWIPE_INDICATOR', value)
    },
    setMuted({ commit }, value) {
      commit('SET_MUTED', value)
    },
    updateMessage ({commit}, value) {
      commit('UPDATE_MESSAGE', value)
    },
    toggleQuickTipsAllLoading({ commit, state }, value) {
      commit('TOGGLE_QUICK_TIPS_ALL_LOADING', value)
    },
    fetchQuickTips({ commit, state }, params) {
      state.quickTipsLoading = true

      if (state.activeCategory !== 'all') {
        params.filters['filter[category_id]='] = state.categories.find(c => c.slug === state.activeCategory)?.id
      } else {
        delete params.filters['filter[category_id]']
      }

      return appClient.getQuickTips(params).then(({ data }) => {
        const { data: videos } = data;
        if (videos) {
          commit('FETCH_QUICK_TIPS', [...state.videos, ...videos])
        }
      }).finally(() => state.quickTipsLoading = false)
    },
    fetchAllQuickTips({ commit, state }, params) {
      if (state.activeCategory !== 'all') {
        params.filters['filter[category_id]'] = state.categories.find(c => c.slug === state.activeCategory)?.id
      } else {
        delete params.filters['filter[category_id]']
      }

      return appClient.getQuickTips(params).then(({ data }) => {
        const { data: videos } = data
        if (videos) {
          if (videos.length === 0) state.noMoreQuickTips = true
          else {
            commit('FETCH_ALL_QUICK_TIPS', [...state.allQuickTips, ...videos])
            return true
          }
        }
        return false
      })
    },
    fetchCategories({ commit, state }, options) {
      if (state.categories.length) {
        return Promise.resolve(state.categories)
      }
      state.categoriesLoading = true
      return appClient.getQuickTipsCategories(options).then(({ data }) => {
        if (data) {
          data.unshift({ id: 'all', name: i18n.global.t('quickTips.all'), slug: 'all' })
          commit('FETCH_CATEGORIES', data)
          return data
        }
      }).finally(() => state.categoriesLoading = false)
    },
    fetchComments({ commit, state }, params) {
      appClient.getQuickTipsComments(state.video.id, params?.page || 1).then(({ data }) => {
        if (data) {
          commit('FETCH_COMMENTS', data)
        }
      })
    },
    addComment ({ commit, state }, params) {
      return appClient.addQuickTipsComment(state.video.id, params.content, params.replyId).then(({data}) => {
        if (data) {
          params.replyId
            ? commit('ADD_REPLY', data.comment)
            : commit('ADD_COMMENT', data.comment)
        }
      })
    },
    deleteComment ({ commit }, params) {
      return appClient.deleteComment(params.comment.id).then(() => {
        params.comment.is_reply
          ? commit('DELETE_REPLY', params)
          : commit('DELETE_COMMENT', params)
      })
    },
    resetComments ({ commit }) {
      commit('RESET_COMMENTS')
    },
    setCommentFocus ({commit}, value) {
      commit('SET_COMMENT_FOCUS', value)
    },
    setReplyTo ({ commit }, value) {
      commit('SET_REPLY_TO', value)
    },
    setCategory({ commit, state }, category) {
      commit('SET_CATEGORY', category)
    },
    setTipLike({ commit, state }, isLiked) {
      return appClient.setQuickTipsVideoLike(state.video.id, isLiked)
    },
    setVideo({ commit, dispatch, state, rootState }, video) {
      let skippedVideo
      if (!rootState.user.guest && state.video && state.videoProgress < 4) {
        skippedVideo = state.video
      }
      commit('SET_VIDEO_PROGRESS', 0)
      commit('SET_VIDEO', video)
      return skippedVideo
    },
    setVideoProgress({ commit, state }, progress) {
      if (!state.video) return
      commit('SET_VIDEO_PROGRESS', progress)
      return appClient.setQuickTipsVideoProgress(state.video.id, Math.max(1, progress))
    },
    setAutoPromotionShown({ commit, state }, video_index) {
      if (!video_index && !state.initialAutoPromotionShown) {
        commit('SET_INITIAL_AUTO_PROMOTION_SHOWN', true)
        return
      }
      commit('SET_AUTO_PROMOTION_SHOWN', video_index)
    },
    setAutoLoginPopupShown({ commit, state }) {
      commit('SET_AUTO_LOGIN_POPUP_SHOWN')
    },
    resetAllQuickTips({ commit, state }) {
      commit('RESET_ALL_QUICK_TIPS')
    },
    resetQuickTips({ commit, state }) {
      commit('RESET_QUICK_TIPS')
    }
  },
  mutations: {
    TOGGLE_COMMENTS (state, value) {
      state.commentsOpened = value
    },
    TOGGLE_SHARE (state, value) {
      state.shareOpened = value
    },
    TOGGLE_POPUP (state, value) {
      state.popupOpened = value
    },
    TOGGLE_LOCK_SCROLL (state, value) {
      state.lockScroll = value
    },
    TOGGLE_SHOW_SWIPE_INDICATOR (state, value) {
      state.showSwipeIndicator = value
      if (!value) return
      state.setSwipeIndicatorSeen = true
    },
    TOGGLE_VIDEO_AUTOPLAY(state, value) {
      state.videoAutoplay = value
    },
    TOGGLE_QUICK_TIPS_ALL_LOADING(state, value) {
      state.quickTipsAllLoading = value
    },
    UPDATE_MESSAGE (state, value) {
      state.postComment = value
    },
    FETCH_QUICK_TIPS (state, value) {
      state.videos = value
    },
    FETCH_ALL_QUICK_TIPS (state, value) {
      state.allQuickTips = value
    },
    FETCH_COMMENTS (state, value) {
      state.comments.push(...value.data)
      state.commentsTotal = value.meta.total
      state.commentsNextPage = value.links.next
      state.commentsPrevPage = value.links.prev
      state.commentsLastPage = value.meta.last_page
      state.commentsLoading = false
    },
    ADD_COMMENT (state, value) {
      state.comments.unshift(value)
      state.commentsTotal += 1
      state.video.comments_count += 1
    },
    ADD_REPLY (state, value) {
      const comment = state.comments.find(item => {
        return item.id === (state.replyTo.is_reply ? state.replyTo.parentId : state.replyTo.id)
      })
      comment.replies.push(value)
      state.commentsTotal += 1
      state.video.comments_count += 1
      state.replyTo = null
    },
    DELETE_COMMENT(state, value) {
      state.comments = state.comments.filter(item => {
        return item.id !== value.comment.id
      })
      const deleteCount = value.comment.replies.length + 1
      state.commentsTotal -= deleteCount
      state.video.comments_count -= deleteCount
    },
    DELETE_REPLY(state, value) {
      const comment = state.comments.find(item => {
        return item.id === value.parentId
      })
      comment.replies = comment.replies.filter(item => {
        return item.id !== value.comment.id
      })
      const deleteCount = value.comment.replies.length + 1
      state.commentsTotal -= deleteCount
      state.video.comments_count -= deleteCount
    },
    RESET_COMMENTS (state) {
      state.comments = []
    },
    SET_COMMENT_FOCUS (state, value) {
      state.commentFocus = value
    },
    SET_REPLY_TO (state, value) {
      state.replyTo = value
    },
    FETCH_CATEGORIES (state, value) {
      state.categories = value
    },
    SET_CATEGORY(state, value) {
      state.activeCategory = value
    },
    SET_VIDEO(state, value) {
      state.video = value
    },
    RESET_QUICK_TIPS(state) {
      state.videos = []
      state.noMoreQuickTips = false
    },
    RESET_ALL_QUICK_TIPS(state) {
      state.allQuickTips = []
      state.noMoreQuickTips = false
    },
    SET_AUTO_PROMOTION_SHOWN(state, value) {
      state.autoPromotionShown = !!value
      state.autoPromotionShownVideos.push(value)
    },
    SET_INITIAL_AUTO_PROMOTION_SHOWN(state, value) {
      state.initialAutoPromotionShown = value
    },
    SET_AUTO_LOGIN_POPUP_SHOWN(state) {
      state.autoLoginPopupShown = true
    },
    SET_VIDEO_PROGRESS(state, value) {
      state.videoProgress = value
    },
    SET_MUTED(state, value) {
      state.muted = value
    }
  }
}
