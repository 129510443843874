import appClient from '@shared/api/appClient'

export default {
  namespaced: true,
  namespace: 'popup',
  state () {
    return {
      popup: null,
      immediate: false,
      popupLoaded: false
    }
  },
  actions: {
    getPopup ({ state, commit }) {
      return appClient.getPopup().then(({ data }) => {
        state.popupLoaded = true

        if (!!Object.keys(data).length) {
          // Preload image
          if (data.image || data.product.checkout_graphic) {
            const image = new Image()
            image.src = data.type.match(/^(plc|facebook)$/) ? data.image : data.product.checkout_graphic || null
            if (data.product?.checkout_image) {
              const image2 = new Image()
              image2.src = data.product.checkout_image
            }
          }

          commit('SET_POPUP', data)

          return data.timer
        }
      })
    },
    setPopup ({ commit }, data) {
      commit('SET_POPUP', data)
    },
    showLoginPopup ({ commit }) {
      commit('SET_POPUP', {
        type: 'login',
        immediate: true
      })
    },
    closePopup ({commit}) {
      commit('CLOSE_POPUP')
    }
  },
  mutations: {
    SET_POPUP (state, value) {
      state.popup = value
      state.immediate = value.immediate ?? false
    },
    CLOSE_POPUP (state) {
      state.popup = null
    }
  }
}
