<template>
  <div class="share-tooltip">
    <font-awesome-icon :icon="['fas', 'share-nodes']" />
    <p>{{ $t('share.shareAnyLesson') }} <strong>{{ $t('share.share') }}<span v-if="!isPhone"> {{ $t('share.lesson') }}</span></strong> {{ $t('share.buttonAbove') }}</p>
    <a class="share-tooltip__close" @click.stop="hideShareTooltip">&times;</a>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('user', {
      guest: state => state.guest
    }),
  },
  methods: {
    hideShareTooltip () {
      this.$store.dispatch('settings/updateSettings', { key: 'hide_share_feature_tooltip', choice: true })
    }
  },
  mounted () {
    setTimeout(() => this.hideShareTooltip(), 20000)
  }
}
</script>

<style lang="scss" scoped>
.share-tooltip {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: calc(100% + 18px);
  right: 8px;
  width: calc(100vw - 16px);
  padding: 8px 60px 8px 21px;
  border-radius: 0 0 6px 6px;
  background: #19191B;
  transform-origin: 100% 0;
  cursor: pointer;

  @include phone-landscape {
    right: 0;
    width: 299px;
    padding: 8px 26px 8px 21px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: 4px;
    border-radius: 6px 6px 0 0;
    background: linear-gradient(90deg, var(--red-gradient));
    z-index: 10;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: auto;
    font-size: 24px;
    color: #fafafa;
    padding: 2px 12px;
    border-radius: 6px;

    @include tablet-portrait {
      padding: 2px 8px;
    }
  }

  svg {
    font-size: 32px;
  }

  p {
    font-size: $text-md;
    line-height: 1.8;
    color: #CDCFD2;
    margin: 0;

    @include phone-landscape {
      font-size: 12px;
      line-height: 1.3;
    }
  }

  strong {
    color: var(--white);
  }
}
</style>