<template>
  <div class="profile-activity-ph">
    <div class="profile-activity-ph__activity" v-for="n in 8" :key="n">
      <div class="profile-activity-ph__item profile-activity-ph__avatar"></div>

      <div class="profile-activity-ph__content">
        <div class="profile-activity-ph__item profile-activity-ph__description-line-1"></div>
        <div class="profile-activity-ph__item profile-activity-ph__description-line-2"></div>
        <div class="profile-activity-ph__item profile-activity-ph__description-line-3"></div>
        <div class="profile-activity-ph__item profile-activity-ph__description-line-4"></div>
        <div class="profile-activity-ph__item profile-activity-ph__time"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProfileActivityPlaceholder',
  computed: {
    ...mapState('lesson', {
      lesson: state => state.lesson
    })
  }
}
</script>

<style lang="scss" scoped>
  .profile-activity-ph {
    &__activity {
      display: flex;
      flex-basis: 100%;
      padding: 12px 15px;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__item {
      position: relative;
      border-radius: 5px;
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;

      &--hidden {
        animation: none;
      }
    }
  
    &__avatar {
      position: relative;
      height: 42px;
      min-width: 42px;
      margin: 0 15px 0 0;
      border-radius: 50%;
      
      @include phone-portrait {
        height: 54px;
        min-width: 54px;
        margin: 0 20px 0 0;
      }
    }

    &__content {
      flex: 2;
    }

    &__description-line-1 {
      width: 90%;
      height: 18px;
      margin: 0 0 4px 0;
      
      @include phone-landscape {
        width: 200px;
      }
    }

    &__description-line-2 {
      width: 100%;
      height: 18px;
      margin: 0 0 4px 0;
      
      @include phone-landscape {
        width: 80%;
      }
    }

    &__description-line-3 {
      width: 100%;
      height: 18px;
      margin: 0 0 4px 0;
      
      @include phone-landscape {
        display: none;
      }
    }

    &__description-line-4 {
      width: 60%;
      height: 18px;
      margin: 0 0 4px 0;
      
      @include phone-landscape {
        display: none;
      }
    }

    &__time {
      height: 18px;
      width: 100px;
      margin: 0;
    }
  }
</style>
