<template>
  <div class="profile-edit__block">
    <h2 class="profile-edit__block-title">{{ $t('profile.notifications') }}</h2>

    <div class="profile-edit__field">
      <div class="profile-edit__field-nest">
        <label class="profile-edit__field-label">{{ $t('profile.allowNotifications') }}</label>

        <div class="profile-edit__toggler-nest" :class="{'profile-edit__toggler-nest--saving': _updating}">
          <transition name="fade">
            <span class="profile-edit__toggler-saved" v-if="_updated && _updating">
              <font-awesome-icon :icon="['fas', 'check']" />
            </span>
          </transition>

          <transition name="fade">
            <span class="profile-edit__toggler-saving" v-if="_updating">
              <font-awesome-icon :icon="['fas', 'spinner']" spin />
            </span>
          </transition>

          <app-toggler v-model="_allowed" />
        </div>
      </div>
    </div>

    <transition-expand>
      <div class="profile-edit__field" v-if="_allowed">
        <div class="profile-edit__field-nest">
          <label class="profile-edit__field-label">{{ $t('profile.dailyLessonReminders') }}</label>

          <div
            class="profile-edit__toggler-nest"
            :class="{
                'profile-edit__toggler-nest--saving': isUpdatingField(
                  'cam_guru_engagement_reminder_notification'
                ),
              }"
          >
            <transition name="fade">
              <span
                class="profile-edit__toggler-saved"
                v-if="
                  isUpdatedField('cam_guru_engagement_reminder_notification') &&
                  !isUpdatingField('cam_guru_engagement_reminder_notification')
                "
              >
                <font-awesome-icon :icon="['fas', 'check']" />
              </span>
            </transition>

            <transition name="fade">
              <span
                class="profile-edit__toggler-saving"
                v-if="isUpdatingField('cam_guru_engagement_reminder_notification')"
              >
                <font-awesome-icon :icon="['fas', 'spinner']" spin />
              </span>
            </transition>

            <app-toggler
              v-model="settings.cam_guru_engagement_reminder_notification"
              @change="updateSettings($event, 'cam_guru_engagement_reminder_notification')"
            />
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import pwaNotifications from "@course-portal/mixins/pwaNotifications";
import TransitionExpand from '@shared/components/utils/TransitionExpand.vue'
import { mapState } from "vuex";

export default {
  components: {
    TransitionExpand,
  },
  mixins: [pwaNotifications],
  data () {
    return {
      updatingSettingsFields: [],
      updatedSettingsFields: [],
    }
  },
  computed: {
    ...mapState("settings", {
      settings: state => state.settings,
    }),
  },
  watch: {
    appNotificationsAllowed: {
      immediate: true,
      handler (val) {
        this.setupNotifications(val)
      }
    },
    _state (val) {
      if (!this.appNotificationsAllowed) return

      this.handlePermissionsState(val)
    },
    _allowed (val) {
      this._updating = true

      setTimeout(() => {
        const notAllowed = ['denied', 'unknown'].includes(this._state);

        this._updating = false;

        if (val && !notAllowed) {
          this.$store.dispatch('pwa/setAppNotificationsAllowed', val)
          this.$store.dispatch("settings/updateSettings", {
            key: 'cam_guru_engagement_reminder_notification',
            choice: true,
          })
          this._pushDeviceToken()
          this.setAllowNotificationsUpdated();
        } else if (!val && !notAllowed) {
          this.$store.dispatch('pwa/setAppNotificationsAllowed', val)
          this._removeDeviceToken()
          this.setAllowNotificationsUpdated();
        } else if (val && notAllowed) {
          this.$store.dispatch('pwa/setAppNotificationsAllowed', true)
          this._iosview.initiateAllowNotificationsPopup();
          this._allowed = false;
        } else if (val && this._state == 'notDetermined') {
          this.$store.dispatch('pwa/setAppNotificationsAllowed', true)
          this.$router.push({ name: "notifications", params: { step: "1" } });
          this._allowed = false;
        }
      }, 1000);
    },
  },
  methods: {
    setAllowNotificationsUpdated () {
      this._updated = true;

      setTimeout(() => {
        this._updated = false;
      }, 2000);
    },
    handlePermissionsState (state) {
      const allowed = ['authorized', 'ephemeral', 'provisional'].includes(state);

      if (state === "notDetermined" || state === "unknown") {
        this.$router.push({ name: "notifications", params: { step: "1" } });
      } else if (allowed) {
        this._pushDeviceToken()
        this._allowed = true;
      } else if (state === "denied") {
        this._removeDeviceToken()
        this._allowed = false;
      }
    },
    setupNotifications (allowed) {
      if (!allowed) {
        this._allowed = false
        return
      }

      this._checkPermissionState()
    },
    isUpdatedField(field) {
      return this.updatedSettingsFields.find((item) => item === field);
    },
    isUpdatingField(field) {
      return this.updatingSettingsFields.find((item) => item === field);
    },
    updateSettings (event, field) {
      this.$emit('updateSettings', {
        event: event,
        field: field
      })
    },
  }
}
</script>