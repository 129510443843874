<template>
  <div class="lesson-ph" :class="{'lesson-ph--wide': isTheaterMode}">
    <div class="lesson-ph__item lesson-ph__item--hidden video-ph" :class="{'video-ph--wide': isTheaterMode}"></div>

    <div class="lesson-ph__columns">
      <div class="content-ph">
        <div class="content-ph__lesson-info">
          <div class="lesson-ph__item content-ph__lesson-title content-ph__lesson-title--line-1"></div>
          <div class="lesson-ph__item content-ph__lesson-title content-ph__lesson-title--line-2"></div>
        </div>

        <div class="content-ph__nav-holder"></div>
      </div>

      <div class="sidebar-ph">
        <div class="sidebar-ph__item" v-for="(item, index) in 8" :key="index"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LessonPlaceholder',
  computed: {
    ...mapState('settings', {
      settings: state => state.settings,
      guestSettings: state => state.guestSettings
    }),
    ...mapState('lesson', {
      lesson: state => state.lesson
    }),
    ...mapState('user', {
      guest: state => state.guest
    }),
    isTheaterMode () {
      return this.guest ? this.guestSettings.theater_mode : this.settings.theater_mode
    }
  }
}
</script>

<style lang="scss" scoped>
  .lesson-ph {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    z-index: 101;
    
    @include tablet-landscape {
      padding: 14px 0 0 14px;
    }
    @include ipad-pro-129(portrait) {
      padding: 0;
    }

    &--wide {
      @include tablet-landscape {
        padding-right: 14px;

        .sidebar-ph {
          display: block;
        }
      }
    }

    &__columns {
      display: flex;
      justify-content: center;
    }

    &__item {
      position: relative;
      border-radius: 5px;
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;

      &--hidden {
        animation: none;
      }
    }
  }

  .video-ph {
    position: relative;
    padding-bottom: 56.25%;
    border-radius: 0;
    overflow: hidden;
    z-index: 100;

    @include phone-landscape (landscape) {
      position: relative;
      top: auto;
      height: calc(100vh - #{$app-header-height});
      padding-bottom: 0;

      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - #{$app-header-height} - 20px);
      }
    }
    @include tablet-portrait {
      height: auto;
      padding-bottom: 56.25%;
    }
    @include tablet-landscape {
      border-radius: 4px;
    }
    @include iphone-11 (landscape) {
      height: calc(100vh - #{$app-header-height});
      padding-bottom: 0;

      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - #{$app-header-height} - 20px);
      }
    }
    @include iphone-11-pro (landscape) {
      height: calc(100vh - #{$app-header-height});
      padding-bottom: 0;

      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - #{$app-header-height} - 20px);
      }
    }
    @include iphone-11-pro-max (landscape) {
      height: calc(100vh - #{$app-header-height});
      padding-bottom: 0;

      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - #{$app-header-height} - 20px);
      }
    }
    @include iphone-12 (landscape) {
      height: calc(100vh - #{$app-header-height});
      padding-bottom: 0;

      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - #{$app-header-height} - 20px);
      }
    }
    @include iphone-12-pro-max (landscape) {
      height: calc(100vh - #{$app-header-height});
      padding-bottom: 0;

      @supports (-webkit-touch-callout: none) {
        height: calc(100vh - #{$app-header-height} - 20px);
      }
    }
    @include tablet-landscape (portrait) {
      position: relative;
      top: auto;
      height: auto;
      padding-bottom: 56.25%;
    }
    @include ipad-pro-129(portrait) {
      border-radius: 0;
    }

    &--wide {
      @include tablet-landscape {
        height: calc((9/16) * 100vw);
        max-height: calc(100vh - 169px);
        min-height: 480px;
        padding: 0;
      }
    }
  }

  .content-ph {
    width: 100%;

    @include tablet-landscape {
      width: 940px;
    }
    @include ipad-pro-129 (portrait) {
      width: 100%;
    }
    @include iphone-11-pro (landscape) {
      padding: 30px 10px;
    }

    &__lesson-info {
      height: 80px;
      padding: 20px 10px 8px 10px;
      background: var(--gray-8);

      @include phone-landscape {
        height: 60.59px;
        padding: 22px 20px 10px 20px;
      }
      @include tablet-landscape {
        background: none;
      }
      @include ipad-pro-129 (portrait) {
        background: var(--gray-8);
      }
    }

    &__lesson-title {
      height: 23px;

      @include phone-landscape {
        margin: 0;
      }

      &--line-1 {
        width: 90%;
        margin: 0 0 4px 0;

        @include phone-landscape {
          width: 80%;
          margin: 0;
        }
        @include tablet-portrait {
          height: 34px;
        }
        @include tablet-landscape {
          width: 80%;
        }
      }
      &--line-2 {
        width: 40%;

        @include phone-landscape {
          display: none;
        }
      }
    }

    &__nav-holder {
      height: 55.4px;
      background: var(--gray-8);

      @include tablet-landscape {
        height: 1px;
        margin: 60px 20px 0 20px;
        border-bottom: 1px solid var(--gray-5);
        background: none;
      }
      @include ipad-pro-129 (portrait) {
        height: 55.4px;
        padding: 0;
        margin: 0;
        border-bottom: none;
        background: var(--gray-8);
      }
    }

    &__tabs-holder {
      padding: 20px 10px;

      @include tablet-portrait {
        padding: 79px 20px 30px 20px;
      }
    }
  }

  .sidebar-ph {
    display: none;
    padding: 14px;

    @include tablet-landscape {
      flex: 0 0 350px;
    }
    @include desktop-xs {
      flex: 0 0 400px;
    }
    @include desktop-sm {
      flex: 0 0 460px;
    }
    @include desktop-lg {
      flex: 0 0 500px;
    }
    
    &__item {
      position: relative;
      height: 85px;
      margin: 0 0 10px 0;
      border-radius: 8px;
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;
    }
  }
</style>
