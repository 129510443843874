<template>
  <div class="quick-tips-comment" v-show="isVisible">
    <div class="quick-tips-comment__inner">
      <app-avatar class="quick-tips-comment__avatar" :src="isCurrentUser ? avatarImage : avatarSrc"></app-avatar>

      <div class="quick-tips-comment__main">
        <div class="quick-tips-comment__content">
          <div style="display: flex; align-items: center; gap: 8px">
            <template v-if="comment.user">
              <span class="quick-tips-comment__author" v-if="guest">{{ userName }}</span>
              <router-link :to="{name: 'user', params: {id: comment.user.id}}" class="quick-tips-comment__author" v-else>
                {{ userName }}
              </router-link>
            </template>

            <div class="quick-tips-comment__badge" :class="[`quick-tips-comment__badge--${comment.user.badge}`]" v-if="comment.user">
              {{ $t(`achievements.${comment.user.badge}`) }}
            </div>
          </div>

          <span v-html="addLinks(comment.content)"></span>
        </div>

        <div class="quick-tips-comment__actions" ref="comment">
          <div
            class="quick-tips-comment__action-btn quick-tips-comment__action-btn--date"
            :title="convertSimpleDate(comment.created_at)"
          >
            {{ timeago(comment.created_at) }}
          </div>

          <a class="quick-tips-comment__action-btn quick-tips-comment__action-btn--reply" @click="reply">{{ $t('reply') }}</a>
          <a class="quick-tips-comment__action-btn quick-tips-comment__action-btn--delete" @click="openDeletePopup" v-if="isCurrentUser">{{ $t('deleteButton') }}</a>
        </div>

        <div v-if="comment.replies.length" class="quick-tips-comment__replies">
          <div class="quick-tips-comment__replies-comments">
            <transition-group name="comment" appear>
              <quick-tips-comment
                v-for="(reply, index) in comment.replies"
                :comment="reply"
                :index="index"
                :key="reply.id"
                :parentId="comment.id"
                :isVisible="index < repliesMax"
                :isParentPinned="comment.pinned"
                @expandReplies="expandReplies"
              >
              </quick-tips-comment>
            </transition-group>
          </div>

          <div class="quick-tips-comment__replies-bottom" v-if="hasMoreReplies > 0 || isHideRepliesBtn">
            <a class="quick-tips-comment__more-comments" @click.stop="showMoreReplies" v-if="hasMoreReplies > 0">
              <font-awesome-icon :icon="['fas', 'chevron-down']" class="sidebar-module__expand-icon" />
              <span>{{ $t('comments.view') }} {{ availableReplies }} {{ $t('comments.more') }} {{ availableReplies > 1 ? $t('comments.replies') : $t('comments.reply') }}</span>
            </a>
            <a class="quick-tips-comment__more-comments" @click.stop="hideMoreReplies" v-if="isHideRepliesBtn">
              <font-awesome-icon :icon="['fas', 'chevron-up']" class="sidebar-module__expand-icon" />
              <span>{{ $t('comments.hideReplies') }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// CONSTANTS
const INITIAL_REPLIES_VISIBLE = 1

export default {
  props: {
    comment: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    parentId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      repliesMax: INITIAL_REPLIES_VISIBLE,
      repliesStep: 3
    }
  },
  computed: {
    ...mapGetters('user', ['avatarImage']),
    ...mapState('user', {
      guest: state => state.guest,
      userId: state => state.user ? state.user.id : null
    }),
    avatarSrc () {
      return this.comment.user && this.comment.user.avatar ? this.comment.user.avatar : this.vaporAsset('images/avatar-placeholder.png')
    },
    userName () {
      return this.comment.user ? `${this.comment.user.first_name} ${this.comment.user.last_name}` : ''
    },
    isCurrentUser () {
      return this.comment.user && this.comment.user.id === this.userId
    },
    hasMoreReplies () {
      return this.comment.replies.length - this.repliesMax
    },
    availableReplies () {
      return this.hasMoreReplies < this.repliesStep ? this.hasMoreReplies : this.repliesStep
    },
    isHideRepliesBtn () {
      return (this.hasMoreReplies < 1 && this.repliesMax > INITIAL_REPLIES_VISIBLE)
    },
  },
  methods: {
    expandReplies (index) {
      this.repliesMax = index
    },
    showMoreReplies () {
      this.repliesMax += this.repliesStep
    },
    hideMoreReplies () {
      this.$refs['comment'].scrollIntoView({ block: this.isMobile ? 'start' : 'center' })
      this.repliesMax = INITIAL_REPLIES_VISIBLE
    },
    reply () {
      this.$store.dispatch('quickTips/setCommentFocus', true)
      this.$store.dispatch('quickTips/setReplyTo', {
        ...this.comment,
        parentId: this.parentId
      })
    },
    openDeletePopup () {
      this.$store.dispatch('popup/setPopup', {
        type: 'comment_delete',
        text: this.$t('comments.deleteComment'),
        comment: this.comment,
        parentId: this.parentId,
        endpoint: 'quickTips'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .quick-tips-comment {
    position: relative;
    margin: 0 0 32px 0;

    &__inner {
      display: flex;
      gap: 8px;
      position: relative;
    }

    &__main {
      flex: 1;
    }

    &__pinned {
      display: inline-flex;
      align-items: center;
      font-size: $text-sm;
      color: var(--gray-1);
      margin: 0 0 8px 44px;
    }

    &__pinned-icon {
      font-size: 12px;
      margin: 0 8px 0 0;
    }

    &__avatar {
      height: 36px;
      min-width: 36px;
      z-index: 2;
    }

    &__author {
      display: table;
      font-size: 15px;
      font-weight: $semiBold;
      color: var(--white);
    }

    &__date {
      font-size: $text-sm;
      color: var(--gray-4);
      margin-left: 10px;
    }

    &__badge {
      display: table;
      font-size: $text-xs;
      line-height: 1.4;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--gray-1);
      padding: 1px 6px;
      border-radius: 4px;
      background-color: var(--beginner);

      &--Intermediate {
        background-color: var(--intermediate);
      }
      &--Advanced {
        background-color: var(--advanced);
      }
      &--Master {
        background-color: var(--master);
      }
      &--Staff {
        background-color: var(--staff);
      }
    }

    &__content {
      font-size: 15px;
      color: var(--white);
      line-height: 20px;
      overflow-wrap: break-word;
      word-wrap: break-word;

      > div {
        margin: 0 0 8px 0;
      }

      span {
        :deep(a) {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    &__highlight {
      transition: background-color .5s linear;
      &--active {
        background-color: var(--gray-4);
      }
    }

    &__show-more {
      color: var(--pink);
      margin: 0 0 0 5px;

      &:active {
        text-decoration: underline;
      }

      @include desktop-xs {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__actions {
      display: flex;
      gap: 16px;
      margin: 8px 0 0 0;
    }

    &__action-btn {
      position: relative;
      font-size: 15px;
      font-weight: $semiBold;
      color: #636366;

      &--cancel {
        &:active {
          color: var(--gray-1);
        }

        @include desktop-xs {
          &:hover {
            color: var(--gray-1);
          }
        }
      }

      &--reply {
        &:active {
          color: var(--gray-1);
        }

        @include desktop-xs {
          &:hover {
            color: var(--gray-1);
          }
        }
      }

      &--delete {
        &:active {
          color: var(--red);
        }

        @include desktop-xs {
          &:hover {
            color: var(--red);
          }
        }

        svg {
          margin-left: 5px;
        }
      }

      &--deleting {
        color: var(--red);
      }

      &--date {
        font-weight: 400;
        text-transform: lowercase;
        cursor: default;
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }

    &__replies {
      position: relative;
      margin: 32px 0 0 0;
    }

    &__replies-comments {
      &:last-child {
        margin-bottom: -32px;
      }
    }

    &__replies-bottom {
      position: relative;
      margin: -16px 0 0 0;
    }

    &__more-comments {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: $text-sm;
      color: var(--gray-3);
      margin: 0 0 0 44px;

      &:active {
        span {
          text-decoration: underline;
        }
      }

      @include desktop-xs {
        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }

      svg {
        font-size: $text-xs;
      }
    }

    &__context-menu {
      min-width: 162px;
      left: 0 !important;
    }

    .commenter {
      padding-left: 6px;

      @include tablet-portrait {
        padding-left: 14px;
      }
    }
  }
</style>