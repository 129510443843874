<template>
  <div class="comment" v-show="isVisible">
    <span class="comment__pinned" v-if="comment.pinned">
      <font-awesome-icon :icon="['fas', 'thumbtack']" class="comment__pinned-icon" />
      {{ $t('comments.pinned') }}
    </span>

    <div class="comment__inner">
      <app-avatar class="comment__avatar" :src="isCurrentUser ? avatarImage : avatarSrc" v-if="guest"></app-avatar>
      <router-link :to="{name: 'user', params: {id: comment.user.id}}" v-else>
        <app-avatar class="comment__avatar" :src="isCurrentUser ? avatarImage : avatarSrc"></app-avatar>
      </router-link>

      <div class="comment__main">
        <div class="comment__content" :class="{'comment__highlight': isHighlightedComment}" :ref="`comment${comment.id}`">
          <div style="display: flex; align-items: center; gap: 8px">
            <div class="comment__author" v-if="guest">
              {{ userName }}
            </div>
            <router-link :to="{name: 'user', params: {id: comment.user.id}}" class="comment__author" v-if="comment.user && !guest">
              {{ userName }}
            </router-link>

            <div class="comment__badge" :class="[`comment__badge--${comment.user.badge}`]" v-if="comment.user">
              {{ $t(`achievements.${comment.user.badge}`) }}
            </div>
          </div>

          <span v-html="addLinks(isTruncate ? contentTruncate : comment.content)"></span>

          <a class="comment__show-more" v-if="isTruncate && !showMore" @click="toggleMore">{{ $t('more') }}</a>
        </div>

        <div class="comment__actions" ref="comment">
          <div
            class="comment__action-btn comment__action-btn--date"
            :class="`comment__action-btn--${currentLanguage}`"
            :title="convertSimpleDate(comment.created_at)"
            @contextmenu.prevent="showContextMenu"
            v-swipe.tap="showContextMenu"
            v-click-outside="hideContextMenu"
          >
            {{ timeago(comment.created_at) }}

            <app-context-menu
              class="comment__context-menu"
              :show="isContextMenu"
              :items="contextItems"
              @copyLink="copyLink"
              @cancel="hideContextMenu"
            >
            </app-context-menu>
          </div>

          <a class="comment__action-btn comment__action-btn--reply" @click="toggleReplyBox" v-if="canReply">{{ $t('reply') }}</a>
          <a class="comment__action-btn comment__action-btn--delete" @click="openDeletePopup" v-if="isCurrentUser">{{ $t('deleteButton') }}</a>
        </div>

        <lesson-comment-rate :comment="comment" v-if="comment.is_rating_required"></lesson-comment-rate>

        <div v-if="comment.replies.length" class="comment__replies">
          <div class="comment__replies-comments">
            <transition-group name="comment" appear>
              <lesson-comment
                v-for="(reply, index) in comment.replies"
                :comment="reply"
                :index="index"
                :key="reply.id"
                :parentId="comment.id"
                :isVisible="index < repliesMax"
                :isParentPinned="comment.pinned"
                @expandReplies="expandReplies"
                @onReplyBoxToggle="toggleReplyBox"
              >
              </lesson-comment>
            </transition-group>
          </div>

          <div class="comment__replies-bottom">
            <a class="comment__more-comments" @click="showMoreReplies" v-if="hasMoreReplies > 0">
              <font-awesome-icon :icon="['fas', 'chevron-down']" class="sidebar-module__expand-icon" />
              <span>{{ $t('comments.view') }} {{ availableReplies }} {{ $t('comments.more') }} {{ availableReplies > 1 ? $t('comments.replies') : $t('comments.reply') }}</span>
            </a>
            <a class="comment__more-comments" @click="hideMoreReplies" v-if="isHideRepliesBtn">
              <font-awesome-icon :icon="['fas', 'chevron-up']" class="sidebar-module__expand-icon" />
              <span>{{ $t('comments.hideReplies') }}</span>
            </a>
          </div>
        </div>

        <div class="comment__commenter-nest" ref="commenter" v-if="!comment.is_reply">
          <transition-expand @transitionEnter="commenterExpanded">
            <lesson-commenter
              :focus="commenterFocus"
              :commentId="comment.id"
              :class="{'commenter--highlighted': commenterHighlighted}"
              @cancel="closeReplyBox"
              v-if="isReplying"
            >
            </lesson-commenter>
          </transition-expand>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LessonCommenter from './LessonCommenter.vue'
import LessonCommentRate from './LessonCommentRate.vue'
import TransitionExpand from '@shared/components/utils/TransitionExpand.vue'
import clickOutside from '@shared/directives/clickOutside.js'
import swipe from '@shared/directives/swipe.js'
import { mapGetters, mapState } from 'vuex'


// CONSTANTS
const INITIAL_REPLIES_VISIBLE = 3

export default {
  name: 'LessonComment',
  components: {
    LessonCommenter,
    LessonCommentRate,
    TransitionExpand
  },
  props: {
    comment: {
      type: Object,
      required: true
    },
    parentId: {
      type: Number,
      required: false,
      default: null
    },
    index: {
      type: Number,
      required: false,
      default: null
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    isParentPinned: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showMore: false,
      repliesMax: INITIAL_REPLIES_VISIBLE,
      repliesStep: 10,
      isReplying: false,
      isDeleting: false,
      isContextMenu: false,
      commenterFocus: false,
      commenterHighlighted: false
    }
  },
  computed: {
    ...mapGetters('course', ['courseLocked']),
    ...mapGetters('user', ['avatarImage']),
    ...mapState('user', {
      guest: state => state.guest,
      userId: state => state.user ? state.user.id : null
    }),
    ...mapState('course', {
      course: state => state.course
    }),
    ...mapState('lesson', {
      lesson: state => state.lesson
    }),
    avatarSrc () {
      return this.comment.user && this.comment.user.avatar ? this.comment.user.avatar : this.vaporAsset('images/avatar-placeholder.png')
    },
    userName () {
      return this.comment.user ? `${this.comment.user.first_name} ${this.comment.user.last_name}` : ''
    },
    commentBtn () {
      return this.isReplying ? 'Cancel' : 'Reply'
    },
    charMax () {
      return this.isMobile ? 350 : 450
    },
    isCourseICA() {
      return this.course?.id === 15
    },
    isCurrentUser () {
      return this.comment.user && this.comment.user.id === this.userId
    },
    isTruncate () {
      const isTolerance = this.comment.content.length - this.charMax < 100

      return this.comment.content.length > this.charMax && !isTolerance
    },
    isHideRepliesBtn () {
      return (this.hasMoreReplies < 1 && this.repliesMax > INITIAL_REPLIES_VISIBLE)
    },
    isHighlightedComment () {
      return this.comment.id === parseInt(this.$route.query.comment_id)
    },
    contentTruncate () {
      return this.showMore ? this.comment.content : this.comment.content.slice(0, this.charMax) + '...'
    },
    isVisibleReply () {
      return this.index < this.repliesMax
    },
    hasMoreReplies () {
      return this.comment.replies.length - this.repliesMax
    },
    availableReplies () {
      return this.hasMoreReplies < this.repliesStep ? this.hasMoreReplies : this.repliesStep
    },
    canReply() {
      return this.comment.is_reply
        ? !this.isParentPinned && !this.courseLocked && !this.isCourseICA && !this.guest
        : !this.comment.pinned && !this.courseLocked && !this.isCourseICA && !this.guest
    },
    contextItems () {
      return [
        {
          id: 0,
          title: this.$t('share.copyLink'),
          event: 'copyLink'
        }
      ]
    }
  },
  directives: {
    'swipe': swipe,
    'click-outside': clickOutside
  },
  methods: {
    toggleMore () {
      this.showMore = true
    },
    showMoreReplies () {
      this.repliesMax += this.repliesStep
    },
    hideMoreReplies () {
      this.$refs['comment'].scrollIntoView({ block: this.isMobile ? 'start' : 'center' })
      this.repliesMax = INITIAL_REPLIES_VISIBLE
    },
    toggleReplyBox () {
      if (this.comment.is_reply) {
        this.$emit('onReplyBoxToggle')
      } else {
        this.isReplying = true
        this.commenterExpanded()
      }
    },
    closeReplyBox() {
      this.isReplying = false
      this.commenterFocus = false
    },
    openDeletePopup () {
      this.trackEvent('button_clicked', { button: this.parentId ? 'lesson_view_delete_reply' : 'lesson_view_delete_comment' })

      this.$store.dispatch('popup/setPopup', {
        type: 'comment_delete',
        text: this.$t('comments.deleteComment'),
        comment: this.comment,
        parentId: this.parentId,
        endpoint: 'comments'
      })
    },
    scrollToComment () {
      const box = this.$refs[`comment${this.comment.id}`]

      // Briefly highlight the comment when in viewport
      const intersectionObserver = new IntersectionObserver((entries) => {
        let [entry] = entries

        if (entry.isIntersecting) {
          box.classList.add('comment__highlight--active')
          setTimeout(() => {
            box.classList.remove('comment__highlight--active')
            intersectionObserver.unobserve(box)
          }, 6000)
        }
      })

      intersectionObserver.observe(box)

      box.scrollIntoView({ behavior: 'smooth', block: 'center' })
    },
    showContextMenu () {
      this.isContextMenu = true
    },
    hideContextMenu () {
      this.isContextMenu = false
    },
    copyLink () {
      const url = `${location.protocol}//${location.host}${location.pathname}?comment_id=${this.comment.id}`

      this.copyTextToClipboard(url)
      this.hideContextMenu()
    },
    expandReplies (index) {
      this.repliesMax = index
    },
    commenterExpanded () {
      if (!this.$refs['commenter'] || !this.elementInViewport(this.$refs['commenter'])) {
        this.$refs['commenter'].scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.highlightCommenter(600)
      } else {
        this.highlightCommenter()
      }
    },
    highlightCommenter (delay = 0) {
      this.commenterFocus = false

      setTimeout(() => {
        this.commenterHighlighted = true
        this.commenterFocus = true
        setTimeout(() => this.commenterHighlighted = false, 2000)
      }, delay)
    }
  },
  mounted () {
    if (this.isHighlightedComment) {
      this.$emit('expandReplies', this.index + 1)
      setTimeout(() => this.scrollToComment(), 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .comment {
    position: relative;
    margin: 0 0 32px 0;

    &__inner {
      display: flex;
      gap: 8px;
      position: relative;
    }

    &__main {
      flex: 1;
    }

    &__pinned {
      display: inline-flex;
      align-items: center;
      font-size: $text-sm;
      color: var(--gray-1);
      margin: 0 0 8px 44px;
    }

    &__pinned-icon {
      font-size: 12px;
      margin: 0 8px 0 0;
    }

    &__avatar {
      height: 36px;
      min-width: 36px;
      z-index: 2;
    }

    &__author {
      display: table;
      font-size: 15px;
      font-weight: $semiBold;
      color: var(--white);
    }

    &__date {
      font-size: $text-sm;
      color: var(--gray-4);
      margin-left: 10px;
    }

    &__badge {
      display: table;
      font-size: $text-xs;
      line-height: 1.4;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--gray-1);
      padding: 1px 6px;
      border-radius: 4px;
      background-color: var(--beginner);

      &--Intermediate {
        background-color: var(--intermediate);
      }
      &--Advanced {
        background-color: var(--advanced);
      }
      &--Master {
        background-color: var(--master);
      }
      &--Staff {
        background-color: var(--staff);
      }
    }

    &__content {
      font-size: 15px;
      color: var(--white);
      line-height: 20px;
      overflow-wrap: break-word;
      word-wrap: break-word;

      > div {
        margin: 0 0 8px 0;
      }

      span {
        :deep(a) {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    &__highlight {
      transition: background-color .5s linear;
      &--active {
        background-color: var(--gray-4);
      }
    }

    &__show-more {
      color: var(--pink);
      margin: 0 0 0 5px;

      &:active {
        text-decoration: underline;
      }

      @include desktop-xs {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__actions {
      display: flex;
      gap: 16px;
      margin: 8px 0 0 0;

      + .comment__commenter-nest {
        margin-top: 16px;
      }
    }

    &__action-btn {
      position: relative;
      font-size: 15px;
      font-weight: $semiBold;
      color: #636366;

      &--cancel {
        &:active {
          color: var(--gray-1);
        }

        @include desktop-xs {
          &:hover {
            color: var(--gray-1);
          }
        }
      }

      &--reply {
        &:active {
          color: var(--gray-1);
        }

        @include desktop-xs {
          &:hover {
            color: var(--gray-1);
          }
        }
      }

      &--delete {
        &:active {
          color: var(--red);
        }

        @include desktop-xs {
          &:hover {
            color: var(--red);
          }
        }

        svg {
          margin-left: 5px;
        }
      }

      &--deleting {
        color: var(--red);
      }

      &--date {
        font-weight: 400;
        text-transform: lowercase;
        cursor: default;
      }

      &--de {
        text-transform: capitalize;
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }

    &__replies {
      position: relative;
      margin: 32px 0 0 0;

      + .comment__commenter-nest {
        margin-top: 16px;
      }
    }

    &__replies-comments {
      &:last-child {
        margin-bottom: -32px;
      }
    }

    &__replies-bottom {
      position: relative;
      margin: -16px 0 0 0;
    }

    &__more-comments {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: $text-sm;
      color: var(--gray-3);
      margin: 0 0 0 44px;

      &:active {
        span {
          text-decoration: underline;
        }
      }

      @include desktop-xs {
        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }

      svg {
        font-size: $text-xs;
      }
    }

    &__context-menu {
      min-width: 162px;
      left: 0 !important;
    }
  }
</style>
