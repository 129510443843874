<template>
  <div class="toast" :class="`toast--${notification.type}`">
    <div class="toast__icon">
      <div class="toast__icon-img" :style="{backgroundImage: `url(${notification.data.image})`}"></div>
    </div>

    <div class="toast__body">
      <p class="toast__description">{{ notification.data.message }}</p>
    </div>

    <div class="toast__close" @click.stop="$emit('close-toast')"><span>&times;</span></div>
  </div>
</template>

<script>
export default {
  name: 'NotificationNotification',
  props: {
    notification: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .toast {
    &__icon {
      position: relative;
      height: 50px;
      width: 50px;
      min-width: 50px;
      margin: 0 10px 0 0;
      border: 2px solid rgba(255,255,255,0.2);
      border-radius: 50%;
      overflow: hidden;
      
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        background: rgba(255,255,255,0.4);
      }

      @include tablet-portrait {
        height: 56px;
        width: 56px;
        min-width: 56px;
      }
    }

    &__icon-img {
      height: 100%;
      width: 100%;
      background-position: 50% 50%;
      background-size: cover;
    }
  }
</style>