<template>
  <div class="content">
    <div class="content__lesson-info" ref="info">
      <template v-if="lesson.upsell_cta">
        <router-link :to="{ name: 'lesson', params: { id: lesson.upsell_cta.current_lesson_id } }"
          class="content__upsell-btn" v-if="lesson.upsell_cta.current_lesson_id">
          <span>{{ lesson.upsell_cta.text }}</span>
        </router-link>
        <a v-if="!isAppMode && !lesson.upsell_cta.current_lesson_id" :href="handleSalesPageLinkParams(lesson.upsell_cta.url)" target="_blank" class="content__upsell-btn">
          <span>{{ lesson.upsell_cta.text }} <font-awesome-icon :icon="['fas', 'chevron-right']" /></span>
        </a>
      </template>

      <div class="content__title-nest">
        <h1 class="content__lesson-title">{{ preventWidows(lesson.title) }}</h1>

        <template v-if="!isPhone">
          <lesson-share-video v-if="isShareButton" />
        </template>
      </div>
    </div>

    <div class="content__nav-holder" :class="{ 'content__nav-holder--with-banner': banner && isIPad }" ref="navHolder">
      <app-tabs :class="`tabs--${currentLanguage}`" :tabs="tabs" :activeTab="activeTab" :module="'lesson'" :loadedFromLesson="true" />

      <template v-if="isPhone">
        <lesson-share-video v-if="isShareButton" />
      </template>
    </div>

    <div class="content__tabs-holder" :class="{ 'content__tabs-holder--dim': isShareTooltip && isPhone }"
      ref="tabsHolder">
      <keep-alive>
        <component :is="activeComponent.component" :loadedFromLesson="true"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import LessonShareVideo from './share/index.vue'
import LessonCourseOverview from './LessonCourseOverview.vue'
import LessonComments from './LessonComments.vue'
import LessonProject from './LessonProject.vue'
import LessonFiles from './LessonFiles.vue'
import LessonNotes from './LessonNotes.vue'
import LessonDescription from './LessonDescription.vue'
import Sidebar from '@course-portal/components/sidebar/index.vue'
import { mapGetters, mapState } from 'vuex'


export default {
  name: 'LessonContent',
  components: {
    LessonShareVideo,
    LessonCourseOverview,
    LessonComments,
    LessonProject,
    LessonFiles,
    LessonNotes,
    LessonDescription,
    Sidebar
  },
  props: {
    parent: {
      type: HTMLDivElement,
      required: false,
      default: null
    }
  },
  data() {
    return {
      showShareTooltip: true,
      dispatchChangeOfTabsEvent: false,
    }
  },
  computed: {
    ...mapGetters('courses', ['courseOnSale']),
    ...mapGetters('course', ['courseLocked']),
    ...mapGetters('lesson', ['tabs']),
    ...mapState('settings', {
      settings: state => state.settings,
      guestSettings: state => state.guestSettings
    }),
    ...mapState('layout', {
      headerHeight: state => state.headerHeight,
      isLandscape: state => state.isLandscape
    }),
    ...mapState('course', {
      course: state => state.course
    }),
    ...mapState('lesson', {
      lesson: state => state.lesson,
      module: state => state.module,
      activeTab: state => state.activeTab,
      defaultTab: state => state.defaultTab
    }),
    ...mapState('banner', {
      banner: state => state.banner
    }),
    ...mapState('user', {
      user: state => state.user,
      guest: state => state.guest
    }),
    activeComponent() {
      const tab = this.tabs.find(item => {
        return item.id === this.activeTab
      })
      return tab || this.tabs[this.defaultTab]
    },
    isLessonsTab() {
      const currentTab = this.tabs.find((tab) => tab.id === this.activeTab)
      return currentTab.name === 'Lessons'
    },
    isShareButton() {
      return this.lesson.video_id && this.course && !this.course.legacy_product
    },
    isShareTooltip() {
      return !this.guest && this.lesson.video_id && this.settings && !this.settings.hide_share_feature_tooltip && this.isPhone && this.course && !this.courseLocked
    }
  },
  watch: {
    activeTab() {
      const navPosition = this.$refs.tabsHolder.offsetTop - this.$refs.navHolder.clientHeight + 1
      const minHeight = `${window.innerHeight + window.scrollY - this.headerHeight}px`
      const minHeightMobile = `${window.innerHeight + navPosition - this.headerHeight}px`

      if (window.scrollY !== 0) this.isMobile ? this.preventJumpMobile(minHeightMobile) : this.preventJump(minHeight)

      this.trackChangeOfTabs()
    },
    isLandscape() {
      this.onOrientationChange()
    }
  },
  methods: {
    handleLockedRedirect () {
      if (!this.isAppMode) return
      if (this.lesson?.course?.locked) {
        this.$store.dispatch('notifications/showToast', {
          item: {
            type: 'error',
            content: {
              message: 'You need to unlock the course to access this&nbsp;lesson.'
            }
          },
          isMobile: this.isMobile
        })
        this.$router.push({ name: 'home' })
      }
    },
    preventJump(val) {
      if (this.parent) {
        this.parent.style.minHeight = val
      }
    },
    preventJumpMobile(val) {
      const navPosition = this.$refs.tabsHolder.offsetTop - this.$refs.navHolder.clientHeight + 1
      const tabsPosition = this.$refs.tabsHolder.offsetTop
      const offset = tabsPosition - this.headerHeight

      this.parent.style.minHeight = val
      if (window.scrollY > navPosition) window.scrollTo(0, offset)
    },
    onOrientationChange() {
      this.$store.dispatch('lesson/selectTab', this.lesson.video_id ? this.defaultTab : 0)
      this.updateTabsbarHeight()
    },
    onWindowScroll() {
      if (window.scrollY === 0) {
        this.preventJump(null)
      }
    },
    updateTabsbarHeight() {
      this.$store.dispatch('layout/setTabsbarHeight', this.$refs.navHolder.offsetHeight)
      this.$store.dispatch('layout/setInfoHeight', this.$refs.info.offsetHeight)
    },
    trackChangeOfTabs () {
      // Prevent the initial event from getting fired.
      if (!this.dispatchChangeOfTabsEvent) {
        this.dispatchChangeOfTabsEvent = true
        return
      }

      let event

      switch (this.tabs.find(tab => tab.id === this.activeTab)?.component) {
        case 'LessonCourseOverview':
          event = 'lesson_view_course_overview_selected'
          break
        case 'Sidebar':
          event = 'lesson_view_lessons_tab_bar_selected'
          break
        case 'LessonComments':
          event = 'lesson_view_comments_tab_bar_selected'
          break
        case 'LessonNotes':
          event = 'lesson_view_notes_tab_bar_selected'
          break
        case 'LessonFiles':
          event = 'lesson_view_files_tab_bar_selected'
          break
        case 'LessonAssignment':
          event = 'lesson_view_assignments_section_selected'
          break
        default:
          return
      }

      this.trackEvent('button_clicked', { button: event })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onWindowScroll)
    window.addEventListener('onscroll', this.onWindowScroll)
    this.updateTabsbarHeight()
    if (this.courseLocked) {
      this.$store.dispatch('lesson/selectTab', 0)
    } else {
      this.$store.dispatch('lesson/selectTab', this.defaultTab)
    }
    this.handleLockedRedirect();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll)
    window.removeEventListener('onscroll', this.onWindowScroll)
    this.$store.dispatch('lesson/selectTab', this.defaultTab)
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;

  @include tablet-landscape {
    width: $container-sm-width;
    max-width: 100%;
  }

  @include ipad-pro-129 (portrait) {
    width: 100%;
  }



  &__lesson-info {
    position: relative;
    padding: 20px 10px 8px 10px;
    background: var(--gray-8);
    z-index: 102;

    @include tablet-portrait(portrait) {
      padding: 22px 20px 10px 20px;
    }

    @include tablet-landscape {
      padding: 22px 20px 10px 20px;
      background: none;
    }

    @include ipad-pro-129 (portrait) {
      background: var(--gray-8);
    }

    @include iphone-11 (landscape) {
      padding: 20px 10px 8px 10px;
      background: var(--gray-8);
    }

    @include iphone-11-pro (landscape) {
      padding: 20px 10px 8px 10px;
      background: var(--gray-8);
    }

    @include iphone-11-pro-max (landscape) {
      padding: 20px 10px 8px 10px;
      background: var(--gray-8);
    }

    @include iphone-12 (landscape) {
      padding: 20px 10px 8px 10px;
      background: var(--gray-8);
    }

    @include iphone-12-pro-max (landscape) {
      padding: 20px 10px 8px 10px;
      background: var(--gray-8);
    }
  }

  &__upsell-btn {
    display: block;
    position: relative;
    width: 516px;
    max-width: 100%;
    line-height: 50px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    color: var(--gray-1);
    padding: 0 10px;
    margin: 0 0 20px 0;
    border-radius: 6px;
    background: linear-gradient(135deg, #ED1C24 -20.63%, #92278F 125.74%);

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
    }

    svg {
      margin-left: 8px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      border-radius: inherit;
      background: var(--gray-8);
      transition: all .2s ease-in-out;

      @include tablet-portrait {
        background: var(--black);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: inherit;
      background: linear-gradient(135.45deg, #B2182B 0.79%, #851E60 100%);
      opacity: 0;
      transition: all .4s ease-in-out;
    }

    &:hover {
      background: linear-gradient(135.45deg, #B2182B 0.79%, #851E60 100%);

      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &__title-nest {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__lesson-title {
    font-size: 20px;
    font-weight: $semiBold;
    margin: 0;

    @include tablet-portrait {
      font-size: 22px;
      line-height: 34px;
    }
  }

  &__nav-holder {
    display: flex;
    justify-content: space-between;
    position: relative;
    background: var(--gray-8);
    z-index: 103;

    @supports (position: sticky) {
      position: sticky;
      top: calc(#{$app-header-height} - 1px);
    }

    @include phone-landscape {
      position: relative;
      top: auto;
      background: var(--gray-8);
    }

    @include tablet-portrait(portrait) {
      display: block;
    }

    @include tablet-landscape {
      display: block;
      background: none;
    }

    @include ipad-pro-129 (portrait) {
      background: var(--gray-8);
    }

    &:before {
      content: '';
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--gray-5);

      @include tablet-landscape {
        display: block;
        left: 20px;
        right: 20px;
        width: auto;
      }

      @include ipad-pro-129 (portrait) {
        display: none;
      }
    }

    &--with-banner {
      @media (orientation: portrait) {
        @supports (position: sticky) {
          position: sticky;
          top: calc(#{$app-header-height} + #{$app-banner-height} - 1px);
        }
      }
    }

    .tabs {
      min-width: calc(100% - 55px);

      @include tablet-portrait(portrait) {
        width: auto;
        padding: 0 20px;
      }

      @include tablet-landscape {
        width: auto;
        padding: 0 20px;
      }

      &--es {
        min-width: calc(100% - 70px);
      }

      &--pt {
        min-width: calc(100% - 80px);
      }
    }
  }

  &__tabs-holder {
    position: relative;
    padding: 32px 10px;
    transition: .4s ease-in-out;
    transition-property: height, opacity;

    @include tablet-portrait {
      padding: 32px 20px;
    }

    @include iphone-11 (landscape) {
      padding: 32px 10px;
    }

    @include iphone-11-pro (landscape) {
      padding: 32px 10px;
    }

    @include iphone-11-pro-max (landscape) {
      padding: 32px 20px;
    }

    @include iphone-12 (landscape) {
      padding: 32px 10px;
    }

    @include iphone-12-pro-max (landscape) {
      padding: 32px 20px;
    }

    &--dim {
      opacity: .3;
    }
  }
}
</style>
