<template>
  <div class="quick-tips-ph__list">
    <div class="quick-tips-ph__item" v-for="(video, index) in 5" :key="index">
      <div class="quick-tips-ph__nest"></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.quick-tips-ph {
  &__list {
    position: relative;
    padding: 0 0 80px 0;
  }

  &__item {
    position: relative;
    height: calc(var(--app-height) - #{$app-header-height});

    @include phone-landscape {
      height: calc(100vh - #{$app-header-height});
      padding: 24px 0 0 0;
      margin-bottom: calc(-90px);
    }
  }

  &__nest {
    position: relative;
    height: 100%;
    background: var(--gray-8);
    animation: shimmer 1s ease-in-out infinite;
    animation-direction: alternate;

    @include phone-landscape {
      aspect-ratio: 9 / 16;
      height: calc(100% - 90px);
      margin: 0 auto;
      border-radius: 16px;
    }
  }
}
</style>