<template>
  <app-container class="quick-tips-all-ph__thumbnail-container">
    <div class="quick-tips-all-ph__thumbnail" v-for="(video, index) in 8" :key="index"></div>
  </app-container>
</template>

<script>
import AppContainer from '@course-portal/components/layout/AppContainer.vue'

export default {
  components: {
    AppContainer
  }
}
</script>

<style lang="scss" scoped>
.quick-tips-all-ph__thumbnail {
    position: relative;
    aspect-ratio: 3/5;
    background: var(--gray-8);
    animation: shimmer 1s ease-in-out infinite;
    animation-direction: alternate;
  &-container {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;

    @include tablet-portrait {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 8px;
    }
  }

}
</style>