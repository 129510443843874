<template>
  <a class="annotation" @click="annotationClick(annotation)">
    <span class="annotation__content" :class="{'video-controls-visible': videoControlsVisible}">{{ annotation.content }}</span>
    <font-awesome-icon :icon="['fas', icon]" class="annotation__icon" />
  </a>
</template>

<script>
import { mapState } from 'vuex'


export default {
  props: {
    annotation: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('lesson', {
      lesson: state => state.lesson
    }),
    ...mapState('layout', {
      tabsElement: state => state.tabsElement,
      videoControlsVisible: state => state.videoControlsVisible
    }),
    icon () {
      switch (this.annotation.type) {
        case 'external_link':
          return 'link'
        case 'important_update':
          return 'chevron-down'
        default:
          return 'play'
      }
    },
    isBlog () {
      return this.annotation.url && (this.annotation.url.includes('iphonephotographyschool.com') || this.annotation.url.includes('photographypro.com'))
    }
  },
  methods: {
    handleImportantUpdate () {
      if (this.isFullscreen()) {
        this.closeFullscreen()
      }

      setTimeout(() => {
        this.$store.dispatch(`lesson/selectTab`, 4)
        this.$store.dispatch(`layout/setNotesHighlighted`, true)
        this.tabsElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, this.isFullscreen() ? 1000 : 0)
    },
    annotationClick (annotation) {
      const video = Wistia.api(this.lesson.video_id)

      switch (this.annotation.type) {
        case 'external_link':
          this.trackEvent('button_clicked', {
            button: 'lesson_view_open_notes_link',
            notes_link: annotation.url
          })
          window.open(`${annotation.url}${this.isBlog ? '?customer' : ''}`, '_blank').focus()
          video.pause()
          break
        case 'important_update':
          this.handleImportantUpdate()
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .annotation {
    display: flex;
    align-items: center;
    position: relative;
    line-height: 35px;
    font-size: 12px;
    font-weight: $medium;
    white-space: nowrap;
    padding: 0 12px;
    border-radius: 5px;
    background: rgba(var(--gray-8-rgb), 0.9);

    @include tablet-portrait {
      font-size: $text-md;
      background: rgba(var(--gray-8-rgb), 0.8);
    }

    &__content {
      text-overflow: ellipsis;
      overflow: hidden;
      &.video-controls-visible {
        max-width: 40vw;
      }
    }
    &__icon {
      margin: 1px 0 0 8px;
      font-size: $text-sm;
    }
  }
</style>
