<template>
  <div class="profile-achievements">
    <template v-if="achievements">
      <p class="profile-achievements__info" v-if="nextBadge">
        {{ $t('achievements.earn') }} {{ nextBadge.required_achievements }}
        <template v-if="currentLanguage === 'es'">
          {{ achievementsPlural }} {{ achievements.unlocked.length ? ` ${$t('achievements.more')} ` : '' }}
        </template>
        <template v-else>
          {{ achievements.unlocked.length ? ` ${$t('achievements.more')}` : '' }} <span v-html="achievementsPlural"></span>
        </template>
        <span v-html="$t('achievements.toGetThe')"></span>
        <span class="profile-achievements__badge" :class="`profile-achievements__badge${badgeClass}`">
          {{ nextBadge ? $t(`achievements.${nextBadge.name}`) : '&nbsp;' }}
        </span>
        <span v-html="$t('achievements.studentStatus')"></span>
      </p>

      <div class="profile-achievements__nest profile-achievements__nest--locked">
        <h3 class="profile-achievements__title">{{ $t('achievements.lockedAchievements') }}
          <span class="profile-achievements__show-all" @click="showAll" v-if="isShowAll">
            {{ showAchievements ? $t('showAll') : $t('close') }}
          </span>
        </h3>

        <div class="profile-achievements__grid">
          <achievements-item v-for="achievement in achievementsLocked" :achievement="achievement" :key="achievement.id"></achievements-item>
        </div>
      </div>

      <div class="profile-achievements__nest profile-achievements__nest--unlocked">
        <h3 class="profile-achievements__title">{{ $t('achievements.earnedAchievements') }}
          <span class="profile-achievements__earned"><span class="profile-achievements__star">&#9733;</span> {{ achievements.unlocked.length}}</span>
        </h3>

        <div class="profile-achievements__grid" v-if="achievements.unlocked.length">
          <achievements-item v-for="achievement in achievements.unlocked" :achievement="achievement" :key="achievement.id"></achievements-item>
        </div>
        <div class="profile-achievements__empty" v-else>
          <p class="profile-achievements__text">{{ $t('achievements.noAchievements') }}</p>

          <router-link :to="{name: 'lesson', params:{id: firstLesson}}" v-if="courses">
            <app-button class="profile-achievements__button" color="white">
              <font-awesome-icon :icon="['fas', 'play']"  class="profile-achievements__button-icon" />
              {{ $t('course.watchTheFirstVideo') }}
            </app-button>
          </router-link>
        </div>
      </div>
    </template>
    <achievements-placeholder v-else></achievements-placeholder>
  </div>
</template>

<script>
import AchievementsPlaceholder from './ProfileAchievementsPlaceholder.vue'
import AchievementsItem from './ProfileAchievementsItem.vue'
import { mapGetters, mapState } from 'vuex'

// Constant values
const DEFAULT_ACHIEVEMENTS_AMOUNT = 5
const DEFAULT_ACHIEVEMENTS_AMOUNT_MOBILE = 3

export default {
  name: 'ProfileAchievements',
  components: {
    AchievementsPlaceholder,
    AchievementsItem
  },
  props: {
    tabId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      showAchievements: null
    }
  },
  computed: {
    ...mapGetters('courses', ['firstLesson']),
    ...mapState('profile', {
      achievements: state => state.achievements,
      nextBadge: state => state.nextBadge
    }),
    ...mapState('courses', {
      courses: state => state.courses
    }),
    achievementsLocked () {
      return this.showAchievements ? this.achievements.locked.slice(0, this.showAchievements) : this.achievements.locked
    },
    achievementsPlural () {
      return this.nextBadge.required_achievements > 1 ? this.$t('achievements.achievements') : this.$t('achievements.achievement')
    },
    isShowAll () {
      if (this.isPhone) {
        return this.achievements.locked.length > DEFAULT_ACHIEVEMENTS_AMOUNT_MOBILE
      } else {
        return this.achievements.locked.length > DEFAULT_ACHIEVEMENTS_AMOUNT
      }
    },
    badgeClass () {
      switch (this.nextBadge.name) {
        case 'Intermediate':
          return '--Intermediate'
        case 'Advanced':
          return '--Advanced'
        case 'Master':
          return '--Masater'
        case 'Staff':
          return '--Staff'
      }
    }
  },
  methods: {
    setShowAchievements () {
      this.isPhone ? this.showAchievements = DEFAULT_ACHIEVEMENTS_AMOUNT_MOBILE : this.showAchievements = DEFAULT_ACHIEVEMENTS_AMOUNT
    },
    showAll () {
      if (this.showAchievements) {
        this.showAchievements = null
      } else {
        this.setShowAchievements()
      }
    }
  },
  mounted () {
    this.setShowAchievements()
  }
}
</script>

<style lang="scss" scoped>
  .profile-achievements {
    text-align: center;

    &__info {
      font-size: 18px;
      color: var(--gray-1);
      margin: 0 0 24px 0;
      line-height: 26px;

      @include tablet-portrait {
        margin: 0 0 32px 0;
      }
      @include iphone-11 (landscape) {
        margin: 0 0 24px 0;
      }
      @include iphone-11-pro (landscape) {
        margin: 0 0 24px 0;
      }
      @include iphone-11-pro-max (landscape) {
        margin: 0 0 24px 0;
      }
      @include iphone-12 (landscape) {
        margin: 0 0 24px 0;
      }
      @include iphone-12-pro-max (landscape) {
        margin: 0 0 24px 0;
      }
    }

    &__badge {
      display: inline-block;
      font-size: $text-sm;
      line-height: 25px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      padding: 0 7px;
      margin: 0 2px;
      border-radius: 4px;
      background-color: var(--beginner);

      &--Intermediate {
        background-color: var(--intermediate);
      }
      &--Advanced {
        background-color: var(--advanced);
      }
      &--Master {
        background-color: var(--master);
      }
      &--Staff {
        background-color: var(--staff);
      }
    }

    &__nest {
      padding: 20px 0;
      margin: 0 0 30px 0;
      border-radius: 8px;
      background: var(--gray-8);

      &:last-child {
        margin: 0;
      }
    }

    &__grid {
      display: flex;
      flex-flow: row wrap;
    }

    &__title {
      display: flex;
      align-items: center;
      font-size: 17px;
      font-size: normal;
      text-align: left;
      padding: 0 20px;
      margin: 0 0 30px 0;
    }

    &__show-all,
    &__earned {
      font-size: 16px;
      font-weight: normal;
      color: var(--white);
      margin-left: auto;
    }

    &__show-all {
      cursor: pointer;

      &:active {
        color: var(--gray-1);
      }

      @include desktop-xs {
        &:hover {
          color: var(--gray-1);
        }
      }
    }

    &__star {
      color: var(--yellow);
      font-size: 18px;
    }

    &__empty {
      text-align: left;
      padding: 0 20px 20px 20px;
    }

    &__text {
      font-size: $text-lg;
      color: var(--gray-1);
      margin: 0 0 20px 0;
    }

    &__button-icon {
      margin: 0 5px 0 0;
    }
  }
</style>