<template>
  <div class="row" :class="{'row--between': between}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppRow',
  props: {
    between: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .row {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;

    &--between {
      justify-content: space-between;
    }
  }
</style>