<template>
  <div
    class="video"
    :class="{
        'video--ended': showNextLesson,
        'video--mobile': isMobile,
        'video--wide': isTheaterMode,
        'video--annotation': annotationActive,
        'video--floating': floating,
        'video--selecting-language': !langSet
    }"
    @mouseenter="showBackBtn = true"
    @mouseleave="showBackBtn = false"
  >
    <canvas class="video__ambilight" ref="ambilight" v-show="!floating"></canvas>

    <div
      class="video__wrap"
      :class="[
        {
          'video__wrap--floating': floating,
          'video__wrap--portrait': lesson.portrait,
          'video__wrap--landscape': !lesson.portrait
        },
        position.class
      ]"
      @[moveEvent]="mouseDown"
      ref="videoWrap"
    >
      <div
        class="wistia_responsive_padding"
        :class="{'wistia_responsive_padding--portrait': lesson.portrait}"
        :style="{
          'height': isPhone && lesson.portrait ? `${mobileVideoSpace}px` : '',
          'padding-bottom': isPhone && lesson.portrait ? '0' : ''
        }"
      >
        <div class="wistia_responsive_wrapper" @mousemove="handleIdle(5000)" @mouseleave="idleState = true">
          <div :class="[{'wistia_embed--no-cursor': !videoControlsVisible}, `wistia_embed wistia_async_${lesson.video_id}`]"></div>

          <lesson-video-popup :floating="floating" v-if="showNextLesson && !guest" />
          <annotations :idle="idleState" v-if="!guest" />

          <transition name="appear">
            <div class="video__top" v-if="showBackBtn && previousTimestamp && !guest">
              <router-link :to="{ name: 'lesson', params: { id: lockedContentOrigin } }" class="video__back">
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </router-link>
            </div>
          </transition>
        </div>
      </div>

      <div class="video__wrap-overlay" v-if="floating">
        <a class="video__floating-close" v-scroll-to="scrollToTop">
          <font-awesome-icon :icon="['fas', 'up-right-from-square']" />
        </a>
        <a class="video__floating-play" @click="togglePlay">
          <font-awesome-icon :icon="['fas', 'pause']" v-if="playing" />
          <font-awesome-icon :icon="['fas', 'play']" v-else />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LessonVideoPopup from './LessonVideoPopup.vue'
import Annotations from './annotations/index.vue'
import wistiaMixin from '@course-portal/mixins/wistiaMixin'
import floatingVideoMixin from '@course-portal/mixins/floatingVideoMixin'
import { mapState } from 'vuex'

export default {
  name: 'LessonVideo',
  components: {
    LessonVideoPopup,
    Annotations
  },
  mixins: [wistiaMixin, floatingVideoMixin],
  data () {
    return {
      showBackBtn: false,
      idleState: true,
      idleTimer: null
    }
  },
  computed: {
    ...mapState('layout', {
      appHeight: state => state.appHeight,
      headerHeight: state => state.headerHeight,
      infoHeight: state => state.infoHeight,
      tabsbarHeight: state => state.tabsbarHeight,
      videoControlsVisible: state => state.videoControlsVisible
    }),
    ...mapState('settings', {
      settings: state => state.settings,
      guestSettings: state => state.guestSettings
    }),
    ...mapState('course', {
      course: state => state.course,
    }),
    ...mapState('lesson', {
      lesson: state => state.lesson,
      module: state => state.module,
      showUpcoming: state => state.showUpcoming,
      annotationActive: state => state.annotationActive,
      previousTimestamp: state => state.previousTimestamp,
      lockedContentOrigin: state => state.lockedContentOrigin
    }),
    ...mapState('notifications', {
      notifications: state => state.notifications
    }),
    ...mapState('user', {
      guest: state => state.guest
    }),
    isTheaterMode () {
      return this.guest ? this.guestSettings.theater_mode : this.settings.theater_mode
    },
    showNextLesson () {
      return this.showUpcoming && this.lesson.next_lesson_id
    },
    mobileVideoSpace () {
      return this.appHeight - (this.headerHeight + this.infoHeight + this.tabsbarHeight)
    },
    moveEvent () {
      return this.isMobile ? 'touchstart' : 'mousedown'
    },
    scrollToTop () {
      return {
        el: 'body',
        duration: 800,
        offset: 0
      }
    },
  },
  methods: {
    handleIdle (time) {
      this.idleState = false

      clearTimeout(this.idleTimer)

      this.idleTimer = setTimeout(() => { this.idleState = true }, time)
    },
    setAppHeight () {
      this.$store.dispatch('layout/setAppHeight', window.innerHeight)
    },
    togglePlay () {
      const video = Wistia.api(this.lesson.video_id)

      video.state() === 'playing' ? video.pause() : video.play()
    },
    fireTrackerEvent(action, name, customData) {
      if (customData) {
        this.trackEvent(action, customData)
        return
      }

      if (!this.course || !this.module || !this.lesson) return

      this.trackEvent(action, {
        ...(name && { button: name }),
        course_id: this.course.id,
        course_slug: this.course.product_slug,
        course_name: this.course.title,
        module_id: this.module.id,
        module_name: this.module.title,
        lesson_id: this.lesson.id,
        lesson_name: this.lesson.title,
      })
    }

  },
  mounted () {
    // Called from "wistiaMixin"
    this.wistiaConfig()
    this.afterOrientationChange(this.setAppHeight)
  },
  beforeUnmount () {
    this.$store.dispatch('lesson/setShowUpcoming', false)
  }
}
</script>

<style lang="scss">
  .video {
    position: relative;
    background-color: var(--black);
    background-size: cover;
    z-index: 100;

    &--ended {
      .w-bpb-wrapper {
        display: none !important;
      }
    }

    &--mobile {
      .w-bottom-bar-lower {
        >div {
          &:first-child {
            display: none;
          }
        }
      }
    }

    &--no-ui {
      .w-ui-container {
        display: none;
      }
    }

    &--floating {
      z-index: 201;
      touch-action: none;
    }

    &--selecting-language {
      button[aria-label="Show audio description menu"],
      button[aria-label="Hide audio description menu"] {
        + div {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &__back {
      position: absolute;
      top: 12px;
      left: 16px;
      transition: all .4;
      z-index: 5;

      @include tablet-portrait {
        top: 28px;
        left: 28px;
        font-size: 24px;
      }
      @include tablet-landscape {
        top: 12px;
        left: 16px;
      }
      @include desktop-md {
        top: 28px;
        left: 28px;
      }
    }

    &__wrap {
      &--landscape {
        .wistia {
          &_embed {
            div[data-handle="thumbnail"] {
              height: 100%;
              width: 100%;

              img {
                object-fit: cover;
                height: 100% !important;
                width: 100% !important;
                border: none !important;
              }
            }
          }
        }
      }

      &--floating {
        position: fixed;
        top: calc(100% - 14px - 225px);
        left: calc(100% - 14px - 400px);
        width: 400px;
        padding: 0;
        border-radius: 8px;
        background: var(--black);
        transform: translateY(20%);
        box-shadow: 0 0 90px rgb(var(--black-rgb),80%), 0 0 20px rgb(var(--black-rgb),80%);
        animation: fade-in-up 0.2s ease-in-out forwards;
        cursor: grab;
        z-index: 201;

        @include tablet-portrait (portrait) {
          top: calc(100% - 28px - 225px);
        }
        @include tablet-landscape {
          top: calc(100% - 14px - 225px);
        }

        &.video__wrap--portrait {
          width: 200px;
          &:has(.video-popup__next-lesson) {
            width: 400px;
            top: calc(100% - 14px - 225px);
            left: calc(100% - 14px - 400px);
            transition: width .4s ease-in-out;
            .video-popup__next-lesson, .video-popup__buttons {
              opacity: 0;
              animation: fade-in-up 0.4s ease-in forwards 0.4s;
            }
            .video-popup__next-lesson {
              user-select: none;
            }
          }
        }

        &:hover {
          .video__wrap-overlay {
            opacity: 1;
            visibility: visible;
          }
        }

        &:active {
          cursor: grabbing;
        }

        .wistia {
          &_responsive_padding {
            &--portrait {
              padding-bottom: 151% !important;
              transition: all .4s ease-in-out;
              &:has(.video-popup__next-lesson) {
                padding-bottom: 56.25% !important;
              }
            }
          }

          &_embed {
            div[data-handle="bigPlayButton"] {
              display: none !important;
            }

            div[data-handle="thumbnail"] {
              height: 100%;

              * {
                height: 100% !important;
                width: 100% !important;
              }
            }
            .w-bottom-bar,
            .w-vulcan-overlays-table,
            div[data-handle="playPauseNotifier"] {
              display: none !important;
            }
          }
        }

        .annotations-nest {
          display: none;
        }
      }

      &--top-left {
        top: calc(14px + $app-header-height + $app-banner-height);
        left: 14px;
        transition: all .4s ease-in-out;
      }
      &--top-right {
        top: calc(14px + $app-header-height + $app-banner-height);
        left: calc(100% - 14px - 400px);
        transition: all .4s ease-in-out;
      }
      &--bottom-left {
        top: calc(100% - 14px - 225px);
        left: 14px;
        transition: all .4s ease-in-out;
      }
      &--bottom-right {
        top: calc(100% - 14px - 225px);
        left: calc(100% - 14px - 400px);
        transition: all .4s ease-in-out;

        @include tablet-portrait (portrait) {
          top: calc(100% - 28px - 225px);
        }
        @include tablet-landscape {
          top: calc(100% - 14px - 225px);
        }
      }
    }

    .video__wrap--portrait {
      top: calc(100% - 14px - 302px);
      left: calc(100% - 14px - 200px);

      &.video__wrap--top-left {
        top: calc(14px + $app-header-height + $app-banner-height);
        left: 14px;
        transition: all .4s ease-in-out;
      }
      &.video__wrap--top-right {
        top: calc(14px + $app-header-height + $app-banner-height);
        left: calc(100% - 14px - 200px);
        transition: all .4s ease-in-out;
      }
      &.video__wrap--bottom-left {
        top: calc(100% - 14px - 302px);
        left: 14px;
        transition: all .4s ease-in-out;
      }
      &.video__wrap--bottom-right {
        top: calc(100% - 14px - 302px);
        left: calc(100% - 14px - 200px);
        transition: all .4s ease-in-out;
      }
      &:has(.video-popup__next-lesson) {
        &.video__wrap {
          &--top-left {
            top: calc(14px + $app-header-height + $app-banner-height);
            left: 14px;
          }
          &--top-right {
            top: calc(14px + $app-header-height + $app-banner-height);
            left: calc(100% - 14px - 400px);
          }
          &--bottom-left {
            top: calc(100% - 14px - 225px);
            left: 14px;
          }
          &--bottom-right {
            top: calc(100% - 14px - 225px);
            left: calc(100% - 14px - 400px);

            @include tablet-portrait (portrait) {
              top: calc(100% - 28px - 225px);
            }
            @include tablet-landscape {
              top: calc(100% - 14px - 225px);
            }
          }
        }
      }
    }

    &__wrap-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(var(--black-rgb),0.4) 0%, rgba(var(--black-rgb),0) 100%);
      opacity: 0;
      visibility: hidden;

      a {
        color: var(--white);
      }
    }

    &__floating-play {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 32px;
      padding: 16px;
      transform: translate(-50%, -50%);

      svg {
        display: block;
      }
    }

    &__floating-close {
      display: inline-flex;
      font-size: 24px;
      padding: 12px;

      svg {
        transform: scaleX(-1);
      }
    }

    .wistia {
      &_responsive_padding {
        position: relative;
        height: auto !important;
        padding: 0 0 56.25% 0 !important;

        &--portrait {
          @include tablet-portrait(portrait) {
            padding: 0 0 80% 0 !important;
          }
          @include tablet-landscape {
            padding: 0 0 56.25% 0 !important;
          }
        }
      }

      &_responsive_wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: none;
      }

      &_embed {
        display: block !important;
        height: 100% !important;
        width: 100% !important;

        &--no-cursor {
          * {
            cursor: none !important;
          }
        }

        &_initialized {
          * {
            cursor: pointer !important;
          }
        }

        >div {
          height: 100% !important;
          width: 100% !important;

          >div {
            height: 100% !important;
            width: 100% !important;

            >div:nth-child(2) {
              height: 100% !important;
              width: 100% !important;
            }
          }
        }

        video {
          object-fit: contain !important;
        }
      }
    }

    &--wide {
      @include tablet-landscape {
        .video__wrap:not(.video__wrap--floating) {
          .wistia_responsive_padding {
            height: calc((9/16) * 100vw);
            max-height: calc(100vh - 169px);
            min-height: 480px;
            padding: 0;
          }
        }
      }
    }

    .w-ui-container {
      &--disabled {
        .w-bottom-bar,
        div[data-handle="fullscreenVideo"],
        div[data-handle="fullscreen"],
        div[data-handle="skipVideo"] {
          visibility: hidden;
        }
      }
      // FF fix to show play button
      // when autoplay doesn't kick in
      >div {
        @-moz-document url-prefix() {
          visibility: visible !important;
        }
      }
    }
    .w-video-wrapper,
    .w-ui-container {
      overflow: hidden;

      @include tablet-landscape {
        border-radius: 4px;
      }
      @include ipad-pro-129(portrait) {
        border-radius: 0;
      }
    }

    .w-css-reset-tree {
      .w-chapter {
        font-size: 14px !important;
        line-height: 18px !important;

        >div {
          padding: 8px 4px !important;
        }
      }
    }
    .w-bpb-wrapper {
      top: 50% !important;
      left: 50% !important;
      margin: 0 !important;
      border-radius: 8px;
      overflow: hidden;
      transform: translate(-50%, -50%);
    }
    .w-big-play-button {
      height: 50px !important;
      width: 80px !important;
      background: linear-gradient(180deg, #C21938 0%, #861B83 100%) !important;
      @include tablet-portrait {
        height: 80px !important;
        width: 120px !important;
      }
      >div {
        background: none !important;
      }
      >* {
        height: 50px !important;
        width: 80px !important;
        @include tablet-portrait {
          height: 80px !important;
          width: 120px !important;
        }
      }
    }
    .w-bottom-bar-left-inner {
      .w-vulcan-button-wrapper {
        >button {
          padding: 2px 0 !important;
          @include tablet-landscape(landscape) {
            padding: 0 !important;
          }
          @include desktop-xs {
            padding: 2px 0 !important;
          }
        }
      }
    }
    .w-bottom-bar-middle-inner {
      @include phone-portrait(portrait) {
        display: flex !important;
        >div:first-child {
          flex: 1;
        }
      }
    }
    .w-bottom-bar-right-inner {
      .w-vulcan-button-wrapper {
        >button {
          padding: 5px 0 !important;
          @include phone-portrait(portrait) {
            padding: 6px 0 !important;
          }
          @include tablet-landscape(landscape) {
            padding: 0 !important;
          }
          @include desktop-xs {
            padding: 5px 0 !important;
          }
        }
        div[data-handle="skipVideo"],
        div[data-handle="fullscreen"],
        div[data-handle="theater"] {
          img {
            max-height: 100% !important;
          }
        }
      }
    }
    .w-ellipsis {
      .w-vulcan-button-wrapper {
        >button {
          @include phone-portrait(portrait) {
            padding: 6px 0 !important;
          }
        }
      }
    }
    .w-playbar-wrapper {
      .w-playbar__time {
        @include phone-portrait(portrait) {
          font-size: 14px !important;
        }
      }
    }
    .w-vulcan-overlays-table {
      @include phone-portrait {
        height: 100%;
      }
    }
    .w-vulcan-overlays--center {
      @include phone-portrait {
        button {
          background: none !important;
        }
        >div {
          >div[data-handle="playPauseLoading"] {
            button {
              @include tablet-portrait {
                height: 100px !important
              }
              >div:last-child {
                position: relative !important;
                padding: 12px 12px 12px 18px !important;
              }
            }
          }
        }
        div[data-handle="skipAhead"] {
          button {
            left: 75% !important;
          }
        }
        div[data-handle="skipBack"] {
          button {
            left: 25% !important;
          }
        }
        div[data-handle="skipAhead"],
        div[data-handle="skipBack"] {
          button {
            height: 80px !important;
            width: 80px !important;
          }
        }
        .w-big-play-button {
          background: linear-gradient(180deg, var(--red-gradient)) !important;
        }
      }
    }
    .w-foreground {
      div[data-handle="playbarlessProgressIndicator"] {
        >div {
          >div {
            height: 4px !important;
            background: var(--gray-3) !important;
            >div {
              background: linear-gradient(90deg, var(--red-gradient)) !important;
            }
          }
        }
      }
    }
    .w-vulcan-icon-wrapper {
        img {
            height: 100%;
        }
    }
    .w-captions {
      padding-bottom: 44px !important;
    }
    .w-controls-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 150%;
      width: 100%;
      background: rgba(var(--black-rgb),0.6);
      pointer-events: none;
    }
    .w-back {
      box-sizing: border-box !important;
      position: absolute;
      top: 3px;
      left: 0;
      height: 44px;
      font-size: 20px;
      line-height: 44px;
      text-align: center;
      color: #fff;
      padding: 0 10px;
      z-index: 1;
      cursor: pointer;

      svg {
        display: block;
        height: 100%;
        margin: 0 auto;
      }
    }
    .w-fullscreen {
      box-sizing: border-box !important;
      position: absolute;
      top: 3px;
      right: 0px;
      height: 44px;
      width: 52px;
      padding: 5px 0;

      img {
        display: block;
        height: 100%;
        margin: 0 auto;
      }
    }
    .w-next-video {
      box-sizing: border-box !important;
      position: absolute;
      top: 3px;
      right: 44px;
      height: 44px;
      width: 52px;
      padding: 5px 0;

      img {
        display: block;
        height: 100%;
        margin: 0 auto;
      }
    }

    &__ambilight {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      transform: translate(-50%, -50%);
      filter: blur(40px) opacity(.4) saturate(150%);
      pointer-events: none;

      @include tablet-portrait(portrait) {
        filter: blur(100px) opacity(.5) saturate(150%)
      }
      @include tablet-landscape {
        filter: blur(100px) opacity(.5) saturate(150%)
      }
    }

    @keyframes fade-in-up {
      0% {
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
</style>
