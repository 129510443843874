<template>
    <div class="native-notifications">
        <div class="native-notifications__wrapper" v-if="Number(step) === 1">
            <div>
                <div class="native-notifications__progress-bar">
                    <div class="native-notifications__progress-bar--inner"></div>
                </div>
                <h1 class="native-notifications__title">{{ $t('notificationFlow.whatsYourGoal') }}</h1>
            </div>
            <div class="native-notifications__radio-buttons">
                <div v-for="(option, index) in options" :key="index"
                    :class="{'native-notifications__radio-button': true, 'active': selectedOption === index}"
                    @click="selectOption(index)">
                    <p class="native-notifications__days" :class="{'active': selectedOption === index}">{{ option.days }}</p>
                    <p class="native-notifications__level" :class="{'active': selectedOption === index}">{{ option.level }}</p>
                </div>
            </div>
            <button @click="goToStep2" class="native-notifications__button">{{ $t('notificationFlow.imCommitted') }}</button>
        </div>
        <div class="native-notifications__wrapper" v-if="Number(step) === 2" >
            <div class="native-notifications__wrapper--step-2" @click="pushPermissionRequest">
                <div>
                    <div class="native-notifications__progress-bar">
                        <div class="native-notifications__progress-bar--inner"></div>
                    </div>
                    <h1 class="native-notifications__title">{{ $t('notificationFlow.wellRemindYou') }}</h1>
                </div>
                <img class="native-notifications__arrow-image" @click="pushPermissionRequest" src="https://images.iphonephotographyschool.com/35851/arrow.svg" alt="">
            </div>
            <button class="native-notifications__button" @click="pushPermissionRequest">{{ $t('forms.continue') }}</button>
        </div>
    </div>
    <div class="center-container">
        <img @click="pushPermissionRequest" class="native-notifications__notifications-placeholder" :class="{'step-2': Number(step) === 2}" src="https://images.iphonephotographyschool.com/35856/allow-placeholder.png" alt="">
    </div>
</template>

<script>
export default {
  name: 'AppNotifications',
  data() {
    return {
      selectedOption: 0, // The index of the currently selected option
      options: [
        { days: this.$t('notificationFlow.lesson1'), level: this.$t('notificationFlow.casual') },
        { days: this.$t('notificationFlow.lesson3'), level: this.$t('notificationFlow.regular') },
        { days: this.$t('notificationFlow.lesson5'), level: this.$t('notificationFlow.serious') },
      ],
    };
  },
  computed: {
    step() {
      return this.$route.params.step;
    },
  },
  methods: {
    goToStep2() {
      this.trackEvent('button_clicked', { button: 'notificaitons_first_step_continue_clicked' });
      this.$router.push({ name: 'notifications', params: { step: '2' } });
    },
    goHome(event) {
      if (event.detail === 'granted') {
        this.$store.dispatch("settings/updateSettings", {
          key: 'cam_guru_engagement_reminder_notification',
          choice: true
        })
      }
      this.$router.push({ name: 'home'});
    },
    selectOption(index) {
      this.selectedOption = index;
    },
    pushPermissionRequest() {
      this.trackEvent('button_clicked', { button: 'second_step_continue_clicked' });
      window.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');
    },
  },
  mounted () {
    this.trackEvent('allow_notifications_flow_started')
    window.addEventListener('push-permission-request', this.goHome);
  },
  beforeUnmount () {
    window.removeEventListener('push-permission-request', this.goHome);
  }
}
</script>

<style lang="scss" scoped>
    .native-notifications {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        &__wrapper {
            max-width: 370px;
            width: 100%;
            padding: 10px;
            height: 80%;
            max-height: 550px;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            &--step-2 {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: start;
                height: 100%;
                gap: 30px;
            }
        }
        &__title {
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            width: 240px;
        }
        &__progress-bar {
            margin-bottom: 16px;
            width: 120px;
            height: 12px;
            border-radius: 24px;
            background: #1C1C1E;
            &--inner {
                width: 40%;
                height: 100%;
                border-radius: 24px;
                background: var(--gradient-tones-camGradientRight, linear-gradient(90deg, #DD213A 0%, #B1216E 100%));
            }
        }
        &__radio-buttons {
            display: flex;
            gap: 18px;
            flex-direction: column;
        }
        &__radio-button {
            width: 100%;
            background: #1C1C1E;
            display: flex;
            gap: 18px;
            flex-direction: row;
            justify-content: space-between;
            padding: 7px 24px;
            border-radius: 80px;
            border: 1px solid #1C1C1E;
            cursor: pointer;
        }
        &__radio-button.active {
            border: 1px solid white;
        }
        &__days {
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            color:#8D8E93;
        }
        &__days.active {
            color: #FFF;
        }

        &__level {
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            color: #48484A;
        }
        &__level.active {
            color: #DDDFE9;
        }
        &__button {
            display: flex;
            width: 340px;
            padding: 16px 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 14px;
            background: #FFF;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            color: black;
            cursor: pointer;
        }
        &__notifications-placeholder {
            max-width: 270px;
            margin: 0 auto;
            display: block;
            width: 90%;
            margin-bottom: 20px;
            opacity: 0;
            z-index: -1;
            &.step-2 {
                opacity: 1;
            }
        }
        &__image-wrapper {
            position: relative;
        }
        &__arrow-image {
            position: absolute;
            right: 103px;
            bottom: 50px;
            animation: jump 0.8s infinite;
        }

        @keyframes jump {
            0%, 100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-10px);
            }
        }
    }
    .center-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Adjust as needed */;
        position: fixed;
        width: 100%;
        z-index: -1;
    }
</style>
