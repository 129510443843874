import { iosview } from "@course-portal/iosview.js";
import { mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      _iosview: iosview,
      _allowed: false,
      _available: false,
      _updating: false,
      _updated: false,
      _state: '',
    }
  },
  computed: {
    ...mapGetters('pwa', ['appNotificationsAllowed']),
    ...mapState("pwa", {
      deviceToken: (state) => state.deviceToken,
    })
  },
  methods: {
    _getAppSetup ()  {
      if (!this.appNotificationsAllowed) {
        return this._removeDeviceToken()
      }

      this._checkPermissionState()
    },
    _checkPermissionState () {
      window.webkit.messageHandlers["push-permission-state"].postMessage("push-permission-state")
      // Check and migrate previous iOS app settings
      window.webkit.messageHandlers["initial-notification-status"].postMessage("initial-notification-status");
    },
    _setPermissionState (event) {
      let eventName

      if (event.detail === "authorized") {
        eventName = 'notifications_allowed_clicked'
      } else if (event.detail === "denied") {
        eventName = 'notifications_dont_allow_clicked'
      }

      this.trackEvent('button_clicked', { button: eventName })

      this._state = event.detail
    },
    _pushDeviceToken () {
      if (!!this.getCookie('pushTokenFresh')) return

      this.$store.dispatch('pwa/pushDeviceToken').then(() => {
        this.trackEvent('push_token_updated', { device_token: this.deviceToken })
        this._setDeviceTokenFresh()
      })
    },
    _removeDeviceToken () {
      this.$store.dispatch('pwa/removeDeviceToken')
    },
    _setDeviceTokenFresh () {
      this.setCookie('pushTokenFresh', true, 1)
    }
  },
  mounted () {
    window.addEventListener("push-permission-state", this._setPermissionState);
    window.addEventListener("notification-status", this._setPermissionState);
  },
  beforeUnmount() {
    window.removeEventListener("push-permission-state", this._setPermissionState);
    window.removeEventListener("notification-status", this._setPermissionState);
  }
}