<template>
  <div class="profile-achievements-ph">
    <div class="profile-achievements-ph__info">
      <p class="profile-achievements-ph__item profile-achievements-ph__info-line-1"></p>
      <p class="profile-achievements-ph__item profile-achievements-ph__info-line-2"></p>
    </div>

    <div class="profile-achievements-ph__item profile-achievements-ph__nest"></div>
    <div class="profile-achievements-ph__item profile-achievements-ph__nest"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProfileAchievementsPlaceholder',
  computed: {
    ...mapState('lesson', {
      lesson: state => state.lesson
    })
  }
}
</script>

<style lang="scss" scoped>
  .profile-achievements-ph {
    &__item {
      position: relative;
      border-radius: 5px;
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;

      &--hidden {
        animation: none;
      }
    }

    &__info {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 24px 0;

      @include tablet-portrait {
        margin: 0 0 32px 0;
      }
      @include iphone-11 (landscape) {
        margin: 0 0 24px 0;
      }
      @include iphone-11-pro (landscape) {
        margin: 0 0 24px 0;
      }
      @include iphone-11-pro-max (landscape) {
        margin: 0 0 24px 0;
      }
      @include iphone-12 (landscape) {
        margin: 0 0 24px 0;
      }
      @include iphone-12-pro-max (landscape) {
        margin: 0 0 24px 0;
      }
    }

    &__info-line-1 {
      height: 25px;
      width: 240px;
      margin: 0 0 4px 0;
    }

    &__info-line-2 {
      height: 25px;
      width: 280px;
      margin: 0;
    }

    &__nest {
      height: 250px;
      margin: 0 0 30px 0;
      border-radius: 8px;
    }
  }
</style>
