<template>
  <div
    class="sidebar-item"
    :class="{
      'sidebar-item--active': isActive,
      'sidebar-item--locked': isLocked
    }"
    @click="goToLesson"
    v-click-outside="hideContextMenu"
  >
    <div class="sidebar-item__inner" ref="lessonItem">
      <div class="sidebar-item__thumbnail-nest" :class="{'sidebar-item__thumbnail-nest--active': isActive, 'sidebar-item__thumbnail-nest--loading': !imgLoaded}">
        <img :src="thumbnail" alt="img" class="sidebar-item__thumbnail" @load="imgLoaded = true" v-show="imgLoaded">
        <div class="sidebar-item__locked" v-if="isLocked">
          <font-awesome-icon :icon="['fas', 'lock']"/>
        </div>
        <template v-else>
          <app-progress :progress="progress || 0" :watched="item.is_watched" class="sidebar-item__progress"></app-progress>
          <div class="sidebar-item__time" v-if="item.duration">{{ convertSecondsToTime(item.duration) }}</div>
          <div class="sidebar-item__active-icon" v-if="isActive">
            <font-awesome-icon :icon="['fas', 'play']"/>
          </div>
        </template>
      </div>

      <div class="sidebar-item__title" :class="{ 'sidebar-item__title--active': isActive }">{{ preventWidows(item.title) }}</div>

      <div class="sidebar-item__context-btn" :class="{'sidebar-item__context-btn--show': isContextMenu || isMobile}" @click="toggleContextMenu" v-if="!isLocked && !guest">
        <span class="sidebar-item__context-dot"></span>
        <span class="sidebar-item__context-dot"></span>
        <span class="sidebar-item__context-dot"></span>
      </div>

      <app-context-menu
        :show="isContextMenu"
        :items="contextItems"
        @markCompleted="markCompleted"
        @markIncomplete="markIncomplete"
        @cancel="hideContextMenu"
        @click.stop
        v-if="!guest"
      >
      </app-context-menu>
    </div>
  </div>
</template>

<script>
// CONSTANTS
const thumbnailWidth = 110
const thumbnailHeight = 60

import clickOutside from '@shared/directives/clickOutside.js'
import { mapState, mapGetters } from 'vuex'


export default {
  name: 'SidebarModuleItem',
  props: {
    module: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    loadedFromLesson: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      imgLoaded: false,
      isContextMenu: false,
      focusClass: false,
      delay: 0
    }
  },
  computed: {
    ...mapGetters('course', ['courseLocked']),
    ...mapState('layout', {
      sidebarNest: state => state.sidebarNest,
      isLandscape: state => state.isLandscape
    }),
    ...mapState('course', {
      course: state => state.course
    }),
    ...mapState('lesson', {
      activeLesson: state => state.lesson
    }),
    ...mapState('user', {
      guest: state => state.guest
    }),
    isActive () {
      return parseInt(this.$route.params.id) === this.item.id
    },
    isWatched () {
      return this.item.progress > 0
    },
    isLocked () {
      return this.courseLocked && !this.item.video_id
    },
    isScrollSidebar () {
      return (this.isActive && !this.isPhone)
    },
    isCourseICALocked() {
      return this.courseLocked && this.course.id === 15
    },
    progress () {
      const num = this.item.is_video ? this.item.progress / this.item.duration : this.item.progress
      return +(num).toFixed(2)
    },
    thumbnail () {
      return `${this.item.thumbnail}?image_crop_resized=${this.thumbnailSize}`
    },
    thumbnailSize () {
      return `${Math.round(thumbnailWidth * this.pixelRatio)}x${Math.round(thumbnailHeight * this.pixelRatio)}`
    },
    contextItems () {
      return [
        {
          ...(!this.item.is_watched ? {id: 0, title: this.$t('sidebar.markAsCompleted'), event: 'markCompleted'} : {}),
          ...(this.item.is_watched ? {id: 1, title: this.$t('sidebar.markAsIncomplete'), event: 'markIncomplete'} : {})
        }
      ]
    },
    eventNamePrefixForTracker () {
      return this.loadedFromLesson ? 'lesson' : 'course'
    }
  },
  watch: {
    '$route.params.id': {
      handler () {
        if (this.isScrollSidebar && this.$route.name === 'lesson') {
          setTimeout(() => {
            const lessonItem = this.$refs.lessonItem

            if (lessonItem) {
              const position = lessonItem.offsetTop
              const offset = lessonItem.clientHeight / 2

              this.sidebarNest.scroll({
                top: position - offset,
                behavior: 'smooth'
              })
            }
          }, 200)
        }
      }
    }
  },
  directives: {
    'click-outside': clickOutside
  },
  methods: {
    markCompleted () {
      if (this.isActive) {
        this.$store.dispatch('lesson/setProgress', {
          lessonId: this.item.id,
          lessonProgress: this.item.is_video ? this.item.duration : 1,
          moduleId: this.module.id
        })
        if (!this.item.is_video) {
          this.item.progress = 1
        }
      } else {
        this.$store.dispatch('course/markLessonCompleted', {
          lessonId: this.item.id,
          moduleId: this.module.id
        })
      }
      this.hideContextMenu()

      this.trackEvent('button_clicked', {
        button: `${this.eventNamePrefixForTracker}_view_set_lesson_completed`,
        lesson_id: this.item.id,
        module_id: this.module.id
      })
    },
    markIncomplete () {
      if (this.isActive) {
        this.$store.dispatch('lesson/setProgress', {
          lessonId: this.item.id,
          lessonProgress: 0,
          moduleId: this.module.id
        })
        if (!this.item.is_video) {
          this.item.progress = 0
        }
      } else {
        this.$store.dispatch('course/markLessonIncomplete', {
          lessonId: this.item.id,
          moduleId: this.module.id
        })
      }
      this.hideContextMenu()

      this.trackEvent('button_clicked', {
        button: `${this.eventNamePrefixForTracker}_view_set_lesson_incomplete`,
        lesson_id: this.item.id,
        module_id: this.module.id
      })
    },
    hideContextMenu () {
      this.isContextMenu = false
    },
    toggleContextMenu () {
      this.isContextMenu = !this.isContextMenu
    },
    preloadImage () {
      const image = new Image()
      image.src = this.item.thumbnail
    },
    setSidebarPosition () {
      if (this.isLocked) {
        this.$nextTick(() => {
          this.sidebarNest.scrollTop = 0
        })
      } else {
        const lessonItem = this.$refs.lessonItem

        if (lessonItem) {
          const position = lessonItem.offsetTop
          const offset = lessonItem.clientHeight / 2

          if (this.isScrollSidebar) {
            this.$nextTick(() => {
              this.sidebarNest.scrollTop = position - offset
            })
          }
        }
      }
    },
    goToLesson (e) {
      const isContextBtnClicked = Object.values(e.target.classList).includes('sidebar-item__context-dot')
        || Object.values(e.target.classList).includes('sidebar-item__context-btn')

      if (isContextBtnClicked) {
        return
      }
      if (this.isICALocked) {
        window.open(this.handleSalesPageLinkParams(this.course.locked_url), '_blank').focus();
        return;
      }

      if (!this.guest) {
        if (this.activeLesson?.is_video && !this.activeLesson?.video_url) {
          this.trackEvent('button_clicked', {
            button: 'locked_lesson_view_free_video',
            lesson_name: this.activeLesson.title,
            lesson_id: this.activeLesson.id,
            course_slug: this.course.product_slug
          })
        }

        this.trackEvent('button_clicked', {
          button: `${this.eventNamePrefixForTracker}_view_choose_lesson`,
          course_id: this.course.id,
          course_slug: this.course.product_slug,
          course_name: this.course.title,
          module_id: this.module.id,
          module_name: this.module.title,
          lesson_id: this.item.id,
          lesson_name: this.item.title,
        })
      }

      this.$router.push({ name: this.guest ? 'share' : 'lesson', params: { id: this.item.id }})
    }
  },
  mounted () {
    // If this lesson is active:
    // scrolls modules sidebar on page load
    // so that current active lesson is in viewport
    this.preloadImage()
    this.setSidebarPosition()
    this.afterOrientationChange(this.setSidebarPosition)
  }
}
</script>

<style lang="scss" scoped>
  .sidebar-item {
    &--active {
      .sidebar-item__inner {
        background-color: var(--gray-6);
      }
    }

    &--locked {
      .sidebar-item__thumbnail-nest,
      .sidebar-item__title {
        opacity: 0.5;
      }
    }

    &:last-child {
      .sidebar-item__inner {
        border-radius: 0 0 5px 5px;
        &:after {
          display: none;
        }
      }
    }

    &__active-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 22px;
      width: 16px;
      color: var(--gray-1);
      opacity: 0.8;
      transform: translate(-50%, -50%);
      z-index: 5;

      @include tablet-landscape {
        font-size: 20px;
      }
    }

    &__inner {
      display: flex;
      align-items: center;
      position: relative;
      padding: 15px 2px 16px 10px;
      transition: background-color .1s;
      cursor: pointer;

      @include tablet-landscape {
        margin: 0 auto;
        max-width: 900px;
        padding: 15px 9px 15px 18px;
      }

      @include desktop-xs {
        &:hover {
          background: var(--gray-6);

          .sidebar-item__context-btn {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &:active {
        background-color: var(--gray-5);
      }
    }

    &__title {
      font-size: $text-sm;

      &--active {
        color: var(--white);
      }
    }

    &__thumbnail-nest {
      position: relative;
      min-width: 110px;
      max-width: 110px;
      height: 60px;
      margin: 0 16px 0 0;
      border-radius: 4px;
      overflow: hidden;

      &--active {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(var(--black-rgb),0.6);
        }
      }

      &--loading {
        background: var(--gray-5);
        animation: shimmer 1s ease-in-out infinite;
        animation-direction: alternate;
      }
    }

    &__locked {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      font-size: 30px;
      color: var(--white);
      background: rgba(var(--black-rgb), 0.6);
    }

    &__thumbnail {
      width: 100%;
      height: 100%;
    }

    &__progress {
      z-index: 5;
    }

    &__time {
      position: absolute;
      right: 0;
      bottom: 5px;
      font-size: $text-xs;
      padding: 0 2px;
      background: rgba(0,0,0,0.7);
      z-index: 5;
    }

    &__context-btn {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-self: stretch;
      position: relative;
      min-width: 29px;
      margin-left: auto;
      opacity: 0;
      visibility: hidden;
      cursor: pointer;

      &--show {
        opacity: 1;
        visibility: visible;
      }
    }
    &__context-dot {
      display: block;
      height: 3px;
      width: 3px;
      margin: 3px auto 0 auto;
      border-radius: 50%;
      background: var(--gray-3);
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }
    }
  }
</style>
