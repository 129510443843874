<template>
  <div class="lesson-teaser" :style="{'background-image': `url(${lesson.thumbnail})`}" v-if="course">
    <div class="lesson-teaser__top">
      <router-link :to="{ name: 'home' }" class="lesson-teaser__back" v-if="!guest" @click="fireTrackerEvent('locked_lesson_view_closed')">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
        {{ $t('lesson.backToMyCourses') }}
      </router-link>
    </div>

    <div class="lesson-teaser__nest">
      <div class="lesson-teaser__locked">
        <h3 class="lesson-teaser__title" v-if="isPhone">{{ $t('lesson.youDontHaveAccessTo') }}<br> {{ preventWidows(course.title) }}</h3>
        <template v-else>
          <font-awesome-icon class="lesson-teaser__icon" :icon="['fas', 'lock']" />
          <h3 class="lesson-teaser__title">{{ $t('lesson.youDontHaveAccessToThisVideo') }}</h3>
          <p class="lesson-teaser__description">{{ $t('lesson.clickToGainAccess') }} {{ preventWidows(course.title) }} {{ $t('lesson.videos') }}</p>
        </template>
        <app-button color="white" large class="lesson-teaser__btn" @click="linkAction">
          {{ $t('lesson.getInstantAccess') }}
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </app-button>
      </div>
    </div>
  </div>
  <div class="lesson-teaser__ph" v-else></div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('courses', ['courseOnSale']),
    ...mapState('lesson', {
      lesson: state => state.lesson,
      module: state => state.module,
    }),
    ...mapState('course', {
      course: state => state.course
    }),
    ...mapState('user', {
      guest: state => state.guest
    })
  },
  methods: {
    linkAction() {
      this.fireTrackerEvent('locked_lesson_view_instant_access')
      window.open(this.handleSalesPageLinkParams(this.lesson.locked_url, this.courseOnSale?.ios_locked_params), '_blank')
    },
    fireTrackerEvent(name) {
      this.trackEvent('button_clicked', {
        button: name,
        course_id: this.course.id,
        course_slug: this.course.product_slug,
        course_name: this.course.title,
        module_id: this.module.id,
        module_name: this.module.title,
        lesson_id: this.lesson.id,
        lesson_name: this.lesson.title,
      })
    }
  }
}
</script>

<style lang="scss">
  .lesson-teaser {
    position: relative;
    border-radius: 8px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    &__back {
      position: absolute;
      top: 12px;
      left: 16px;
      font-size: 14px;
      z-index: 5;

      @include tablet-portrait {
        top: 28px;
        left: 28px;
        font-size: $text-md;
      }
      @include tablet-landscape {
        top: 12px;
        left: 16px;
        font-size: 14px;
      }
      @include desktop-md {
        top: 28px;
        left: 28px;
        font-size: $text-md;
      }
    }

    &__nest {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      background: rgba(var(--black-rgb), 0.8);
      backdrop-filter: blur(8px);

      @include tablet-landscape {
        border-radius: 4px;
      }
    }

    &__ph {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;

      @include tablet-landscape {
        border-radius: 4px;
      }
    }

    &__locked {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      padding: 16px 16px 32px 16px;

      @include tablet-portrait {
        display: block;
        left: 50%;
        top: 50%;
        width: 80%;
        height: auto;
        padding: 30px 20px;
        transform: translate(-50%, -50%);
      }
      @include desktop-md {
        padding: 80px 20px;
      }
    }

    &__icon {
      font-size: 50px;
      margin: 0 0 16px 0;

      @include desktop-md {
        font-size: 63px;
      }
    }

    &__title {
      font-size: 20px;
      font-weight: $semiBold;
      margin: 0 0 32px 0;

      @include tablet-portrait {
        font-size: 28px;
        margin: 0 0 16px 0;
      }
      @include tablet-landscape {
        font-size: 26px;
      }
      @include desktop-md {
        font-size: 32px;
      }
    }

    &__description {
      font-size: 18px;
      margin: 0 0 38px 0;

      @include desktop-md {
        font-size: 20px;
      }
    }

    &__btn {
      width: 100%;
      line-height: 40px !important;

      @include tablet-portrait {
        width: 344px;
        max-width: 100%;
        line-height: 48px !important;
      }
    }
  }
</style>
