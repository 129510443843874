import appClient from '@shared/api/appClient'

export default {
  namespaced: true,
  namespace: 'course',
  state: {
    course: null
  },
  getters: {
    courseId: (state) => {
      return state.course?.id
    },
    courseLocked: (state, getters, rootState) => {
      return state.course ? state.course.locked : rootState.lesson.lesson?.course?.locked
    },
    singleLesson: (state) => (id) => {
      let singleLesson

      state.course.modules.forEach(module => {
        return module.lessons.find(lesson => {
          if (lesson.id === id) {
            singleLesson = lesson
          }
        })
      })

      return singleLesson
    },
    singleModule: (state) => (id) => {
      return state.course.modules.find(item => {
        return item.id === id
      })
    }
  },
  actions: {
    setCourse ({ commit, dispatch, state }, id) {
      if (state.course && state.course.id === id) {
        dispatch('layout/setHeaderTitle', state.course.title, {root: true})
        return
      }

      commit('SET_COURSE', null)

      return appClient.getCourse(id).then(({data}) => {
        if (data) {
          dispatch('layout/setHeaderTitle', data.title, {root: true})
          commit('SET_COURSE', data)
        }
      })
    },
    setSharedCourse ({ commit, dispatch, state }, id) {
      if (state.course && state.course.id === id) {
        dispatch('layout/setHeaderTitle', state.course.title, {root: true})
        return
      }

      commit('SET_COURSE', null)

      return appClient.getSharedCourse(id).then(({data}) => {
        if (data) {
          dispatch('layout/setHeaderTitle', data.title, {root: true})
          commit('SET_COURSE', data)
        }
      })
    },
    resetCourse ({ commit, dispatch }) {
      dispatch('layout/setHeaderTitle', null, {root: true})
      commit('RESET_COURSE')
    },
    markLessonCompleted({ state, commit, dispatch, getters }, payload) {
      const lesson = getters.singleLesson(payload.lessonId)
      const module = getters.singleModule(payload.moduleId)

      appClient.setProgress(payload.lessonId, lesson.duration).then(({data}) => {
        commit('MARK_LESSON_COMPLETED', {
          lesson: lesson,
          module: module,
          moduleProgress: data.module_percent_completed,
          courseProgress: data.course_percent_completed
        })

        // Update course progress
        dispatch('courses/updateProgress', {
          courseId: state.course.id,
          courseProgress: data.course_percent_completed
        }, { root: true })

        // Update course current lesson
        dispatch('courses/updateCurrentLesson', {
          courseId: state.course.id,
          currentLessonId: data.current_lesson_id
        }, { root: true })
      })
    },
    markLessonIncomplete({ state, commit, dispatch, getters }, payload) {
      const lesson = getters.singleLesson(payload.lessonId)
      const module = getters.singleModule(payload.moduleId)

      appClient.setProgress(payload.lessonId, 0).then(({data}) => {
        commit('MARK_LESSON_INCOMPLETE', {
          lesson: lesson,
          module: module,
          moduleProgress: data.module_percent_completed,
          courseProgress: data.course_percent_completed
        })

        // Update course progress
        dispatch('courses/updateProgress', {
          courseId: state.course.id,
          courseProgress: data.course_percent_completed
        }, { root: true })

        // Update course current lesson
        dispatch('courses/updateCurrentLesson', {
          courseId: state.course.id,
          currentLessonId: data.current_lesson_id
        }, { root: true })
      })
    },
    updateProgress ({ commit, getters }, payload) {
      commit('UPDATE_PROGRESS', {
        module: getters.singleModule(payload.moduleId),
        moduleProgress: payload.moduleProgress,
        courseProgress: payload.courseProgress
      })
    }
  },
  mutations: {
    SET_COURSE (state, value) {
      state.course = value
    },
    RESET_COURSE (state)  {
      state.course = null
    },
    MARK_LESSON_COMPLETED (state, payload) {
      payload.lesson.is_watched = true
      payload.lesson.progress = payload.lesson.duration
      payload.module.percent_completed = payload.moduleProgress
      state.course.percent_completed = payload.courseProgress
    },
    MARK_LESSON_INCOMPLETE (state, payload) {
      payload.lesson.is_watched = false
      payload.lesson.progress = null
      payload.module.percent_completed = payload.moduleProgress
      state.course.percent_completed = payload.courseProgress
    },
    UPDATE_PROGRESS (state, { module, moduleProgress, courseProgress }) {
      module.percent_completed = moduleProgress
      state.course.percent_completed = courseProgress
    }
  }
}
