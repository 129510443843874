<template>
  <component :is="notification.type" :notification="notification"></component>
</template>

<script>
import achievement_unlocked from './AppNotifications/achievement_unlocked.vue'
import badge_unlocked from './AppNotifications/badge_unlocked.vue'
import comment_reply from './AppNotifications/comment_reply.vue'
import new_lesson from './AppNotifications/new_lesson.vue'
import notification from './AppNotifications/notification.vue'
import success from './AppNotifications/success.vue'
import warning from './AppNotifications/warning.vue'
import error from './AppNotifications/error.vue'

export default {
  name: 'AppToast',
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  components: {
    achievement_unlocked,
    badge_unlocked,
    comment_reply,
    new_lesson,
    notification,
    success,
    warning,
    error
  }
}
</script>

<style lang="scss">
  .toast {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 10px 13px 10px;
    background: rgba(var(--gray-5-rgb), 0.8);
    backdrop-filter: blur(20px);

    @supports not (backdrop-filter: blur(20px)) {
      background: rgba(var(--gray-5-rgb), 0.95);
    }

    @include tablet-portrait {
      padding: 15px 30px 18px 10px;
    }

    &__body {
      flex: 2;
      font-size: $text-sm;

      @include phone-portrait {
        font-size: $text-md;
      }
    }

    &__title {
      color: var(--gray-3);
      margin: 0;
    }

    &__description {
      margin: 0;
    }

    &__close {
      display: none;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      font-size: 24px;
      text-align: center;
      padding: 0 10px 3px 10px;
      opacity: 0.3;
      cursor: pointer;
      
      // Targets devices that allow accurate pointing
      // (desktop in this case)
      @media (min-width: $breakpoint-desktop-xs) and (pointer: fine) {
        display: flex;
      }
      
      &:hover {
        opacity: 1;
      }
    }
  }
</style>