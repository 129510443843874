<template>
  <button class="button" :class="[`button--${color}`, {'button--large': large}, {'button--outlined': outlined}]" v-bind="$attrs">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    color: {
      type: String,
      required: false,
      default: 'white'
    },
    large: {
      type: Boolean,
      required: false
    },
    outlined: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font: inherit;
    font-size: $text-sm;
    line-height: 40px;
    font-weight: $semiBold;
    color: var(--gray-4);
    line-height: 40px;
    padding: 0 10px;
    border-radius: 6px;
    border: none;
    outline: none;
    transition: background-color .1s;
    cursor: pointer;

    @include phone-portrait {
      line-height: 42px;
      padding: 0 14px;
    }
    @include tablet-portrait {
      font-size: $text-md;
    }

    &--large {
      font-size: $text-sm;
      line-height: 40px;

      @include phone-portrait {
        font-size: $text-md;
        line-height: 48px;
      }
      @include tablet-portrait {
        font-size: $text-lg;
        line-height: 50px;
      }
    }

    &--white {
      color: var(--black);
      background: var(--white);

      &:hover {
        background-color: var(--gray-1);
      }
      &.button--outlined {
        color: var(--gray-1);
        background: var(--black);
        border: 1px solid var(--gray-1);
        &:hover {
          border: 1px solid var(--white);
          color: var(--white);
          background: var(--gray-7);
        }
      }
    }

    &--green {
      color: var(--white);
      background: var(--green);
    }

    &--red {
      color: var(--white);
      background: var(--red);
    }

    &--gray-4 {
      color: var(--white);
      background: var(--gray-5);

      &:hover {
        background-color: var(--gray-4);
      }
    }

    &--gray-5 {
      color: var(--white);
      background: var(--gray-6);

      &:hover {
        background-color: var(--gray-5);
      }
    }

    &--gray-6 {
      color: var(--white);
      background: var(--gray-7);

      &:hover {
        background-color: var(--gray-6);
      }
    }
    &--gray-8 {
      color: var(--white);
      background: var(--gray-8);

      &:hover {
        background-color: var(--gray-7);
      }
    }
    &--primary {
        color: var(--white);
        background: linear-gradient(135deg, #D41C3D 0%, #861B83 100%);
    }
    &--white-no-hover {
      color: var(--black);
      background: var(--white);
    }
    &--orange {
      color: var(--black);
      background: linear-gradient(90deg, #F4B76B 15.45%, #F2A056 48.25%, #F09249 68.31%);
    }
  }
</style>
