<template>
  <div class="annotations-nest"
    :class="{ 'video-controls-visible': videoControlsVisible && isPhone, 'back-button-visible': previousTimestamp }">
    <div class="annotations" :class="{ 'annotations--ipad-fullscreen': isIPadFullscreen }">
      <template v-if="lesson.annotations">
        <transition-group name="slide-in">
          <annotation v-for="annotation in annotationCurrent" :annotation="annotation" :key="annotation.id" />
        </transition-group>
      </template>
    </div>
  </div>
</template>

<script>
import Annotation from './Annotation.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Annotation
  },
  props: {
    idle: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fullscreen: false
    }
  },
  computed: {
    ...mapState('layout', {
      videoControlsVisible: state => state.videoControlsVisible
    }),
    ...mapState('lesson', {
      lesson: state => state.lesson,
      annotationActive: state => state.annotationActive,
      previousTimestamp: state => state.previousTimestamp,
    }),
    annotationCurrent() {
      return this.lesson.annotations.filter(annotation => {
        return annotation.id === this.annotationActive
      })
    },
    isIPadFullscreen() {
      return this.fullscreen && this.isIPad
    }
  },
  methods: {
    checkFullscreen() {
      document.addEventListener('webkitfullscreenchange', () => {
        document.webkitFullscreenElement
          ? this.fullscreen = true
          : this.fullscreen = false
      })
    }
  },
  mounted() {
    this.checkFullscreen()
  }
}
</script>

<style lang="scss" scoped>
.annotations-nest {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 10;
  transition: right 350ms ease;

  &.video-controls-visible {
    right: 100%;

    .annotations {
      right: -10px;
      transform: translateX(100%);
    }

    &.back-button-visible .annotations {
      right: -44px;
    }
  }
}

.annotations {
  position: absolute;
  top: 8px;
  right: 8px;
  transition: right 350ms ease, transform 350ms ease;

  @include tablet-portrait {
    top: 16px;
    right: 16px;
  }

  &--ipad-fullscreen {
    top: 24px;
    right: 24px;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    cursor: pointer;

    @include desktop-xs {
      &:hover {
        .annotations__tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:active {
      .annotations__tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    &--upcoming {
      line-height: 35px;
      font-weight: $medium;
      white-space: nowrap;
      padding: 0 12px;
      border-radius: 5px;
      background: rgba(var(--gray-8-rgb), 0.8);
    }

    img {
      max-width: none;
      margin: 0 0 0 10px;
    }
  }

  &__video-ended {
    display: flex;
    align-items: center;
    white-space: nowrap;

    p {
      margin: 0;
    }
  }

  &__tooltip {
    position: absolute;
    top: 100%;
    right: 0;
    font-size: 12px;
    line-height: 26px;
    white-space: nowrap;
    padding: 0 8px;
    border-radius: 4px;
    background: rgba(var(--gray-8-rgb), 0.9);
    transition: all .2s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: all .2s ease-in-out;
}

.slide-in-enter-from,
.slide-in-leave-active {
  opacity: 0;
  transform: translateX(8px);

  @include tablet-portrait {
    transform: translateX(16px);
  }
}
</style>