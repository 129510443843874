<template>
  <div class="course-intro-ph">
    <div class="course-intro-ph__top">
      <!-- <div class="course-intro-ph__item course-intro-ph__title"></div>

      <div class="course-intro-ph__description">
        <div class="course-intro-ph__item"></div>
        <div class="course-intro-ph__item"></div>
        <div class="course-intro-ph__item"></div>
        <div class="course-intro-ph__item"></div>
        <div class="course-intro-ph__item"></div>
        <div class="course-intro-ph__item"></div>
      </div> -->

      <div class="course-intro-ph__item course-intro-ph__button"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseIntroPlaceholder'
}
</script>

<style lang="scss" scoped>
  .course-intro-ph {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    height: 100%;
    padding: 0 10px 25px 10px;

    &__item {
      position: relative;
      border-radius: 2px;
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;
    }

    &__top {
      position: relative;
      width: 100%;

      @include phone-landscape (landscape) {
        max-width: 80%;
        margin: 0 auto;
      }
      @include tablet-portrait (portrait) {
        max-width: 80%;
        margin: 0 auto;
      }
      @include tablet-landscape (landscape) {
        max-width: 70%;
        margin: 0 auto;
      }
      @include desktop-xs {
        width: 900px;
        margin: 0 auto;
      }
    }

    &__button {
      display: block;
      width: 100%;
      height: 40px;
      border-radius: 6px;
      margin-top: 20px;
      background: var(--white);

      @include phone-portrait {
        height: 48px;
      }
      @include tablet-portrait {
        height: 50px;
      }
    }

    &__title {
      height: 32px;
      width: 95%;
      margin: 0 0 13px 0;
    }

    &__description {
      >* {
        height: 20px;
        margin: 0 0 2px 0;
        &:nth-child(1) {
          width: 95%;
        }
        &:nth-child(3) {
          width: 90%;
        }
        &:nth-child(4) {
          width: 85%;
        }
        &:last-child {
          width: 30%;
          margin: 0;
        }
      }
    }
  }
</style>