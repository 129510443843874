<template>
  <div class="quick-tips-item" :class="{'quick-tips-item--with-banner': banner, 'swipe': shouldShowSwipeIndicator}" ref="quickTipsItem" @click="handleItemClick">
    <div class="quick-tips-item__nest">
      <quick-tips-video :video="video" :index="index" />

      <div class="quick-tips-item__buttons" v-if="activeVideo">
        <div class="quick-tips-item__button quick-tips-item__button--like" @click="like">
          <font-awesome-icon :icon="['far', 'heart']" class="quick-tips-item__button-icon quick-tips-item__button-icon--like" v-if="!video.is_liked" />
          <font-awesome-icon :icon="['fa', 'heart']" class="quick-tips-item__button-icon quick-tips-item__button-icon--like quick-tips-item__button-icon--liked" v-else />
          <p class="quick-tips-item__button-text">{{ compactNumberFormatter(video.likes_count, {formatBreakpoint: 10_000}) }}</p>
        </div>

        <div class="quick-tips-item__button quick-tips-item__button--comments" @click.stop="openComments">
          <font-awesome-icon :icon="['far', 'comment']" class="quick-tips-item__button-icon quick-tips-item__button-icon--comments" />
          <p class="quick-tips-item__button-text">{{ compactNumberFormatter(video.comments_count, {formatBreakpoint: 10_000}) }}</p>
        </div>

        <div class="quick-tips-item__button" @click.stop="openShare">
          <font-awesome-icon :icon="['far', 'share']" class="quick-tips-item__button-icon quick-tips-item__button-icon--share" />
        </div>

        <router-link @click.stop="trackAllTipsButtonClick" :to="{ name: 'quick-tips-all' }" class="quick-tips-item__button">
          <font-awesome-icon :icon="['far', 'grid']" class="quick-tips-item__button-icon quick-tips-item__button-icon--grid" />
        </router-link>

        <div class="quick-tips-item__button" @click.stop="toggleMuted">
          <font-awesome-icon :icon="['far', 'volume']" class="quick-tips-item__button-icon quick-tips-item__button-icon--mute" v-if="!muted" />
          <font-awesome-icon :icon="['far', 'volume-slash']" class="quick-tips-item__button-icon quick-tips-item__button-icon--unmute" v-else  />
        </div>
      </div>

      <div class="quick-tips-item__indicator" v-if="index === 0 && shouldShowSwipeIndicator">
        <span class="quick-tips-item__swipe-line" />
        <img class="quick-tips-item__swipe" src="https://images.iphonephotographyschool.com/33062/point-hand.svg" alt="svg" >
        <p class="quick-tips-item__text" v-html="$t('quickTips.swipeUp')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import QuickTipsVideo from './QuickTipsVideo.vue'
import { mapState } from 'vuex'

export default {
  components: {
    QuickTipsVideo
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    video: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('quickTips', {
      activeVideo: state => state.video,
      showSwipeIndicator: state => state.showSwipeIndicator,
      swipeIndicatorSeen: state => state.swipeIndicatorSeen,
      commentsOpened: state => state.commentsOpened,
      shareOpened: state => state.shareOpened,
      muted: state => state.muted
    }),
    ...mapState('user', ['guest']),
    ...mapState('banner', {
      banner: state => state.banner
    }),
    // only swipe up the first and second video - part of the animation
    isSwipeElement() {
      return [0,1].includes(this.index)
    },
    shouldShowSwipeIndicator() {
      return this.isPhone && this.showSwipeIndicator && !this.commentsOpened && !this.shareOpened && this.isSwipeElement
    }
  },
  methods: {
    like() {
      this.fireTrackerEvent('button_clicked', !this.video.is_liked ? 'quick_tips_view_like' : 'quick_tips_view_remove_like')

      if (this.guest) return this.$store.dispatch('popup/showLoginPopup')
      this.video.is_liked = !this.video.is_liked
      this.video.likes_count = this.video.is_liked ? this.video.likes_count + 1 : this.video.likes_count - 1
      this.$store.dispatch('quickTips/setTipLike', this.video.is_liked).catch(() => {
        this.video.is_liked = !this.video.is_liked
        this.video.likes_count = this.video.is_liked ? this.video.likes_count + 1 : this.video.likes_count - 1
      })
    },
    openComments() {
      this.$store.dispatch('quickTips/toggleVideoAutoplay', false)
      this.$store.dispatch('quickTips/toggleComments', true)
      this.$store.dispatch('quickTips/toggleShowSwipeIndicator', false)

      this.fireTrackerEvent('button_clicked', 'quick_tips_view_open_comments')
    },
    openShare() {
      this.$store.dispatch('quickTips/toggleVideoAutoplay', false)
      this.$store.dispatch('quickTips/toggleShare', true)
      this.$store.dispatch('quickTips/toggleShowSwipeIndicator', false)

      this.fireTrackerEvent('button_clicked', 'quick_tips_view_share')
    },
    handleItemClick() {
      if (!this.showSwipeIndicator) return
      this.$store.dispatch('quickTips/toggleShowSwipeIndicator', false);
    },
    trackAllTipsButtonClick() {
      const event = this.$router.currentRoute.value.params.id === this.video.id
        ? 'quick_tips_view_active_video_open_grid'
        : 'quick_tips_view_open_grid'

      this.fireTrackerEvent(event)
    },
    fireTrackerEvent(action, name) {
      this.trackEvent(action, {
        ...(name && { button: name }),
        quick_tip_id: this.video.id,
        quick_tip_title: this.video.title,
      })
    },
    toggleMuted() {
      this.$store.dispatch('quickTips/setMuted', !this.muted)
      this.trackEvent('button_clicked', {
        button: 'quick_tips_view_mute',
        quick_tip_id: this.video.id,
        quick_tip_title: this.video.title,
        is_muted: !this.muted
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-tips-item {
  scroll-snap-align: start;
  height: calc(var(--app-height) - #{$app-header-height});

  @include phone-landscape {
    height: calc(100vh - #{$app-header-height});
    padding: 24px 0 0 0;
    margin-bottom: -90px;
  }

  &--with-banner {
    height: calc(var(--app-height) - (#{$app-header-height + $app-banner-height}));

    @include phone-landscape {
      height: calc(100vh - (#{$app-header-height + $app-banner-height}));
    }
  }

  &__nest {
    position: relative;
    height: 100%;
    overflow: hidden;

    @include phone-landscape {
      aspect-ratio: 9 / 16;
      height: calc(100% - 90px);
      margin: 0 auto;
      overflow: visible;
    }
  }

  &__buttons {
    display: flex;
    flex-flow: column;
    gap: 24px;
    position: absolute;
    bottom: 32px;
    right: 4px;

    @include phone-landscape {
      right: auto;
      left: calc(100% + 8px);
    }
  }

  &__button {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 52px;
    color: var(--white);
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;

    &--like, &--comments {
      margin-bottom: -10px;
    }
  }

  &__button-icon {
    font-size: 28px;

    &--liked {
      color: var(--red);
      animation: beat .4s ease-out;
    }
    &--mute, &--unmute {
      font-size: 24px;
    }
    &--unmute {
      margin-left: 3px;
    }
  }

  &__button-text {
    font-size: 11px;
    line-height: 12px;
    font-weight: $semiBold;
    margin: 0;
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transition: all .1s ease-in-out;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
    animation:
      fade-in 1s ease-in-out forwards,
      fade-out 1s ease-in-out 7s forwards;
  }

  &.swipe {
    animation:
      video-swipe 1.2s linear 2.2s,
      video-swipe 1.2s linear 4.9s;
  }

  &__swipe {
    pointer-events: none;
    width: 53px;
    opacity: 0;
    transform: translateX(56px) translateY(69px) rotateZ(-100.47deg);
    animation:
      fade-in 1s ease-out 1s forwards,
      swipe-up 1s linear 2s forwards,
      fade-out 0.6s linear 3s forwards,
      fade-in 0.6s linear 4.1s forwards,
      swipe-down 0.6s linear 4.1s forwards,
      swipe-up 1s linear 4.7s forwards,
      fade-out 0.6s linear 5.7s forwards;

    &-line {
      width: 9px;
      height: 67px;
      position: absolute;
      bottom: 50%;
      border-radius: 8px;
      opacity: 0;
      background: linear-gradient(180deg, #FFF 0%, transparent 100%);
      animation:
        fade-in 0.8s linear 2.2s forwards,
        line-expand-up 0.8s linear 2.2s forwards,
        fade-out 0.6s linear 3s forwards,
        fade-in 0.8s linear 4.9s forwards,
        line-expand-up 0.8s linear 4.9s forwards,
        fade-out 0.6s linear 5.7s forwards;
    }
  }

  &__text {
    position: absolute;
    pointer-events: none;
    bottom: 20%;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes swipe-up {
    0% {
      transform: translateX(56px) translateY(69px) rotateZ(-100.47deg)
    }
    20% {
      transform: translateX(50px) translateY(30px) rotateZ(-90deg)
    }
    100% {
      transform: translateX(49px) translateY(-122px) rotateZ(-25.73deg)
    }
  }

  @keyframes swipe-down {
    0% {
      transform: translateX(49px) translateY(-122px) rotateZ(-25.73deg)
    }
    80% {
      transform: translateX(50px) translateY(30px) rotateZ(-90deg)
    }
    100% {
      transform: translateX(56px) translateY(69px) rotateZ(-100.47deg)
    }
  }

  @keyframes line-expand-up {
    0% {
      height: 67px;
    }
    100% {
      height: 155px;
      transform: translateY(-4px);
    }
  }

  @keyframes video-swipe {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-88px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(88px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes beat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
