import appClient from '@shared/api/appClient'
import appLibrary from '@shared/base/appLibrary'

export default {
  namespaced: true,
  namespace: 'pwa',
  state: {
    isLoading: false,
    deviceToken: null,
    appNotificationsAllowed: false,
    isIosUrlParams: false
  },
  getters: {
    appNotificationsAllowed: (state) => {
      return state.appNotificationsAllowed
    }
  },
  actions: {
    setLoading({ commit }, status) {
      commit('SET_LOADING', status);
    },
    setDeviceToken({ commit }, token) {
      commit('SET_DEVICE_TOKEN', token);
    },
    pushDeviceToken({ state }) {
      if (!state.deviceToken) return;
      return appClient.addDeviceToken(state.deviceToken)
    },
    removeDeviceToken({ state }) {
      if (!state.deviceToken) return;

      return appClient.removeDeviceToken(state.deviceToken).then(() => {
        appLibrary.methods.deleteCookie('pushTokenFresh')
      })
    },
    setAppNotificationsAllowed({ commit }, allowed) {
      window.localStorage.setItem('appNotifications', allowed);
      commit('SET_APP_NOTIFICATIONS_ALLOWED', allowed);
    },
    setIsIosUrlParams({ commit }) {
      commit('SET_IS_IOS_URL_PARAMS')
    }
  },
  mutations: {
    SET_LOADING (state, status) {
      state.isLoading = status;
    },
    SET_DEVICE_TOKEN (state, token) {
      state.deviceToken = token;
    },
    SET_APP_NOTIFICATIONS_ALLOWED (state, allowed) {
      state.appNotificationsAllowed = allowed;
    },
    SET_IS_IOS_URL_PARAMS (state) {
      state.isIosUrlParams = true;
    }
  },
}