<template>
  <div class="profile-ph">
    <div class="profile-ph__item profile-ph__avatar"></div>
    
    <div class="profile-ph__item profile-ph__user-name"></div>

    <div class="profile-ph__item profile-ph__badge"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProfilePlaceholder',
  computed: {
    ...mapState('lesson', {
      lesson: state => state.lesson
    })
  }
}
</script>

<style lang="scss" scoped>
  .profile-ph {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 48px;

    @include tablet-portrait {
      padding-top: 0;
    }
    
    &__item {
      position: relative;
      border-radius: 5px;
      background: var(--gray-6);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;

      &--hidden {
        animation: none;
      }
    }

    &__avatar {
      position: relative;
      height: 100px;
      width: 100px;
      margin-bottom: 13px;
      border-radius: 50%;

      @include tablet-portrait {
        height: 142px;
        width: 142px;
      }
      @include iphone-11 (landscape) {
        height: 100px;
        width: 100px;
      }
      @include iphone-11-pro (landscape) {
        height: 100px;
        width: 100px;
      }
      @include iphone-11-pro-max (landscape) {
        height: 100px;
        width: 100px;
      }
      @include iphone-12 (landscape) {
        height: 100px;
        width: 100px;
      }
      @include iphone-12-pro-max (landscape) {
        height: 100px;
        width: 100px;
      }
    }

    &__user-name {
      height: 33.8px;
      width: 240px;
    }

    &__badge {
      height: 28px;
      width: 110px;
      margin-top: 7px;
    }
  }
</style>
