<template>
  <div class="course-intro">
    <template v-if="course">
      <div class="course-intro__top">
        <h2 class="course-intro__title">{{ course.title }}</h2>
        <p class="course-intro__description">{{ descriptionShort }}</p>

        <router-link :to="{name: 'lesson', params: {id: course.current_lesson.id}}" tag="div" @click="handleStartWatching">
          <app-button class="course-intro__button" color="white" :large="true">
            <font-awesome-icon :icon="['fas', 'play']" class="course-intro__button-icon" />
            {{ buttonText }}
          </app-button>
        </router-link>
      </div>
    </template>

    <transition name="appear">
      <course-intro-placeholder v-if="!course"></course-intro-placeholder>
    </transition>
  </div>
</template>

<script>
import CourseIntroPlaceholder from './CourseIntroPlaceholder.vue'
import { mapState } from 'vuex'

export default {
  name: 'CourseIntro',
  components: {
    CourseIntroPlaceholder
  },
  computed: {
    ...mapState('course', {
      course: state => state.course
    }),
    isContinue () {
      return this.course.percent_completed && this.course.percent_completed !== 1
    },
    buttonText () {
      return this.course.locked ? this.$t('course.watchPreview') : this.isContinue ? this.$t('continueWatching') : this.$t('course.watchTheFirstVideo')
    },
    trackerEventName () {
      return this.course.locked ? 'course_view_preview' : this.isContinue ? 'course_view_continue_watching' : 'course_view_start_watching'
    },
    descriptionShort () {
      return this.course.description.split(/\r?\n|\r|\n/g)[0]
    }
  },
  methods: {
    handleStartWatching () {
      this.trackEvent('button_clicked', {
        button: this.trackerEventName,
        course_id: this.course.id,
        course_slug: this.course.product_slug,
        course_name: this.course.title,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .course-intro {
    display: flex;
    align-items: flex-end;
    height: 100%;
    padding: 0 10px 25px 10px;

    @supports (position: sticky) {
      position: sticky;
      top: $app-header-height;
    }

    &__top {
      position: relative;
      width: 100%;
      @include phone-landscape (landscape) {
        max-width: 80%;
        margin: 0 auto;
      }
      @include tablet-portrait (portrait) {
        max-width: 80%;
        margin: 0 auto;
      }
      @include tablet-landscape (landscape) {
        max-width: 70%;
        margin: 0 auto;
      }
      @include desktop-xs {
        width: 900px;
        margin: 0 auto;
      }
    }

    &__button {
      display: block;
      width: 100%;
      margin-top: 20px;
      border-radius: 6px !important;
    }

    &__title {
      font-size: $header-md;
      line-height: 100%;
      font-weight: 500;
      margin: 0 0 13px 0;

      @include phone-portrait {
        font-size: 32px;
      }
      @include phone-landscape {
        font-size: 32px;
      }
      @include iphone-5 (landscape) {
        font-size: $header-md;
      }
    }

    &__description {
      font-size: $text-sm;
      line-height: 1.4;
      margin: 0;

      @include phone-portrait {
        font-size: $text-md;
      }
      @include phone-landscape {
        font-size: $text-md;
      }
      @include iphone-5 (landscape) {
        font-size: $text-sm;
      }
    }

    &__button-icon {
      font-size: $text-md;
      margin: 0 5px 0 0;
    }
  }
</style>
