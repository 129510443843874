export default {
  data () {
    return {
      position: {
        target: null,
        class: null,
        initialMouseX: null,
        initialMouseY: null,
        posX: null,
        posY: null,
        mouseX: null,
        mouseY: null
      }
    }
  },
  methods: {
    mouseDown (e) {
      if (!this.floating) {
        return
      }

      this.position.target = this.$refs['videoWrap']
      this.position.posX = this.position.target.offsetLeft
      this.position.posY = this.position.target.offsetTop
      this.position.initialMouseX = this.isMobile ? e.touches[0].clientX : e.clientX
      this.position.initialMouseY = this.isMobile ? e.touches[0].clientY : e.clientY
      this.position.class = null

      this.move(e)
      
      document.addEventListener(this.isMobile ? 'touchmove' : 'mousemove', this.move, true)
      document.addEventListener(this.isMobile ? 'touchend' : 'mouseup', this.mouseUp, false)
    },
    mouseUp (e) {
      this.position.mouseX = e.pageX
      this.position.mouseY = e.pageY - window.scrollY

      this.checkScreenEnd()

      document.removeEventListener(this.isMobile ? 'touchmove' : 'mousemove', this.move, true)
      document.removeEventListener(this.isMobile ? 'touchend' : 'mouseup', this.mouseUp, false)
      
      this.position.target = null
    },
    move (e) {
      const dy = (this.isMobile ? e.touches[0].clientY : e.clientY) - this.position.initialMouseY
      const dx = (this.isMobile ? e.touches[0].clientX : e.clientX) - this.position.initialMouseX

      this.position.target.style.top = this.position.posY + dy + 'px'
      this.position.target.style.left = this.position.posX + dx + 'px'
    },
    checkScreenEnd () {
      const rightEnd = window.innerWidth / 2
      const bottomEnd = window.innerHeight / 2
      
      if (this.position.mouseX < rightEnd && this.position.mouseY < bottomEnd) {
        this.position.class = 'video__wrap--top-left'
      }

      if (this.position.mouseX > rightEnd && this.position.mouseY < bottomEnd) {
        this.position.class = 'video__wrap--top-right'
      }

      if (this.position.mouseX < rightEnd && this.position.mouseY > bottomEnd) {
        this.position.class = 'video__wrap--bottom-left'
      }
      
      if (this.position.mouseX > rightEnd && this.position.mouseY > bottomEnd) {
        this.position.class = 'video__wrap--bottom-right'
      }

      this.position.target.removeAttribute('style')
    }
  }
}