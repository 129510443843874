<template>
  <div class="share-video">
    <transition name="appear">
      <div class="share-video__bg" @click="hideShareVideo" v-if="showShareVideo"></div>
    </transition>

    <share-button :class="{'share-button--active': showShareVideo}" @click="toggleShareVideo" />

    <transition name="scale">
      <share-tooltip @click="toggleShareVideo" v-if="showTooltip" />
    </transition>

    <transition :name="isPhone ? 'slide' : 'scale'">
      <share-popup class="share-popup" @close="hideShareVideo" v-if="showShareVideo" />
    </transition>
  </div>
</template>

<script>
import ShareButton from './ShareButton.vue'
import SharePopup from './SharePopup.vue'
import ShareTooltip from './ShareTooltip.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    ShareButton,
    SharePopup,
    ShareTooltip
  },
  data () {
    return {
      showShareVideo: false,
      activeTooltip: false
    }
  },
  computed: {
    ...mapGetters('course', ['courseLocked']),
    ...mapState('user', {
      guest: state => state.guest
    }),
    ...mapState('settings', {
      settings: state => state.settings,
      guestSettings: state => state.guestSettings
    }),
    ...mapState('course', {
      course: state => state.course
    }),
    ...mapState('lesson', {
      lesson: state => state.lesson
    }),
    showTooltip () {
      return !this.guest && this.settings && !this.settings.hide_share_feature_tooltip && this.activeTooltip && !this.courseLocked
    }
  },
  methods: {
    toggleShareVideo () {
      this.guest
        ? this.$store.dispatch('settings/updateGuestSettings', { key: 'hide_share_feature_tooltip', choice: true })
        : this.$store.dispatch('settings/updateSettings', { key: 'hide_share_feature_tooltip', choice: true })

      if (this.isAppMode) {
        navigator.share({
          url: this.lesson.share_url,
          text: `${this.$t('share.checkOutThisVideo')} ${this.course.title} ${this.$t('share.onlineCourse')}`,
          title: this.$t('share.shareThisLesson')
        })
        return
      }

      this.showShareVideo = !this.showShareVideo
    },
    hideShareVideo () {
      this.showShareVideo = false
    }
  },
  mounted () {
    this.activeTooltip = true
  }
}
</script>

<style lang="scss" scoped>
.share-video {
  position: relative;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    z-index: 105;
  }
}

.share-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 105;

  @include tablet-portrait {
    position: absolute;
    bottom: -50px;
    left: auto;
    right: calc(100% + 32px);
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% calc(100% - 50px);
    transition-property: transform, opacity;
    transition: .2s ease-out;
  }
}

.scale-enter-active {
  transition: .2s ease-in-out;
}
.scale-enter-from,
.scale-leave-active {
  opacity: 0;
  transform: scale(.8);
  transition: .4s ease-in;
  transition-property: transform, opacity;
}
.scale-leave-active {
  transition: .2s ease-out;
}

.slide-enter-active {
  transition: .4s ease-in-out;
}
.slide-enter-from,
.slide-leave-active {
  visibility: hidden;
  transform: translateY(100%);
  transition-property: transform, visibility;
}
.slide-leave-active {
  transition: .4s ease-in-out;
}
</style>