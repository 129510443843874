import appClient from '@shared/api/appClient'

export default {
  namespaced: true,
  namespace: 'banner',
  state () {
    return {
      banner: null
    }
  },
  actions: {
    getBanner ({commit}) {
      return appClient.getBanner().then(({data}) => {
        if (!!Object.keys(data).length) {
          commit('GET_BANNER', data)

          return data.timer
        }
      })
    },
    closeBanner ({ commit }) {
      commit('CLOSE_BANNER')
    }
  },
  mutations: {
    GET_BANNER (state, value) {
      state.banner = value
    },
    CLOSE_BANNER (state) {
      state.banner = null
    }
  }
}
