<template>
  <div class="sidebar-module" :class="{'sidebar-module--disabled': !isExpandable}" ref="module" ontouchstart>
    <div class="sidebar-module__top" :class="{'sidebar-module__top--active': isActive, 'sidebar-module__top--expanded': expanded}" @click="expandModule">
      <app-progress :progress="module.percent_completed || 0" :vertical="true"></app-progress>
      <div>
        <div class="sidebar-module__heading" >
          <div class="sidebar-module__free-video" v-if="isTeaser">{{ isTeasers ? $t('sidebar.freeVideos') : $t('sidebar.freeVideo')}}</div>
          {{ module.subtitle }}
        </div>
        <div class="sidebar-module__title">{{ preventWidows(module.title) }}</div>
      </div>
      <div class="sidebar-module__expand" :class="{ 'sidebar-module__expand--expanded': expanded }" v-if="isExpandable">
        <font-awesome-icon :icon="['fas', 'chevron-down']" class="sidebar-module__expand-icon" />
      </div>
      <div class="sidebar-module__coming-soon" v-else>
        {{ $t('sidebar.comingSoon') }}
      </div>
    </div>

    <transition-expand @transitionEnter="moduleStateChange" @transitionLeave="moduleStateChange">
      <div class="sidebar-module__expander" v-show="expanded" v-if="isExpandable">
        <sidebar-module-item v-for="item in module.lessons" :item="item" :module="module" :loadedFromLesson="loadedFromLesson" :key="item.id"></sidebar-module-item>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import TransitionExpand from '@shared/components/utils/TransitionExpand.vue'
import SidebarModuleItem from './SidebarModuleItem.vue'
import { mapState } from 'vuex'


export default {
  name: 'SidebarModule',
  components: {
    TransitionExpand,
    SidebarModuleItem
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    loadedFromLesson: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    ...mapState('layout', {
      sidebarNest: state => state.sidebarNest,
      sidebarInner: state => state.sidebarInner,
      isLandscape: state => state.isLandscape
    }),
    ...mapState('course', {
      course: state => state.course
    }),
    isActive () {
      const currentLesson = parseInt(this.$route.params.id)
      const hasActiveLesson = this.module.lessons.find(item => item.id === currentLesson)

      return hasActiveLesson ? true : false
    },
    isCompleted () {
      return this.module.progress === 100
    },
    isExpandable () {
      return this.module.lessons.length && !this.module.is_coming_soon
    },
    isTeaser () {
      return this.course.locked && this.module.lessons.filter(lesson => lesson.is_teaser).length
    },
    isTeasers () {
      return this.module.lessons.filter(lesson => lesson.is_teaser).length > 1
    },
    isIPadPortrait () {
      return this.isIPad && !this.isLandscape
    },
    isScrollToModule () {
      return !this.expanded && !this.isPhone && !this.isIPadPortrait
    },
    eventNamePrefixForTracker () {
      return this.loadedFromLesson ? 'lesson' : 'course'
    }
  },
  watch: {
    '$route.params.id': {
      handler () {
        if (this.isActive) {
          this.expanded = true
        }
      }
    }
  },
  methods: {
    expandModule () {
      if (!this.isExpandable) {
        return
      }

      if (this.isScrollToModule) {
        this.scrollSidebarToModule()
      }

      this.expanded = !this.expanded

      this.trackEvent('button_clicked', {
        button: this.eventNamePrefixForTracker + (this.expanded ? '_view_expand_module' : '_view_collapse_module'),
        course_id: this.course.id,
        course_slug: this.course.product_slug,
        course_name: this.course.title,
        module_id: this.module.id,
        module_name: this.module.title,
      })
    },
    scrollSidebarToModule () {
      const modulePosition = this.$refs.module.offsetTop + (this.$refs.module.clientHeight * 2)
      const sidebarPosition = this.sidebarNest.scrollTop + this.sidebarNest.clientHeight

      if (modulePosition > sidebarPosition) {

        setTimeout (() => {
          const position = this.$refs.module.offsetTop
          const offset = this.$refs.module.clientHeight / 2

          this.sidebarNest.scroll({
            top: position - offset,
            behavior: 'smooth'
          })
        }, 200)
      }
    },
    moduleStateChange () {
      this.$store.dispatch('layout/setSidebarDownHidden', this.sidebarInner.clientHeight < this.sidebarNest.clientHeight)
    }
  },
  created () {
    this.expanded = this.isActive
  }
}
</script>

<style lang="scss" scoped>
  .sidebar-module {
    border-radius: 8px;
    margin: 0 0 10px 0;
    background-color: var(--gray-8);
    transition: background-color .1s;
    cursor: pointer;

    &--disabled {
      cursor: default;
      pointer-events: none;
    }

    &:last-child {
      margin: 0;
    }

    @include desktop-xs {
      &:hover {
        background-color: var(--gray-7);
      }
    }

    &__top {
      display: flex;
      align-items: center;
      padding: 15px 12px 15px 10px;
      border-radius: 8px;
      background-color: var(--gray-8);
      transition: all .2s ease-out;

      @include tablet-landscape {
        padding: 15px 18px;
      }

      .progress-bar--vertical {
        background-color: var(--gray-6);
      }

      &--active {
        border-radius: 8px;
        background-color: var(--gray-6);

        .progress-bar--vertical {
          background-color: var(--gray-5);
        }
      }

      &--expanded {
        border-radius: 8px 8px 0 0;
      }
    }

    &__heading {
      display: flex;
      font-size: $text-sm;
      line-height: 18px;
      font-weight: normal;
      color: var(--gray-3);
      margin: 0 0 4px 0;
    }

    &__free-video {
      font-size: 12px;
      font-weight: $semiBold;
      color: var(--black);
      padding: 0 6px;
      margin: 0 8px 0 0;
      border-radius: 4px;
      background: #73C988;
    }

    &__title {
      line-height: 1.2;
      font-weight: $semiBold;
      padding-right: 20px;
    }

    &__expand {
      color: var(--gray-3);
      margin-left: auto;
      transform-origin: 50% 50%;
      margin-top: 4px;
      &--expanded {
        .sidebar-module__expand-icon {
          transform: rotate(180deg);
        }
      }
    }
    &__expand-icon {
        font-size: 21px;
        transition: transform .4s ease-in-out;
    }

    &__coming-soon {
      font-size: 13px;
      line-height: 28px;
      color: var(--black);
      white-space: nowrap;
      padding: 0 10px;
      margin-left: auto;
      border-radius: 4px;
      background: var( --gray-3);
    }
  }
</style>
