<template>  
  <div class="home-ph-courses">
    <div class="home-ph-courses__item home-ph-courses__item--hidden home-ph-courses__course" v-for="(course, index) in 6" :key="index">
      <div class="home-ph-courses__course-thumbnail"></div>
      <div class="home-ph-courses__course-info">
        <div class="home-ph-courses__item home-ph-courses__title"></div>
        <div class="home-ph-courses__item home-ph-courses__description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCardPlaceholder'
}
</script>

<style lang="scss" scoped>
  .home-ph-courses {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @include phone-landscape {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      row-gap: 24px;
    }
    @include tablet-landscape {
      column-gap: 32px;
      row-gap: 32px;
    }

    &__item {
      position: relative;
      max-width: 100%;
      border-radius: 5px;
      background: var(--gray-5);
      animation: shimmer 1s ease-in-out infinite;
      animation-direction: alternate;

      &--hidden {
        animation: none;
      }
    }

    &__course {
      border-radius: 12px;
      margin-bottom: 30px;

      @include phone-landscape {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--full {
        grid-column: 1/-1;

        @include phone-landscape {
          .home-ph-courses__course-thumbnail {
            padding: 0 0 41.5% 0;
          }
        }
        @include tablet-portrait {
          .home-ph-courses__course-thumbnail {
            padding: 0 0 48.3% 0;
          }
        }
      }
    }

    &__course-thumbnail {
      padding: 0 0 100% 0;

      @include phone-landscape {
        padding: 0 0 83% 0;
      }
      @include tablet-portrait {
        padding: 0 0 100% 0;
      }
    }

    &__course-info {
      display: flex;
      flex-basis: 100%;
      flex-flow: column;
      justify-content: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 96px;
      padding: 0 16px;
      border-radius: 0 0 12px 12px;
      background: var(--gray-7);
      backdrop-filter: blur(15px);
      z-index: 2;

      @include phone-portrait {
        padding: 0 20px;
      }
      @include phone-landscape {
        height: 76px;
      }
      @include tablet-portrait {
        height: 96px;
        padding: 0 14px 0 23px;
      }
    }

    &__title {
      height: 24px;
      width: 238px;
      margin: 0 0 4px 0;
    }

    &__description {
      width: 135px;
      height: 17px;
    }
  }
</style>
