<template>
  <div class="quick-tips-comments" v-click-outside="closeOutside" v-bind:class="{'quick-tips-comments--expanded': (isExpanded || commentFocus || replyTo) && comments.length > 4}">
    <div class="quick-tips-comments__top">
      <p class="quick-tips-comments__top-title">
        {{ $t('tabs.comments') }}
        <span class="quick-tips-comments__top-nr" v-if="comments.length">{{ compactNumberFormatter(commentsTotal, {formatBreakpoint: 10_000}) }}</span>
      </p>
      <div class="quick-tips-comments__indicator" v-swipe.down="handleSwipeDown" v-swipe.up="handleSwipeUp" v-if="isMobile"></div>

      <a class="quick-tips-comments__close" @click="closeComments">&times;</a>
    </div>

    <div class="quick-tips-comments__nest">
      <template v-if="commentsTotal === 0">
        <div class="quick-tips-comments__empty">
          <font-awesome-icon :icon="['fas', 'message']" class="quick-tips-comments__empty-icon" />
          <h5 class="quick-tips-comments__empty-title" v-html="$t('comments.noMoreCommentsForThisVideo')"></h5>
          <p class="quick-tips-comments__empty-text">{{ $t('comments.beFirst') }}</p>
        </div>
      </template>
      <template v-else>
        <div class="quick-tips-comments__list" v-if="comments.length">
          <quick-tips-comment v-for="comment in comments" :key="comment.id" :comment="comment" />
        </div>
        <quick-tips-comments-placeholder :comments="6" v-else />
      </template>
    </div>

    <div @click="handleGuest">
      <div class="quick-tips-comments__commenter" >
        <quick-tips-commenter />
      </div>
    </div>
  </div>
</template>

<script>
import QuickTipsComment from './QuickTipsComment.vue'
import QuickTipsCommentsPlaceholder from '../lesson/LessonCommentsPlaceholder.vue'
import QuickTipsCommenter from './QuickTipsCommenter.vue'
import clickOutside from '@shared/directives/clickOutside.js'
import swipe from '@shared/directives/swipe'
import { mapState } from 'vuex'

export default {
  components: {
    QuickTipsComment,
    QuickTipsCommentsPlaceholder,
    QuickTipsCommenter,
  },
  directives: {
    'click-outside': clickOutside,
    'swipe': swipe
  },
  computed: {
    ...mapState('quickTips', ['video','comments','commentsTotal','commentFocus','replyTo']),
    ...mapState('popup', ['popup']),
    ...mapState('user', ['guest'])
  },
  data() {
    return {
      isExpanded: false
    }
  },
  methods: {
    closeComments () {
      if (this.popup) return
      this.$store.dispatch('quickTips/toggleComments', false)
      this.$store.dispatch('quickTips/toggleVideoAutoplay', true)
    },
    closeOutside(e) {
      // ignoring the click was on the close button in the login popup
      // at max the click would be in one of 3 elements in the close button (a, svg or path element)
      if ([e.target.classList, e.target.parentNode.classList, e.target.parentNode.parentNode.classList].some(list => list.contains('popup__close'))) return
      this.closeComments()
    },
    handleSwipeUp() {
      if (!this.isExpanded) {
        this.isExpanded = true
      }
    },
    handleSwipeDown() {
      if (this.commentFocus) return
      if (this.isExpanded) {
        this.isExpanded = false
        return
      }
      this.closeComments()
    },
    handleGuest() {
      if (this.guest) {
        return this.$store.dispatch('popup/showLoginPopup')
      }
    }
  },
  mounted () {
    this.$store.dispatch('quickTips/toggleLockScroll', true)
    this.$store.dispatch('quickTips/fetchComments')
  },
  unmounted () {
    this.$store.dispatch('quickTips/toggleLockScroll', false)
    this.$store.dispatch('quickTips/resetComments')
    this.$store.dispatch('quickTips/setReplyTo', null)
  }
}
</script>

<style lang="scss" scoped>
.quick-tips-comments {
  display: flex;
  flex-flow: column;
  position: fixed;
  bottom: 0;
  left: 50%;
  max-height: 67vh;
  width: 642px;
  max-width: 101%;
  padding: 0 0 74px 0;
  border-radius: 24px 24px 0 0;
  background: rgba(28, 28, 30, 0.98);
  backdrop-filter: blur(16px);
  transform: translate(-50%, 0);
  transition: all 0.2s ease-in-out;

  &--expanded {
    max-height: 88vh;
  }

  &__top {
    position: relative;
    padding: 32px 16px 16px 16px;
    border-bottom: 1px solid var(--gray-5);

    @media (hover: hover) {
      padding: 24px 16px 16px 16px;
    }
  }

  &__top-title {
    font-size: 17px;
    line-height: 22px;
    font-weight: $semiBold;
    color: var(--white);
    margin: 0;
  }
  &__indicator {
      position: absolute;
      top: 0;
      left: 50%;
      height: 32px;
      width: 60%;
      margin: 0 0 0 -30%;
      touch-action: none;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 4px;
        width: 36px;
        border-radius: 4px;
        background: var(--gray-5);
        transform: translate(-50%, -50%);
      }
    }

  &__top-nr {
    font-size: 15px;
    font-weight: normal;
    color: #636366;
    margin: 0 0 0 4px;
  }

  &__nest {
    flex: 1;
    position: relative;
    min-height: 50vh;
    padding: 16px 16px 0 16px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (hover: none) {
      @include hide-scrollbar;
    }

    @media (hover: hover) {
      @include style-scrollbar(4px, var(--gray-4), var(--gray-6));
    }
  }

  &__commenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 14px;
    background: rgba(22, 22, 22, 0.94);
    backdrop-filter: blur(10px);
    z-index: 10;

    > * {
      &:first-child {
        flex: 1;
      }
    }
  }

  &__close {
    position: absolute;
    top: calc(50% + 8px);
    right: 0;
    left: auto;
    width: auto;
    font-size: 30px;
    color: var(--white);
    padding: 2px 12px;
    border-radius: 10px;
    transform: translateY(-50%);

    @include tablet-portrait {
      padding: 5px 16px;
    }

    @media (hover: hover) {
      top: 50%;
    }
  }

  &__empty {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 16px 16px 74px 16px;
  }
  &__empty-icon {
    font-size: 32px;
    color: var(--gray-3);
    margin: 0 0 20px 0;
  }
  &__empty-title {
    font-size: $header-xs;
    margin: 0 0 10px 0;
  }
  &__empty-text {
    color: var(--gray-3);
    margin: 0
  }

  ::v-deep(.comments-ph__comment) {
    margin-bottom: 32px;
  }
  ::v-deep(.comments-ph__comment),
  ::v-deep(.comments-ph__commenter) {
    padding-left: 0;
  }
}
</style>
