<template>
  <div class="assignment">
    <div class="assignment__content" v-html="assignments"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LessonProject',
  computed: {
    ...mapState('lesson', {
      assignments: state => state.lesson.assignments_html
    })
  }
}
</script>

<style lang="scss" scoped>
  .assignment {
    padding: 0 0 60px 0;

    &__content {
      color: var(--gray-2);

      :deep(ol) {
        list-style: none;
        line-height: 1.4;
        padding: 0;
        margin: 0 0 22px 0;
        counter-reset: li;

        &:last-child {
          margin-bottom: 0;
        }

        li {
          counter-increment: li;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            content: counter(li) ". ";
            font-weight: $semiBold;
            color: var(--red);
          }
        }
      }

      :deep(p) {
        line-height: 1.4;
        margin: 0 0 24px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      :deep(strong) {
        color: var(--gray-1);
      }

      :deep(a) {
        text-decoration: underline;
      }

      :deep(br) {
        display: none;
      }
    }
  }
</style>
