<template>
  <div class="course-module-ph"></div>
</template>

<script>
export default {
  name: 'SidebarModulePlaceholder'
}
</script>

<style lang="scss" scoped>
  .course-module-ph {
    position: relative;
    height: 85px;
    margin: 0 0 10px 0;
    border-radius: 8px;
    background: var(--gray-6);
    animation: shimmer 1s ease-in-out infinite;
    animation-direction: alternate;
  }
</style>