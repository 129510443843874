<template>
  <div class="loader">
    <div class="loader__line-wrapper">
      <div class="loader__line">
        <div class="loader__line-inner"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLineLoader'
}
</script>

<style lang="scss">
.loader {
  $duration: 1.5s;

  &__line {
    position: relative;
    top: 0px;
    left: 0px;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    transform-origin: 100% 0%;
    animation: loader_line $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
    border-radius: 5px;
    overflow: hidden;
    &-wrapper {
      height: 2px;
      position: relative;
      top: 0px;
      left: 0px;
      bottom: auto;
      right: auto;
      background-image: linear-gradient(to left, #DDDDDD, #FFFFFF);
      border-radius: 5px;
      overflow: hidden;
    }
    &-inner {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: auto;
      right: auto;
      width: 100%;
      height: 100%;
      transform-origin: 0% 0%;
      border-radius: 5px;
      overflow: hidden;
      opacity: 1;
      background-color: #A3AAAE; // Apple uses this for their "silver" color
      animation: loader_line_inner $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
      border-radius: 5px;
      overflow: hidden;
    }
  }
    
  @keyframes loader_line {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  @keyframes loader_line_inner {
    0% {
      transform: scaleX(0);
    }
    25% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(1);
    }
  }
}
</style>