<template>
  <div class="refer" v-if="isLoaded">
    <app-container>
      <div class="refer__block refer__referrals">
        <h3 class="refer__block-title">My Referrals</h3>

        <div class="refer__referrals-row">
          <div class="refer__referrals-item">
            <span v-tooltip="'<strong>Pending payments</strong> displays the total<br> amount that will be paid if/when the 30<br> days from purchase elapse'">
              Pending payments
              <font-awesome-icon :icon="['far', 'question-circle']" />
            </span>
            <p>{{ referrals.stats.total_pending }}</p>
          </div>

          <div class="refer__referrals-item">
            <span>Friends joined</span>
            <p>{{ referrals.stats.total_count }}</p>
          </div>
        </div>

        <div class="refer__referrals-item">
            <span>Total payouts</span>
            <p>{{ referrals.stats.total_paid }}</p>
        </div>

        <div class="refer__referrals-more" :class="{'refer__referrals-more--opened': showReferrals}" ref="referrals" @click="toggleReferrals">
          Show more details
          <font-awesome-icon :icon="['fas', 'chevron-down']" class="refer__referrals-more-arrow" />
        </div>

        <transition-expand>
          <div v-if="showReferrals">
            <table class="refer__referrals-table">
              <thead>
                <tr>
                  <th v-tooltip="'Date displays the day when your<br> friend purchased the course'">Date <font-awesome-icon :icon="['far', 'question-circle']" /></th>
                  <th v-tooltip="'<strong>Course</strong> displays the name of the<br> course that your friend purchased'">Course <font-awesome-icon :icon="['far', 'question-circle']" /></th>
                  <th v-tooltip="'<strong>Earned</strong> displays the money that you<br> will be receiving or already received'">Earned <font-awesome-icon :icon="['far', 'question-circle']" /></th>
                  <th v-tooltip="'<strong>Status</strong> displays the current<br> state of the referral'">Status <font-awesome-icon :icon="['far', 'question-circle']" /></th>
                </tr>
              </thead>

              <TransitionGroup tag="tbody" name="list" v-if="referrals.data.length">
                <my-referral v-for="(referral, index) in referrals.data" :referral="referral" :key="index"></my-referral>
              </TransitionGroup>
            </table>

            <a class="refer__block-btn" @click="loadReferrals" v-if="canLoadMore">
              Load More
            </a>

            <div class="refer__referrals-none" v-if="!referrals.data.length">
              No referrals yet! Share your link with a friend to get started.
            </div>
          </div>
        </transition-expand>
      </div>

      <div class="refer__block refer__paypal">
        <h3 class="refer__block-title">Get Paid</h3>
        <p class="refer__block-desc">Payments will be made to your PayPal&nbsp;account<br> Enter your PayPal email address to get paid</p>

        <transition-expand>
          <div v-if="paypal.success">
            <div class="refer__alert refer__alert--success">
              <font-awesome-icon :icon="['fas', 'check-circle']" />
              <p>We have successfully saved your PayPal email!</p>
            </div>
          </div>

          <div v-if="paypal.error">
            <div class="refer__alert refer__alert--error">
              <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
              <p>Sorry, that doesn’t seem like a valid email!</p>
            </div>
          </div>
        </transition-expand>

        <div class="refer__block-input-nest">
          <img :src="vaporAsset('images/icons/icon-paypal.svg')" alt="pp" class="refer__block-input-img">

          <input
            type="text"
            placeholder="PayPal email address"
            class="refer__block-input refer__block-input--paypal"
            :class="{'refer__block-input--error': paypal.error}"
            v-model="paypalAccountModel"
            @input="clearPaypal"
            @keyup.enter="savePaypal(paypalAccountModel)"
          >

          <a class="refer__block-btn" :class="{'refer__block-btn--opened': paypal.success}" @click="savePaypal(paypalAccountModel)">
              <font-awesome-icon :icon="['far', 'check-square']" v-if="paypal.success" />
            {{ paypal.success ? 'Saved' : paypalBtn }}
          </a>
        </div>
      </div>

      <div class="refer__paypal-signup" v-if="!paypalAccount">
        Don’t have PayPal? <a href="https://www.paypal.com/kn/welcome/signup/" target="_blank">Sign up here</a>
      </div>

      <refer-terms :separator="!paypalAccount ? true : false"></refer-terms>

      <div class="refer__questions">
        <a class="refer__block-btn show-menu" @click="openContactForm">
          Questions? Ask Us!
        </a>
      </div>
    </app-container>
  </div>
</template>

<script>
  import MyReferral from './ReferMyReferral.vue'
  import ReferTerms from './ReferTerms.vue'
  import AppContainer from '@course-portal/components/layout/AppContainer.vue'
  import TransitionExpand from '@shared/components/utils/TransitionExpand.vue'
  import { mapGetters, mapState } from 'vuex'

  export default {
    components: {
      MyReferral,
      ReferTerms,
      AppContainer,
      TransitionExpand
    },
    data () {
      return {
        loaded: false,
        showReferrals: false,
        linkCopied: false,
        paypal: {
          error: false,
          success: false
        }
      }
    },
    computed: {
      ...mapGetters('user', ['hasFriendReferrals']),
      ...mapState('user', {
        user: state => state.user
      }),
      ...mapState('courses', {
        courses: state => state.courses
      }),
      ...mapState('refer', {
        shareLink: state => state.shareLink,
        referrals: state => state.referrals,
        paypalAccount: state => state.paypalAccount
      }),
      paypalAccountModel: {
        get () {
          return this.$store.state.refer.paypalAccountModel
        },
        set (value) {
          this.$store.commit('refer/SET_PAYPAL_ACCOUNT_MODEL', value)
        }
      },
      isLoaded () {
        return this.loaded && this.user && this.courses
      },
      canLoadMore () {
        return this.referrals.meta.current_page < this.referrals.meta.last_page
      },
      canShare () {
        return navigator.canShare
      },
      paypalBtn () {
        return this.paypalAccount ? 'Update' : 'Save'
      },
      composeEmailMessage () {
        return `?subject=Sharing this ${encodeURI('80%')} discount!&body=Hi! I wanted to share this ${encodeURI('80%')} discount for iPhone Photo Academy:%0D%0A%0D%0A${this.shareLink}/%0D%0A%0D%0AIf you’d like to take amazing photos with your iPhone, I highly recommend this online course...%0D%0A%0D%0AAnd with ${encodeURI('80%')} off right now, it’s an absolute bargain!`
      }
    },
    watch: {
      user (val) {
        if (val && this.hasFriendReferrals) {
          this.loaded = true
        } else {
          this.$router.push('page-not-found')
        }
      }
    },
    methods: {
      shareFb () {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.shareLink}/`, '_blank').focus()
      },
      shareWhatsapp () {
        window.open(`https://${this.isMobile ? 'api' : 'web'}.whatsapp.com/send?text=Hi! I wanted to share this 80% discount for iPhone Photo Academy. If you’d like to take amazing photos with your iPhone, I highly recommend this online course. And with 80% off right now, it’s an absolute bargain!%0D%0A%0D%0A${this.shareLink}/`, '_blank').focus()
      },
      shareTwitter () {
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent('Do you want to take amazing photos with your iPhone? If so, I highly recommend the iPhone Photo Academy online course. If you use this link, you’ll get a huge 80% discount!')}%0D%0A%0D%0A${this.shareLink}/`, '_blank').focus()
      },
      async shareOther () {
        try {
          await navigator.share({
            url: this.shareLink
          })
        } catch (error) {
          this.$store.dispatch('notifications/showToast', {
            item: {
              type: 'error',
              content: {
                message: error.message
              }
            },
            isMobile: isMobile()
          })
        }
      },
      copyLink () {
        this.copyTextToClipboard(this.shareLink)

        this.linkCopied = true

        setTimeout(() => {
          this.linkCopied = false
        }, 5000)
      },
      toggleReferrals () {
        this.showReferrals = !this.showReferrals
        this.$refs['referrals'].scrollIntoView({ behavior: 'smooth', block: 'center' })
      },
      loadReferrals () {
        this.$store.dispatch('refer/loadMoreReferrals', this.referrals.meta.current_page + 1)
      },
      savePaypal (email) {
        this.$store.dispatch('refer/setPaypalAccountModel', email).then(() => {
          this.$store.commit('refer/SET_PAYPAL_ACCOUNT_MODEL', email)
          this.paypal.error = false
          this.paypal.success = true
        }).catch(() => {
          this.paypal.success = false
          this.paypal.error = true
        })

        this.clearPaypal()
      },
      clearPaypal () {
        this.paypal = {
          success: false,
          error: false
        }
      },
      openContactForm () {
        this.$store.dispatch('layout/toggleMenu', true)
        this.$store.dispatch('layout/setMenuComponent', {
          component: 'AppMenuContact'
        })
      }
    },
    mounted () {
      Promise.all([
        this.$store.dispatch('refer/getReferLink'),
        this.$store.dispatch('refer/getReferrals'),
        this.$store.dispatch('refer/getPaypalAccount')
      ]).then(() => {
        this.loaded = true
      })
    }
  }
</script>

<style lang="scss" scoped>
  .refer {
    padding: 32px 0;

    @include tablet-portrait (portrait) {
      padding: 82px 0;
    }
    @include tablet-landscape {
      padding: 82px 0;
    }

    .container {
      width: 640px;
      max-width: 100%;
    }

    &__block {
      padding: 32px 24px;
      margin: 24px 0 0 0;
      border-radius: 16px;
      background: var(--gray-8);

      @include phone-landscape {
        padding: 40px;
        margin: 32px 0 0 0;
      }
    }

    &__block-title {
      font-size: $header-sm;
      line-height: 26px;
      font-weight: $semiBold;
      text-align: center;
      margin: 0 0 24px 0;

      @include phone-landscape {
        line-height: 1.5;
        margin: 0 0 32px 0;
      }
    }

    &__block-desc {
      font-size: 15px;
      text-align: center;
      margin: -16px 0 48px 0;

      @include phone-landscape {
        font-size: $text-md;
        margin: -24px 0 48px 0;
      }
    }

    &__block-input-nest {
      position: relative;

      @include phone-landscape {
        display: flex;
      }
    }

    &__block-input-img {
      position: absolute;
      top: 12px;
      left: 16px;
    }

    &__block-input {
      width: 100%;
      height: 43px;
      font-size: 13px;
      color: var(--gray-1);
      padding: 0 16px;
      border: 1px solid var(--black);
      border-radius: 10px;
      outline: none;
      background: var(--black);
      transition: all .2s ease-in-out;

      @include phone-landscape {
        width: auto;
        min-width: calc(100% - 123px);
      }

      &--paypal {
        padding-left: 43px;
      }

      &--error {
        border-color: var(--red);
      }
    }

    &__block-btn {
      display: block;
      width: 115px;
      font-size: 14px;
      line-height: 41px;
      font-weight: $semiBold;
      text-align: center;
      margin: 8px auto 0 auto;
      border: 1px solid var(--gray-1);
      border-radius: 10px;

      @include phone-landscape {
        margin: 0 0 0 8px;
      }

      &:hover {
        color: var(--gray-8);
        background: var(--gray-1);
      }

      &:active {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      &--opened {
        color: var(--green);
        background: var(--gray-1);
      }
    }

    &__referrals {
      margin-top: 0;

      .refer__block-btn {
        margin: 32px auto 0 auto;

        &:active {
          color: var(--gray-8);
          background: var(--gray-1);
        }
      }
    }

    &__referrals-row {
      display: flex;
      gap: 8px;
      margin: 0 0 8px 0;
    }

    &__referrals-item {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 74px;
      border-radius: 10px;
      background: var(--black);

      p {
        font-size: $header-sm;
        color: var(--white);
        margin: 0;
      }

      span {
        font-size: 12px;
        line-height: 1.5;
        color: var(--gray-3);
        cursor: default;

        svg {
          vertical-align: middle;
          font-size: 15px;
          margin: 0 0 0 4px;
        }
      }
    }

    &__referrals-more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px 12px 16px;
      margin: 32px 0 0 0;
      border-bottom: 1px solid var(--gray-3);
      cursor: pointer;

      &--opened {
        .refer__referrals-more-arrow {
          transform: rotate(180deg);
        }
      }
    }

    &__referrals-more-arrow {
      transition: transform .2s ease-in-out;
    }

    &__referrals-table {
      width: 100%;
      font-size: 15px;
      text-align: left;
      margin: 32px 0 0 0;

      th {
        cursor: default;

        &:nth-child(2) {
          display: none;

          @include phone-landscape {
            display: table-cell;
          }
        }
      }

      th {
        font-weight: $semiBold;
        white-space: nowrap;
        padding: 0 24px 17px 0;

        svg {
          color: var(--gray-3);
          margin: 0 0 0 4px;
        }
      }
    }

    &__referrals-none {
      font-size: 15px;
      text-align: center;
      color: var(--gray-3);
      margin: 16px 0 0 0;
    }

    &__paypal {
      margin-bottom: 16px;

      .refer__block-desc {
        @media only screen and (device-width: 375px) {
          font-size: $text-sm;
        }
      }
    }

    &__paypal-signup {
      font-size: 14px;
      color: var(--gray-2);
      text-align: center;
      padding: 0 0 0 0;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    &__questions {
      display: flex;
      justify-content: center;
      margin: 32px 0 0 0;

      .refer__block-btn {
        width: auto;
        padding: 0 12px;
      }
    }

    &__alert {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: $text-sm;
      line-height: 1.5;
      color: var(--gray-8);
      padding: 10px 41px;
      margin: 0 0 16px 0;
      border-radius: 10px;

      &--success {
        background: #E7FAE9;
      }

      &--error {
        background: #FAE7EC;
      }

      p {
        margin: 0
      }
    }
  }

.list-enter-active,
.list-leave-active {
  transition: all 0.4s ease-in-out;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
