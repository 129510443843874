import { PlatformDataHydrator } from '@iphoneps/events'
import { v1 as uuid } from 'uuid'
import { isAppMode } from '../base/appFlags'

export default new PlatformDataHydrator({
    platform: isAppMode() ? "camguru-app" : "course-portal",
    resolveAnonymousIdFn: () => {
        if (window.App.ipse_aid) {
            return window.App.ipse_aid;
        }

        if (window.ipse_aid) {
            return window.ipse_aid;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const aid = urlParams.get('ipse_aid') ?? uuid();
        window.ipse_aid = aid;

        return aid;
    },
    resolveSessionIdFn: () => null,
    resolveContactIdFn: () => window.App.contact_id ?? null,
    resolveProductDataFn: () => null,
    resolveSplitTestDataFn: () => null
})
