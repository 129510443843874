import appClient from '@shared/api/appClient'

export default {
  namespaced: true,
  namespace: 'comments',
  state: {
    comments: [],
    commentsTotal: null,
    commentsNextPage: null,
    commentsPrevPage: null,
    commentsLastPage: null,
    commentsLoading: true,
    commentFocus: false
  },
  actions: {
    setComments ({ commit, state }, payload) {
      state.commentsLoading = true

      return new Promise((resolve, reject) => {
        appClient.getComments(payload.lessonId, payload.page || 1, payload.commentId)
          .then((response) => {
            commit('SET_COMMENTS', response.data)
            resolve()
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    setSharedComments ({ commit, state }, payload) {
      state.commentsLoading = true

      return new Promise((resolve, reject) => {
        appClient.getSharedComments(payload.lessonId, payload.page || 1, payload.commentId)
          .then((response) => {
            commit('SET_COMMENTS', response.data)
            resolve()
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    resetComments ({ commit }) {
      commit('RESET_COMMENTS')
    },
    addComment ({ commit, rootState }, value) {
      return new Promise((resolve) => {
        appClient.addComment(rootState.lesson.lesson.id, value.content, value.replyId).then(({data}) => {
          value.replyId ? commit('ADD_REPLY', {reply: data.comment, replyId: value.replyId}) : commit('ADD_COMMENT', data.comment)
          resolve(data)
        })
      })
    },
    deleteComment ({ commit }, value ) {
      return new Promise((resolve) => {
        appClient.deleteComment(value.comment.id).then(() => {
          value.comment.is_reply ? commit('DELETE_REPLY', value) : commit('DELETE_COMMENT', value)
          resolve()
        })
      })
    },
    setCommentFocus ({commit}, value) {
      commit('SET_COMMENT_FOCUS', value)
    },
    resetComments ({ commit }) {
      commit('RESET_COMMENTS')
    }
  },
  mutations: {
    SET_COMMENTS (state, value) {
      state.comments.push(...value.data.filter(obj1 => state.comments.every(obj2 => obj1.id !== obj2.id)))
      state.commentsTotal = value.meta.total
      state.commentsNextPage = value.links.next
      state.commentsPrevPage = value.links.prev
      state.commentsLastPage = value.meta.last_page
      state.commentsLoading = false
    },
    RESET_COMMENTS (state) {
      state.comments = []
      state.commentsTotal = null
      state.commentsNextPage = null
      state.commentsPrevPage = null
      state.commentsLastPage = null
    },
    ADD_COMMENT (state, value) {
      state.comments.unshift(value)
      state.commentsTotal += 1
    },
    ADD_REPLY (state, value) {
      const comment = state.comments.find(item => {
        return item.id === value.replyId
      })
      comment.replies.push(value.reply)
      state.commentsTotal += 1
    },
    DELETE_COMMENT (state, value) {
      state.comments = state.comments.filter(item => {
        return item.id !== value.comment.id
      })
      state.commentsTotal -= value.comment.replies.length + 1
    },
    DELETE_REPLY (state, value) {
      const comment = state.comments.find(item => {
        return item.id === value.parentId
      })
      comment.replies = comment.replies.filter(item => {
        return item.id !== value.comment.id
      })
      state.commentsTotal -= 1
    },
    SET_COMMENT_FOCUS (state, value) {
      state.commentFocus = value
    },
    RESET_COMMENTS (state) {
      state.comments = []
    }
  }
}
