<template>
  <tr class="referral">
    <td>{{ referral.date }}</td>
    <td>{{ preventWidows(referral.product_name) }}</td>
    <td :class="{ 'paid': referral.is_paid, 'pending': referral.is_pending }">{{ referral.earned || '-' }}</td>
    <td :class="{ 'paid': referral.is_paid, 'refunded': referral.is_refunded, 'pending': referral.is_pending }">{{ referral.status }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    referral: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .referral {
    td {
      vertical-align: top;
      color: var(--gray-3);
      padding: 7px 24px 7px 0;

      &:first-child {
        white-space: nowrap;
      }

      &:last-child {
        white-space: nowrap;
        text-transform: capitalize;
        padding-right: 0;
        &.pending {
          color: var(--orange);
        }

        &.refunded {
          color: var(--red);
        }

        &.paid {
          color: var(--green);
        }
      }

      &:nth-child(2) {
        display: none;

        @include phone-landscape {
          display: table-cell;
        }
      }

      &:nth-child(3) {
        color: var(--gray-6);

        &.pending {
          color: var(--gray-3);
        }

        &.refunded {
          color: var(--gray-3);
        }

        &.paid {
          color: var(--gray-3);
        }
      }
    }
  }
</style>