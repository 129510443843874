import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import store from '../store'
import { isAppMode, isMobile } from '@shared/base/appFlags'

export const setChannel = (userId) => {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: window.data.pusherAppKey,
    cluster: window.data.pusherAppCluster,
    forceTLS: true
  })

  const channel = window.Echo.private(userId)

  channel.notification((n) => {
    // Set default 'is_read' activity state
    n.type === 'badge_unlocked' || n.type === 'achievement_unlocked'
      ? n.is_read = true
      : n.is_read = false

    if (isAppMode() && n.type === 'comment_reply') return

    store.dispatch('notifications/setNotifications', n).then(() => {
      store.dispatch('notifications/showNotification', {
        item: n,
        isMobile: isMobile()
      })

      // Update activities list
      store.commit('profile/UPDATE_ACTIVITY', n)
    })
  })
}