import appClient from '@shared/api/appClient'
import { isMobile } from '@shared/base/appFlags'

export default {
  namespaced: true,
  namespace: 'settings',
  state () {
    return {
      settings: null,
      guestSettings: {
        theater_mode: false
      }
    }
  },
  actions: {
    getSettings ({ commit }) {
      return appClient.getSettings().then(({data}) => {
        if (data) {
          commit('GET_SETTINGS', data)
        }
      })
    },
    updateSettings ({ commit }, value) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_SETTINGS', value)
        appClient.updateSettings({field: value.key, value: value.choice}).then(() => {
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    updateGuestSettings ({ commit }, value) {
      commit('UPDATE_GUEST_SETTINGS', value)
    }
  },
  mutations: {
    GET_SETTINGS (state, value) {
      state.settings = value

      // Set theater mode to "false" for mobile devices
      if (isMobile()) {
        state.settings.theater_mode = false
      }
    },
    UPDATE_SETTINGS (state, value) {
      state.settings[value.key] = value.choice
    },
    UPDATE_GUEST_SETTINGS (state, value) {
      state.guestSettings[value.key] = value.choice
    }
  }
}
