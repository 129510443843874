<template>
  <div class="profile-avatar" :class="{'profile-avatar--compact': compact}">
    <app-avatar class="profile-avatar__img" :src="avatarImage" @click="$refs.file.click()">
      <div class="profile-avatar__icon" :class="{'profile-avatar__icon--uploaded': isAvatar}" v-if="!uploading">
        <template v-if="compact">
          <font-awesome-icon :icon="['fas', 'check']" v-if="isAvatar" />
          <font-awesome-icon :icon="['fas', 'plus']" v-else />
        </template>
        <font-awesome-icon :icon="['fas', 'camera']" v-else />
      </div>

      <div class="profile-avatar__uploading" v-if="uploading"></div>
    </app-avatar>

    <app-button class="profile-avatar__button" color="gray-5" @click="$refs.file.click()">{{ $t('profile.uploadNewPicture') }}</app-button>
    <a class="profile-avatar__remove" @click="removeAvatar" v-if="!uploading && isAvatar">{{ $t('profile.removePicture') }}</a>
    <input type="file" ref="file" accept=".jpg,.jpeg,.png,.bmp,.gif,.svg,.webp" class="profile-avatar__input" @change="uploadAvatar">
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'


export default {
  name: 'ProfileEditAvatar',
  props: {
    compact: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      uploading: false,
      removing: false
    }
  },
  computed: {
    ...mapGetters('user', ['avatarImage']),
    ...mapState('user', {
      user: state => state.user
    }),
    ...mapState('layout', {
      cropping: state => state.cropping
    }),
    isAvatar () {
      return this.avatarImage !== null && !this.avatarImage.includes('placeholder')
    }
  },
  watch: {
    cropping (val) {
      if (!val) {
        this.$refs.file.value = null
      }
    }
  },
  methods: {
    uploadAvatar (event, files) {
      let file

      files ? file = files[0] : file = event.target.files[0]

      if (!file) {
        return false
      }
      if (!file.type.match('image.*')) {
        return false
      }

      const blob = URL.createObjectURL(file)

      const reader = new FileReader()

      reader.onload = () => {
        this.$store.dispatch('user/cropAvatar', blob)
        this.$store.dispatch('layout/toggleCropping', true)
        this.$store.dispatch('user/setAvatarInput', this.$refs.file)
      }
      reader.readAsDataURL(file)

      this.trackEvent('button_clicked', { button: 'edit_profile_view_upload_profile_picture' })
    },
    removeAvatar () {
      this.uploading = true
      this.removing = true

      const formData = new FormData()
      formData.append('avatar', '')
      formData.append('first_name', this.user.first_name)
      formData.append('last_name', this.user.last_name)

      this.$store.dispatch('user/updateUser', formData).then(() => {
        this.$store.dispatch('user/removeAvatar')
        this.uploading = false
        this.removing = false
      }).catch(() => {
        this.uploading = false
        this.removing = false
      })

      this.$refs.file.value = null

      this.trackEvent('button_clicked', { button: 'edit_profile_view_remove_profile_picture' })
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-avatar {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 0 30px 0;

  @include tablet-portrait {
    flex-flow: row;
  }

  &--compact {
    display: block;
    margin: 0;

    .profile-avatar__img {
      width: 100%;
      height: auto;
      margin: 0;
    }

    .profile-avatar__icon {
      display: flex;
      font-size: $text-md;
      color: var(--gray-6);
      background: linear-gradient(360deg, var(--red-gradient));

      &--uploaded {
        color: var(--black);
        background: var(--green-light);
      }
    }

    .profile-avatar__button,
    .profile-avatar__remove {
      display: none;
    }
  }

  &__img {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 0 20px 0;
    border-radius: 50%;
    background-color: var(--gray-6);
    background-size: cover;
    cursor: pointer;

    @include tablet-portrait {
      height: 88px;
      width: 88px;
      margin: 0 24px 0 0;
    }
  }

  &__uploading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: rgba(var(--black-rgb), 0.6);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 40px;
      width: 40px;
      margin: -20px 0 0 -20px;
      border: 2px solid transparent;
      border-top-color: var(--white);
      border-radius: 50%;
      pointer-events: none;
      animation: loading .6s infinite linear;
    }
  }

  &__button {
    display: none;
    font-weight: normal;
    margin: 0 24px 0 0;

    @include tablet-portrait {
      display: inline-block;
    }
  }

  &__remove {
    color: var(--gray-3);
    &:hover {
      color: var(--red);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: -3px;
    height: 32px;
    width: 32px;
    color: var(--black);
    border-radius: 50%;
    background: var(--white);
    z-index: 5;

    @include tablet-portrait {
      display: none;
    }
  }

  &__input {
    display: none;
  }

  @keyframes loading {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
  }
}
</style>
