<template>
  <div class="commenter" :class="{'commenter--qt': isQuickTips}" v-click-outside="hideButtons">
    <div class="commenter__inner">
      <app-avatar class="commenter__avatar" :src="avatarImage"></app-avatar>

      <div class="commenter__main">
        <div class="commenter__nest" v-click-outside="hideEmojis">
          <auto-sized-textarea ref="autoSizer">
            <textarea
              rows="1"
              :placeholder="`${$t('comments.leaveAComment')}...`"
              class="commenter__textarea"
              :class="{'commenter__textarea--with-picker': !isMobile}"
              ref="textarea"
              v-model="model"
              :disabled="loading"
              @click="showButtons"
              @keydown.enter.prevent
              @keyup.enter.prevent="addComment"
              @focus="handleFocus"
              @blur="resetFocus"
            ></textarea>
          </auto-sized-textarea>

          <div class="commenter__options" v-if="!isMobile && !isQuickTips">
            <div class="commenter__options-btn commenter__emoji-btn" :class="{'commenter__emoji-btn--active': isEmojiPicker}" @click="toggleEmojis">
              <font-awesome-icon :icon="['far', 'face-smile']" />
            </div>

            <transition-expand down>
              <div class="commenter__emoji-nest" ref="emojiNest" v-show="isEmojiPicker">
                <Picker
                  :data="emojiIndex"
                  :showPreview="false"
                  @select="emojiSelect"
                />
              </div>
            </transition-expand>
          </div>
        </div>

        <transition-expand>
          <div class="commenter__actions" v-show="isButtonsVisible" v-if="!isQuickTips">
            <a class="commenter__action-btn commenter__action-btn--cancel" @click="cancel">{{ $t('cancel') }}</a>
            <app-button class="commenter__action-btn" :class="minimal ? 'commenter__action-btn--loader' : 'commenter__action-btn--loader-reply'" color="green" v-if="loading">
              <font-awesome-icon :icon="['fas', 'spinner']" spin />
            </app-button>
            <app-button class="commenter__action-btn" :class="{'commenter__action-btn--disabled': !commentText}" color="green" @click="addComment" v-else>{{ !minimal ? $t('reply') : $t('comments.comment') }}</app-button>
          </div>
        </transition-expand>
      </div>
    </div>
  </div>
</template>

<script>
import AutoSizedTextarea from '@shared/components/utils/AutoSizedTextarea.vue'
import TransitionExpand from '@shared/components/utils/TransitionExpand.vue'
import clickOutside from '@shared/directives/clickOutside.js'
import insertText from 'insert-text-at-cursor'
import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'
import { mapGetters, mapState } from 'vuex'

const emojiIndex = new EmojiIndex(data)

export default {
  name: 'LessonCommenter',
  components: {
    Picker,
    AutoSizedTextarea,
    TransitionExpand
  },
  props: {
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    minimal: {
      type: Boolean,
      required: false,
      default: false
    },
    commentId: {
      type: Number,
      required: false,
      default: null
    },
    isQuickTips: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['cancel', 'resetFocus'],
  data() {
    return {
      commentText: '',
      isFocus: false,
      isEmojiPicker: false,
      emojiIndex: emojiIndex,
      loading: false
    }
  },
  computed: {
    ...mapGetters('user', ['avatarImage']),
    ...mapState('comments', {
      commentFocus: state => state.commentFocus
    }),
    ...mapState('quickTips', {
      postComment: state => state.postComment
    }),
    model: {
      get () {
        return this.isQuickTips ? this.postComment : this.commentText
      },
      set (value) {
        this.isQuickTips
          ? this.$store.dispatch('quickTips/updateMessage', value)
          : this.commentText = value
      }
    },
    isAvatar () {
      return !this.avatarImage.includes('placeholder')
    },
    isButtonsVisible () {
      return this.minimal ? this.isFocus || this.commentText || this.focus : true
    }
  },
  watch: {
    focus: {
      handler (val) {
        if (val) {
          if (this.isMobile) {
            this.$nextTick(() => { this.$refs.textarea.focus() })
          } else {
            setTimeout(() => { this.$refs.textarea.focus() }, 300)
          }
        }
      },
      immediate: true
    },
    commentFocus (val) {
      // Pause "next video countdown" if comment box is focused
      this.$store.dispatch('lesson/pauseCountdown', val)
    }
  },
  directives: {
    'click-outside': clickOutside
  },
  methods: {
    cancel () {
      this.commentText = ''
      this.isFocus = false
      this.$refs['autoSizer'].resetTextarea()
      this.$emit('cancel')
    },
    handleFocus () {
      this.$store.dispatch('comments/setCommentFocus', true)
    },
    resetFocus () {
      this.$emit('resetFocus')
      this.$store.dispatch('comments/setCommentFocus', false)
    },
    toggleEmojis () {
      this.isEmojiPicker = !this.isEmojiPicker
      this.$refs.textarea.focus()

      if (this.isEmojiPicker) {
        setTimeout(() => this.$refs.emojiNest.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 400)
      }
    },
    hideEmojis () {
      this.isEmojiPicker = false
    },
    emojiSelect (emoji) {
      insertText(this.$refs.textarea, emoji.native)
      this.hideEmojis()
    },
    addComment () {
      if (!this.commentText.length) return

      this.trackEvent('button_clicked', { button: this.commentId ? 'lesson_view_add_reply_to_comment' : 'lesson_view_add_comment' })

      this.loading = true
      this.$store.dispatch('comments/addComment', {
        content: this.commentText,
        replyId: this.commentId
      }).then((data) => {
        // Display avatar upload popup if this is user's first comment
        if (data.is_first_comment && !this.isAvatar) {
          this.$store.dispatch('popup/setPopup', {
            type: 'profile_photo'
          }, { root: true })
        }

        this.loading = false
        this.cancel()
      }).catch(() => {
        this.loading = false
      })
    },
    showButtons () {
      this.isFocus = true
    },
    hideButtons () {
      this.isFocus = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .commenter {
    position: relative;

    &--qt {
      .commenter__textarea {
        background: none;
      }
    }

    &__inner {
      position: relative;
      padding: 0 0 0 18px;
    }

    &__nest {
      position: relative;
    }

    &__main {
      flex: 1;
    }

    &__options {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }

    &__options-btn {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 22px;
      color: var(--gray-3);
      padding: 15px;
      cursor: pointer;
    }

    &__emoji-btn {
      &--active {
        color: var(--green-light);
      }
    }

    &__emoji-nest {
      position: absolute;
      top: 90%;
      right: 15px;
      z-index: 10;
    }

    &__avatar {
      position: absolute;
      top: 7px;
      left: 0;
      height: 36px;
      min-width: 36px;
      z-index: 2;
    }

    &__textarea {
      display: block;
      width: 100%;
      height: 50px;
      min-height: 50px;
      font-size: $text-md;
      line-height: 22px;
      color: var(--gray-1);
      border: 0;
      padding: 14px 16px 14px 26px;
      border-radius: 8px;
      outline: none;
      background: var(--gray-8);
      overflow: hidden;
      resize: none;
      -webkit-appearance: none;

      &--with-picker {
        padding-right: 68px;
      }

      &::placeholder {
        color: var(--gray-1);
      }
    }

    &__actions {
      text-align: right;
    }

    &__action-btn {
      margin: 8px 16px 0 0;

      &--cancel {
        color: var(--gray-3);

        &:active {
          color: var(--gray-1);
        }

        @include desktop-xs {
          &:hover {
            color: var(--gray-1);
          }
        }
      }

      &--loader {
        width: 86px;
        @include phone-portrait {
          width: 94px;
        }
        @include tablet-portrait {
          width: 102px;
        }
      }

      &--loader-reply {
        width: 58px;

        @include phone-portrait {
          width: 65.5px;
        }
        @include phone-landscape {
          width: 70.5px;
        }
      }

      &--disabled {
        opacity: .4;
        filter: grayscale(1);
        pointer-events: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
