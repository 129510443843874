<template>
    <div class="trial-ended">
        <div class="trial-ended__bg">
            <div class="image-collage">
                <img v-for="(image,index) in images" :key="image" class="img" :class="image.class + ' '+getImageGridAreaName(index)" :srcset="image.srcset"
                    :src="image.src" />
            </div>
        </div>

        <div class="trial-ended__inner">
            <span class="trial-ended__icon"></span>
            <h2 class="trial-ended__title" v-html="$t('course.trialEnded')"></h2>
            <p class="trial-ended__text" v-html="$t('course.toKeepLearning')"></p>

            <a class="trial-ended__btn" href="https://secure.iphonephotographyschool.com/iphone-photo-academy"
                target="_blank">
                <span>{{ $t('course.get') }} iPhone Photo Academy</span>
            </a>
        </div>

    </div>
</template>

<script>

export default {
    data() {
        return {
            images: [
                {
                    "class": "left show-mobile-only",
                    "srcset": "https://images.iphonephotographyschool.com/32503/light-trails.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32503/light-trails.jpg"
                },
                {
                    "class": "left",
                    "srcset": "https://images.iphonephotographyschool.com/32451/road2x.jpg 2x, https://images.iphonephotographyschool.com/32450/road.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32450/road.jpg"
                },
                {
                    "class": "center",
                    "srcset": "https://images.iphonephotographyschool.com/32433/trees2x.jpg 2x, https://images.iphonephotographyschool.com/32432/trees.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32432/trees.jpg"
                },
                {
                    "class": "center",
                    "srcset": "https://images.iphonephotographyschool.com/32437/light-trail22x.jpg 2x, https://images.iphonephotographyschool.com/32436/light-trail2.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32436/light-trail2.jpg"
                },
                {
                    "class": "center",
                    "srcset": "https://images.iphonephotographyschool.com/32504/woman-door.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32504/woman-door.jpg"
                },
                {
                    "class": "right flip-image",
                    "srcset": "https://images.iphonephotographyschool.com/32457/field2x.jpg 2x, https://images.iphonephotographyschool.com/32456/field.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32456/field.jpg"
                },
                {
                    "class": "right",
                    "srcset": "https://images.iphonephotographyschool.com/32439/sunset-clouds2x.jpg 2x, https://images.iphonephotographyschool.com/32438/sunset-clouds.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32438/sunset-clouds.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32441/web-sun2x.jpg 2x, https://images.iphonephotographyschool.com/32440/web-sun.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32440/web-sun.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32429/river2x.jpg 2x, https://images.iphonephotographyschool.com/32428/river.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32428/river.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32431/sunset-sea2x.jpg 2x, https://images.iphonephotographyschool.com/32430/sunset-sea.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32430/sunset-sea.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32426/mountain2x.jpg 2x, https://images.iphonephotographyschool.com/32426/mountain.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32426/mountain.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32425/light-trail2x.jpg 2x, https://images.iphonephotographyschool.com/32424/light-trail.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32424/light-trail.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32445/woman-hand2x.jpg 2x, https://images.iphonephotographyschool.com/32444/woman-hand.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32444/woman-hand.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32453/umbrella2x.jpg 2x, https://images.iphonephotographyschool.com/32452/umbrella.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32452/umbrella.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32435/horses2x.jpg 2x, https://images.iphonephotographyschool.com/32434/horses.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32434/horses.jpg"
                },
                {
                    "class": "hide-mobile",
                    "srcset": "https://images.iphonephotographyschool.com/32445/woman-hand2x.jpg 2x, https://images.iphonephotographyschool.com/32444/woman-hand.jpg 1x",
                    "src": "https://images.iphonephotographyschool.com/32444/woman-hand.jpg"
                },
            ]

        }
    },
    methods: {
        getImageGridAreaName(index) {
            return `img--${String.fromCharCode(97 + index)}`;
        }
    }
}

</script>


<style lang="scss" scoped>
.trial-ended {
    @include phone-landscape {
        display: flex;
        flex-direction: row-reverse;
        height: calc(100vh - 56px);
        overflow: hidden;
        justify-content: space-between;
    }

    &__inner {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        text-align: center;
        padding: 0 10px;
        margin-bottom: 28px;
        margin-top: 28px;
        overflow: hidden;

        @include phone-portrait {
            padding: 0 13px;

        }

        @include phone-landscape {
            justify-content: center;
            align-items: flex-start;
            text-align: left;
            padding: 0 71px 0 71px;
        }
    }

    &__bg {
        position: relative;
        height: 334px;
        width: 100%;
        overflow: hidden;

        @media (max-width: calc($breakpoint-phone-landscape - 1px)) {

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 82px;
                z-index: 1;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 82px;
                z-index: 1;
                background: linear-gradient(-90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }


        @include phone-landscape {
            height: 100%;
            width: 20%;
            max-width: 945px;
            overflow: visible;
            padding-left: 50px;
        }

        @include tablet-landscape {
            width: 30%;
        }

        @include desktop-sm {
            width: 45%;
        }

        .image-collage {
            display: grid;
            height: 100%;
            gap: 8px;
            width: 117%;
            transform: translateX(-8%);
            grid-template:
                "a c d f" 1fr
                "a e e f" 1fr
                "b e e g" 1fr;

            @include phone-landscape {
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 82px;
                    z-index: 1;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
                }

                transform: rotate(-20deg) translateY(-20%);
                height: unset;
                min-width: 945px;
                gap: 16px;
                grid-template:
                    "l m ." 1fr
                    "c g n" 1fr
                    "i d f" 1fr
                    "j e b" 1fr
                    "k h o" 1fr
                    ". p ." 1fr;

                .img {

                    &--l,
                    &--c,
                    &--i,
                    &--j,
                    &--k {
                        transform: translateY(25%);
                    }

                    &--n,
                    &--f,
                    &--b,
                    &--o
                     {
                        transform: translateY(-25%);
                    }

                }
            }
            @include tablet-landscape {
                gap: 26px;
            }

            .img {
                border-radius: 4px;
                height: 100%;
                object-fit: cover;

                @include phone-landscape {
                    height: 212px;
                    width: 100%;
                }

                &--a {
                    grid-area: a;
                }

                &--b {
                    grid-area: b;
                }

                &--c {
                    grid-area: c;
                }

                &--d {
                    grid-area: d;
                }

                &--e {
                    grid-area: e;
                    object-view-box: inset(0% 0% 28%);
                    width: 100%;
                }

                &--f {
                    grid-area: f;
                }

                &--g {
                    grid-area: g;
                }

                &--h {
                    grid-area: h;
                }

                &--i {
                    grid-area: i;
                }

                &--j {
                    grid-area: j;
                }

                &--k {
                    grid-area: k;
                }

                &--l {
                    grid-area: l;
                }
                &--m {
                    grid-area: m;
                }

                &--n {
                    grid-area: n;
                }
                &--o {
                    grid-area: o;
                }
                &--p {
                    grid-area: p;
                }
            }

            .left {
                object-position: right;
                object-view-box: inset(0% 8% 0% 0%);
            }

            .right {
                object-position: left;
                object-view-box: inset(0% 0% 0% 8%);
            }
        }
    }

    .hide-mobile {
        display: none;

        @include phone-landscape {
            display: block;
        }
    }

    .show-mobile-only {
        @include phone-landscape {
            display: none;
        }
    }

    &__icon {
        position: relative;
        width: 28px;
        height: 28px;
        margin: 0 0 20px 0;
        background: url('https://images.iphonephotographyschool.com/32501/lock-trial.svg') no-repeat center center;
        background-size: contain;

        @include phone-landscape {
            align-self: center;
            width: 37px;
            height: 37px;
        }
    }

    &__title {
        position: relative;
        font-size: 28px;
        font-weight: $semiBold;
        margin: 0 0 28px 0;

        @include phone-landscape {
            font-size: 32px;
        }

        @include tablet-portrait {
            font-size: 34px;
        }
    }

    &__text {
        position: relative;
        font-size: 13px;
        color: #AAAAAA;
        margin: 0 0 32px 0;
        font-weight: 400;

        @include phone-portrait {
            font-size: 15px;
        }

        @include phone-landscape {
            font-size: 19px;
        }

        @include tablet-landscape {
            font-size: 24px;
        }
    }


    &__btn {
        display: block;
        position: relative;
        width: 338px;
        max-width: 100%;
        font-size: 18px;
        line-height: 50px;
        font-weight: $bold;
        text-align: center;
        text-decoration: none;
        color: #fff;
        border: none;
        border-radius: 16px;
        outline: none;
        background: linear-gradient(135deg, #D41C3D 0%, #861B83 100%);

        @include phone-landscape {
            line-height: 64px;
        }

    }
}
</style>