<template>
    <nav class="options" ref="options">
      <div class="options__item"
        v-if="!loading"
        v-for="option in options"
        :key="getOptionId(option)"
        :ref="setOptionsRefs"
        :data-id="getOptionId(option)"
      >
        <app-button :color="getOptionButtonColorProp(option)" @click="handleOptionClicked(option)" class="options__item-button">
          {{ getOptionLabel(option) }}
        </app-button>
      </div>

      <template v-else>
        <div class="options__item">
          <app-button color="white" class="options__item-button">
          All
          </app-button>
        </div>
      </template>
    </nav>
</template>

<script>
import AppButton from './AppButton.vue'

export default {
  name: 'AppBullets',
  components: [
    AppButton
  ],
  props: {
    options: {
      type: Array,
      required: false
    },
    mode: {
      type: String,
      default: 'single'
    },
    value: {
      type: [Array, String],
      required: false
    },
    defaultId: {
      type: String,
      required: false
    },
    propId: {
      type: String,
      required: false
    },
    propLabel: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    },
  },
  emits: ['valueChange', 'scrollChange'],
  data () {
    return {
      isLoaded: false,
      optionsRefs: [],
      activeOptions: []
    }
  },
  methods: {
    getOptionId(option) {
      return this.propId ? option[this.propId] : option.id
    },
    isModeSingle() {
      return this.mode && this.mode === 'single'
    },
    getOptionLabel(option) {
      return this.propLabel ? option[this.propLabel] : option.name
    },
    getOptionButtonColorProp(option) {
      return this.activeOptions.includes(this.getOptionId(option)) ? 'white-no-hover' : 'gray-8'
    },
    setOptionsRefs (el) {
      if (el) {
        this.optionsRefs.push(el)
      }
    },
    isOptionActive(id) {
      return this.activeOptions.includes(id)
    },
    setOptionInactive(id) {
      if (this.isModeSingle) this.activeOptions = ""+this.defaultId ? [this.defaultId] : []
      else this.activeOptions =
        this.activeOptions.length === 1 && this.defaultId
          ? [this.defaultId]
          : this.activeOptions.filter(optionId => optionId !== id)
    },
    setOptionActive(id) {
      if (this.isModeSingle) this.activeOptions = [id]
      else this.activeOptions.push(id)
    },
    handleOptionClicked(option) {
      if (!this.optionsRefs.length) return
      const id = this.getOptionId(option)
      const button = this.optionsRefs.find(option => option.dataset.id === id)
      if (id === this.defaultId) {
        this.activeOptions = [id]
      } else {
        if (this.isOptionActive(id) && !this.isModeSingle) this.setOptionInactive(id);
        else if (this.isOptionActive(id) && this.isModeSingle) return
        else this.setOptionActive(id);
      }
      this.$emit('valueChange', this.isModeSingle ?  this.activeOptions[0] : this.activeOptions)

      button.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    },
    handleScroll () {
      this.$refs.options.addEventListener('scroll', e => {
        const classes = []

        if (e.target.scrollLeft)
          classes.push('dim-left')

        if (Math.ceil(e.target.scrollLeft + e.target.clientWidth) < e.target.scrollWidth)
          classes.push('dim-right')

        this.$emit('scrollChange', classes)
      })
    }
  },
  mounted() {
    if (this.isModeSingle) {
      this.activeOptions = [this.value]
      this.handleScroll()
      this.$nextTick(() => {
        if (!this.optionsRefs.length) return
        const optionElem = this.optionsRefs.find(option => option.dataset.id === this.value)
        if (this.$refs.options.scrollWidth > this.$refs.options.clientWidth) {
          this.$emit('scrollChange', ['dim-right'])
        }
        if (!optionElem) return
        setTimeout(() => {
          optionElem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
        }, 100)
      })

      return
    }

    this.activeOptions = this.value
  },
}
</script>

<style lang="scss" scoped>
  .options {
    display: flex;
    padding-left: 30px;
    position: relative;
    overflow: scroll;

    @include hide-scrollbar;

    // Targets iOS Safari
    @supports (-webkit-touch-callout: none) {
      // Smooth horizontal options scrolling on iOS devices
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    }

    &__item {
      position: relative;
      font-size: $text-md;
      text-align: center;
      color: var(--gray-3);
      margin: 0 8px 0 0;
      cursor: pointer;

      &:last-child {
        margin: 0;
      }

      &-button {
        display: inline-block;
        vertical-align: top;
        position: relative;
        white-space: nowrap;
        margin: 0;
        border-radius: 32px;
        padding: 0 16px;
        line-height: 32px;

        @include tablet-portrait {
          display: block;
          font-size: 13px;
          font-weight: 400;
          padding: 0 16px;
          line-height: 32px;
        }
      }
    }

    &__comments-nr {
      font-size: $text-sm;
      color: var(--gray-4);
      margin-left: 2px;
    }

    &__item-locked {
      display: inline;
      font-size: 13px;
      margin: 0 2px 0 0;
    }

    &__tab-indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      border-radius: 4px 4px 0 0;
      background: linear-gradient(270deg, var(--red-gradient));
    }
  }
</style>