<template>
  <div class="share-popup__nest">
    <div class="share-popup__scroll" :class="{'share-popup__scroll--with-banner': banner}">
      <div class="share-popup__indicator" v-swipe.down="close" v-if="isMobile"></div>

      <h2 class="share-popup__heading">{{ $t('share.shareThisLesson') }}</h2>

      <h3 class="share-popup__section-title">{{ $t('share.social') }}</h3>

      <div class="share-popup__section">
        <div class="share-popup__share-item share-popup__share-item--fb" @click="shareFb">
          <font-awesome-icon :icon="['fab', 'facebook-f']" class="share-popup__share-icon" />
          Facebook
        </div>

        <div class="share-popup__share-item share-popup__share-item--twitter" @click="shareTwitter">
          <font-awesome-icon :icon="['fab', 'twitter']" class="share-popup__share-icon" />
          Twitter
        </div>

        <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${lesson.share_url}`" target="_blank" class="share-popup__share-item share-popup__share-item--linkedin">
          <font-awesome-icon :icon="['fab', 'linkedin-in']" class="share-popup__share-icon" />
          Linkedin
        </a>
      </div>

      <h3 class="share-popup__section-title">{{ $t('share.messaging') }}</h3>

      <div class="share-popup__section">
        <div class="share-popup__share-item share-popup__share-item--whatsapp" @click="shareWhatsapp">
          <font-awesome-icon :icon="['fab', 'whatsapp']" class="share-popup__share-icon" />
          Whatsapp
        </div>

        <!-- <a :href="`fb-messenger://share/?link= https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789`" target="_blank" class="share-popup__share-item share-popup__share-item--messanger">
          <font-awesome-icon :icon="['fab', 'facebook-messenger']" class="share-popup__share-icon" />
          Messanger
        </a> -->

        <div class="share-popup__share-item share-popup__share-item--telegram" @click="shareTelegram">
          <font-awesome-icon :icon="['fab', 'telegram']" class="share-popup__share-icon" />
          Telegram
        </div>

        <a :href="`mailto:${composeEmailMessage}`" target="_blank" class="share-popup__share-item share-popup__share-item--mail">
          <font-awesome-icon :icon="['far', 'envelope']" class="share-popup__share-icon" />
          Email
        </a>
      </div>

      <h3 class="share-popup__section-title">{{ $t('share.link') }}</h3>

      <div class="share-popup__link">
        <span>{{ lesson.share_url }}</span>
      </div>

      <div class="share-popup__buttons">
        <div class="share-popup__button share-popup__button--copy" :class="{'share-popup__button--active': copied}" @click="copyLink">{{ copied ? $t('share.linkCopied') : $t('share.copyLink') }}</div>
        <div class="share-popup__button share-popup__button--close" @click="$emit('close')">{{ $t('close') }}</div>
      </div>

      <a class="share-popup__close" @click="close">&times;</a>

      <div class="share-popup__arrow"><font-awesome-icon :icon="['fas', 'play']" /></div>
    </div>
  </div>
</template>

<script>
import swipe from '@shared/directives/swipe'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      copied: false
    }
  },
  computed: {
    ...mapState('lesson', {
      lesson: state => state.lesson
    }),
    ...mapState('course', {
      course: state => state.course
    }),
    ...mapState('banner', {
      banner: state => state.banner
    }),
    composeEmailMessage () {
      return `?subject=${this.$t('share.checkThisOut')}&body=${this.$t('share.checkOutThisVideo')} ${this.course.title} ${this.$t('share.onlineCourse')}.%0D%0A%0D%0A${this.lesson.share_url}/`
    }
  },
  directives: {
    'swipe': swipe
  },
  methods: {
    shareFb () {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.lesson.share_url}/`, '_blank').focus()
    },
    shareTwitter () {
      window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(`${this.$t('share.checkOutThisVideo')} ${this.course.title} ${this.$t('share.onlineCourse')}.`)}%0D%0A%0D%0A${this.lesson.share_url}/`, '_blank').focus()
    },
    shareWhatsapp () {
      window.open(`https://${this.isMobile ? 'api' : 'web'}.whatsapp.com/send?text=${this.$t('share.checkOutThisVideo')} ${this.course.title} ${this.$t('share.onlineCourse')}.%0D%0A%0D%0A${this.lesson.share_url}/`, '_blank').focus()
    },
    shareTelegram () {
      window.open(`https://telegram.me/share/url?url=${this.lesson.share_url}&text=${this.$t('share.checkOutThisVideo')} ${this.course.title} ${this.$t('share.onlineCourse')}.`)
    },
    copyLink () {
      this.copyTextToClipboard(this.lesson.share_url)

      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 5000)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.share-popup {
  &__nest {
    position: relative;
    width: 100%;
    max-width: 100%;
    color: var(--gray-8);
    border-radius: 20px 20px 0 0;
    background: #F2F2F2;
    z-index: 102;

    @include phone-landscape {
      min-width: 377px;
    }
    @include tablet-portrait {
      border-radius: 20px;
      background: #F2F2F2;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    }
  }

  &__scroll {
    padding: 32px;
    max-height: calc(100vh - #{$app-header-height});
    overflow-y: auto;

    @include tablet-portrait(portrait) {
      padding: 52px 40px;
    }
    @include tablet-landscape {
      padding: 52px 40px;
    }

    &--with-banner {
      max-height: calc(100vh - (#{$app-header-height} + #{$app-banner-height}));
    }
  }

  &__indicator {
    position: absolute;
    top: 0;
    left: 50%;
    height: 32px;
    width: 60%;
    margin: 0 0 0 -30%;
    touch-action: none;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 4px;
      width: 36px;
      border-radius: 4px;
      background: var(--gray-2);
      transform: translate(-50%, -50%);
    }
  }

  &__heading {
    font-size: 24px;
    margin: 0 0 20px 0;
  }

  &__section-title {
    font-size: $text-md;
    font-weight: $medium;
    letter-spacing: 0.01em;
    padding: 0 0 8px 0;
    margin: 0 0 12px 0;
    border-bottom: 1px solid #CDCFD2;
  }

  &__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 24px 0;

    @include tablet-portrait(portrait) {
      margin: 0 0 40px 0;
    }
    @include tablet-landscape {
      margin: 0 0 40px 0;
    }

    div:last-child {
      width: 15px
    }
  }

  &__share-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    width: 68px;
    height: 76px;
    font-size: 10px;
    padding: 8px 0 0 0;
    cursor: pointer;

    &:hover {
      border-radius: 8px;
      background: var(--gray-1);
    }

    &--fb {
      .share-popup__share-icon {
        color: #3B5998;
      }
    }

    &--twitter {
      .share-popup__share-icon {
        color: #1DA1F2;
      }
    }

    &--linkedin {
      .share-popup__share-icon {
        color: #0077B5;
      }
    }

    &--whatsapp {
      .share-popup__share-icon {
        color: #25D366;
      }
    }

    &--messanger {
      .share-popup__share-icon {
        color: #006AFF;
      }
    }

    &--telegram {
      .share-popup__share-icon {
        color: #229ED9;
      }
    }

    &--mail {
      .share-popup__share-icon {
        color: #861B83;
      }
    }
  }

  &__share-icon {
    font-size: 26px;
    // margin: 0 0 15px 0;
  }

  &__link {
    font-size: $text-xs;
    line-height: 44px;
    text-align: center;
    color: var(--gray-4);
    padding: 0 16px;
    margin: 0 0 12px 0;
    background: #E6E6E6;
    border-radius: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include tablet-portrait {
      line-height: 34px;
    }
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }

  &__button {
    font-size: 12px;
    line-height: 42px;
    font-weight: $bold;
    text-align: center;
    padding: 0 20px;
    color: var(--gray-6);
    border: 1px solid var(--gray-6);
    border-radius: 6px;
    transition: all .1s ease-in-out;
    cursor: pointer;

    @include tablet-portrait {
      line-height: 32px;
    }

    &--copy {
      flex: 1;
      color: #FAFAFA;
      background: var(--gray-6);

      &:hover {
        border-color: var(--gray-8);
        background: var(--gray-8);
      }
    }

    &--active {
      background: var(--gray-8);
      pointer-events: none;

      &:hover {
        background: var(--gray-8);
      }
    }

    &--close {
      min-width: 95px;
      color: var(--gray-8);

      &:hover {
        background: rgba((var(--gray-8-rgb)), 0.05);
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: auto;
    font-size: 30px;
    color: var(--gray-3);
    padding: 2px 12px;
    border-radius: 20px;

    @include tablet-portrait {
      padding: 7px 16px;
    }
  }

  &__arrow {
    position: absolute;
    bottom: 50px;
    left: calc(100% - 4px);
    font-size: 28px;
    color: #F2F2F2;
    z-index: 110;
  }
}
</style>