const swipe = {
  data () {
    return {
      touchStartY: null,
      touchStartX: null,
      touchEndY: null,
      touchEndX: null
    }
  },
  mounted (el, binding, vnode) {
    el.swipeYStartEvent = function (event) {
      this.touchStartY = event.changedTouches[0].screenY
    }

    el.swipeYEndEvent = function (event) {
      this.touchEndY = event.changedTouches[0].screenY
      el.swipeHandle(event, binding)
    }

    el.swipeXStartEvent = function (event) {
      this.touchStartX = event.changedTouches[0].screenX
    }

    el.swipeXEndEvent = function (event) {
      this.touchEndX = event.changedTouches[0].screenX
      el.swipeHandle(event, binding)
    }

    el.swipeHandle = function (event, binding) {
      if (binding.modifiers.up) {
        if (this.touchEndY < this.touchStartY) {
          binding.value(event)
        }
      }

      if (binding.modifiers.down) {
        if (this.touchEndY > this.touchStartY) {
          binding.value(event)
        }
      }

      if (binding.modifiers.left) {
        if (this.touchEndX < this.touchStartX) {
          binding.value(event)
        }
      }

      if (binding.modifiers.right) {
        if (this.touchEndX > this.touchStartX) {
          binding.value(event)
        }
      }

      if (binding.modifiers.tap) {
        if (this.touchEndY === this.touchStartY) {
          binding.value(event)
        }
      }
    }

    // Listen to vertical swipe
    if (binding.modifiers.up || binding.modifiers.down || binding.modifiers.tap) {
      el.addEventListener('touchstart', el.swipeYStartEvent)
      el.addEventListener('touchend', el.swipeYEndEvent)
    }

    // Listen to horizontal swipe
    if (binding.modifiers.left || binding.modifiers.right) {
      el.addEventListener('touchstart', el.swipeXStartEvent)
      el.addEventListener('touchend', el.swipeXEndEvent)
    }
  },
  beforeUnmount (el, binding) {
    // Detach vertical swipe events
    if (binding.modifiers.up || binding.modifiers.down) {
      el.removeEventListener('touchstart', el.swipeYStartEvent)
      el.removeEventListener('touchend', el.swipeYEndEvent)
    }

    // Detach horizontal swipe events
    if (binding.modifiers.left || binding.modifiers.right) {
      el.removeEventListener('touchstart', el.swipeYStartEvent)
      el.removeEventListener('touchend', el.swipeYEndEvent)
    }
  }
}

export default swipe