export default {
  namespaced: true,
  namespace: 'timer',
  state: {
    start: 0
  },
  actions: {
    startTimer ({ commit }) {
      setInterval(() => commit('START_TIMER'), 1000)      
    }
  },
  mutations: {
    START_TIMER (state) {
      state.start = ++state.start
    }
  }
}