<template>
  <div class="refer__block terms">
    <h3 class="terms__title">Terms And Conditions</h3>

    <ul class="terms__list">
      <li class="terms__item">Only purchases made directly via your unique discount link will be considered a valid referral.</li>
      <li class="terms__item">If your friend purchases via any other links it will not be classed as a valid referral.</li>
      <li class="terms__item">Only purchases of the ‘iPhone Photo Academy’ course will be considered a valid referral and eligible for commission.</li>
      <li class="terms__item">All other courses available for sale on our websites are not included in this promotion and you are not eligible for commission if they are purchased by someone you refer.</li>
      <li class="terms__item">If a purchase you have referred is refunded within 30 days you will not receive the commission.</li>
      <li class="terms__item">PayPal is the only payment method available for commissions to be paid.</li>
      <li class="terms__item">It is your responsibility to ensure your PayPal email address is correctly entered on this page.</li>
      <li class="terms__item">Referral commissions are eligible for payment 31 days after the referral purchase date.</li>
      <li class="terms__item">It can take up to 14 days for your referral commission payments to be processed and for you to receive the money in your PayPal account.</li>
      <li class="terms__item">We reserve the right to remove this promotion and make your unique discount link invalid at any time.</li>
      <li class="terms__item">We reserve the right to freeze any commission payments if we suspect fraudulent activity or breaches of these terms and conditions.</li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.terms {
  color: #CDCFD2;
  text-align: center;
  padding: 16px 0 0 0;

  &__title {
    font-size: $header-sm;
    line-height: 1.5;
    font-weight: 600;
    margin: 0 0 16px 0;
  }

  &__list {
    display: inline-flex;
    flex-flow: column;
    text-align: left;
    padding: 0;
    margin: 0 0 0 20px;
  }

  &__item {
    font-size: 16px;
    margin: 0 0 24px 0;

    &:last-child {
      margin: 0;
    }
  }
}
</style>